import { Badge, Drawer } from "@mui/material";
import { useContext, useState } from "react";
import CloseIcon from "../../assets/img/close_icon.svg";
import { Link } from "react-router-dom";
import Price from "../price/price";
import CartItemCard from "../cart-item-card/cart-item-card";
import AppContext from "../../context/context";

export default function MenuCart() {
  const { cart } = useContext(AppContext);
  const [showCart, setShowCart] = useState(false);
  return (
    <>
      {cart?.length > 0 ? (
        <Badge
          badgeContent={cart.length}
          color="purple"
          sx={{
            width: "20px",
            height: "20px",
          }}
        >
          <span
            onClick={(event) => {
              event.preventDefault();
              setShowCart(true);
            }}
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.5001 21C19.3285 21 20.0001 20.3284 20.0001 19.5C20.0001 18.6716 19.3285 18 18.5001 18C17.6717 18 17.0001 18.6716 17.0001 19.5C17.0001 20.3284 17.6717 21 18.5001 21Z"
                fill="black"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.5 21C9.3284 21 10 20.3284 10 19.5C10 18.6716 9.3284 18 8.5 18C7.67157 18 7 18.6716 7 19.5C7 20.3284 7.67157 21 8.5 21Z"
                fill="black"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 3H21L19 14H6L4 3ZM4 3C3.83333 2.33333 3 1 1 1"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.9999 14H5.99988H4.23065C2.44634 14 1.49988 14.7812 1.49988 16C1.49988 17.2188 2.44634 18 4.23065 18H18.4999"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </Badge>
      ) : (
        <span
          onClick={(event) => {
            event.preventDefault();
            setShowCart(true);
          }}
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.5001 21C19.3285 21 20.0001 20.3284 20.0001 19.5C20.0001 18.6716 19.3285 18 18.5001 18C17.6717 18 17.0001 18.6716 17.0001 19.5C17.0001 20.3284 17.6717 21 18.5001 21Z"
              fill="black"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8.5 21C9.3284 21 10 20.3284 10 19.5C10 18.6716 9.3284 18 8.5 18C7.67157 18 7 18.6716 7 19.5C7 20.3284 7.67157 21 8.5 21Z"
              fill="black"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M4 3H21L19 14H6L4 3ZM4 3C3.83333 2.33333 3 1 1 1"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.9999 14H5.99988H4.23065C2.44634 14 1.49988 14.7812 1.49988 16C1.49988 17.2188 2.44634 18 4.23065 18H18.4999"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      )}
      <Drawer
        anchor="right"
        open={showCart}
        onClose={() => {
          setShowCart(false);
        }}
        className="bg-[rgba(25,25,25,0.50)] backdrop-blur-[10px]"
        PaperProps={{
          sx: {
            width: {
              xs: "100%",
              md: "30%",
            },
            borderRadius: "15px",
          },
        }}
      >
        <div className="bg-[#fff] shadow rounded-[20px]">
          <div className="flex items-center bg-[#000] p-[5px] text-[#fff] ltr:rounded-[15px_15px_0_0] rtl:rounded-[0_0_15px_15px]">
            <div className="w-[75%] px-[10px]">Your Cart</div>
            <div className="w-[25%] flex justify-end">
              <img
                src={CloseIcon}
                alt=""
                className="p-[5px] bg-[#fff] rounded-full cursor-pointer"
                onClick={() => {
                  setShowCart(false);
                }}
              />
            </div>
          </div>

          {cart && cart.length > 0 ? (
            <>
              <div className="p-[15px]">
                {cart.map((item, index) => {
                  return <CartItemCard item={item} key={index} />;
                })}
              </div>
              <div className="p-[15px_5px] mx-[5px] flex items-center border-solid border-t-[1px] border-[#BEBEBE]">
                <div className="w-full text-[20px] text-[#666]">
                  <span className="font-[700] text-[#000]">Total: </span>
                  <span className="text-[#0BC154]">
                    <Price
                      price={cart?.reduce(
                        (total, product) =>
                          (total =
                            total +
                            product.count *
                              (product.sale_price
                                ? product.sale_price
                                : product.price)),
                        0
                      )}
                    />
                  </span>
                </div>
                <div className="w-full flex justify-end">
                  <Link
                    to="/cart"
                    className="p-[3px_20px] text-[#fff] bg-[#0BC154] rounded-[15px] ltr:mr-[5px] rtl:ml-[5px]"
                  >
                    Cart
                  </Link>
                </div>
              </div>
            </>
          ) : (
            <div className="flex justify-center py-[30px]">
              No Products in your cart
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
}
