import "./accordion.css";
import { useState } from "react";

export default function Accordion(props) {
  const [expanded, setExpanded] = useState(
    props.expanded ? props.expanded : false
  );

  return (
    <>
      <button
        class={`accordion bg-[#fff] text-[#000] p-[10px_15px] cursor-pointer border-none w-full text-[12px] flex items-center ${
          expanded ? "active" : ""
        }`}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <div className="w-[calc(100%-35px)] block">{props.title}</div>
        <div className="w-[35px] h-[35px] flex items-center justify-end">
          {expanded ? (
            <svg
              width="30"
              height="31"
              viewBox="0 0 30 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="rotate-90"
            >
              <path
                d="M15.75 15.4712L10 9.72119L11.75 7.97119L19.25 15.4712L11.75 22.9712L10 21.2212L15.75 15.4712Z"
                fill="black"
                fillOpacity="0.8"
              />
            </svg>
          ) : (
            <svg
              width="30"
              height="31"
              viewBox="0 0 30 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.75 15.4712L10 9.72119L11.75 7.97119L19.25 15.4712L11.75 22.9712L10 21.2212L15.75 15.4712Z"
                fill="black"
                fillOpacity="0.8"
              />
            </svg>
          )}
        </div>
      </button>
      <div
        class="panel"
        style={{
          display: expanded ? "block" : "none",
        }}
      >
        {props.children}
      </div>
    </>
  );
}
