import React from "react";
import { useContext } from "react";
import { useState } from "react";
import Input from "../components/input/input";
import FormButton from "../components/form-button/form-button";
import { ClipLoader } from "react-spinners";
import { useSnackbar } from "notistack";
import AppContext from "../context/context";
import Header from "./header";
import Footer from "./footer";
import { Link, useNavigate } from "react-router-dom";

export default function Login() {
  const { PUBLIC_URL } = useContext(AppContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [error, setError] = useState({
    username: "",
    password: "",
    default: "",
  });

  const login = async () => {
    try {
      await fetch(`${PUBLIC_URL}/api/customer/login`, {
        method: "POST",
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then(async (data) => {
          setLoader(0);
          if (data.token) {
            localStorage.setItem("token", data.token);
            let localcart = localStorage.getItem("cart");
            localcart = localcart ? JSON.parse(localcart) : [];
            let localwish = localStorage.getItem("wish");
            localwish = localwish ? JSON.parse(localwish) : [];
            let localcompare = localStorage.getItem("compare");
            localcompare = localcompare ? JSON.parse(localcompare) : [];

            const lists = {
              cart: [...localcart],
              wish: [...localwish],
              compare: [...localcompare],
            };

            if (
              localcart.length > 0 ||
              localwish.length > 0 ||
              localcompare.length > 0
            ) {
              await fetch(`${PUBLIC_URL}/api/customer/updatecart`, {
                method: "POST",
                body: JSON.stringify({
                  lists: lists,
                }),
                headers: {
                  Authorization: "Bearer " + data.token,
                },
              })
                .then((respose) => {
                  if (respose.ok) {
                    return respose.json();
                  }
                  throw new Error("error");
                })
                .then((data) => {
                  if (data.status === 1) {
                    localStorage.removeItem("cart");
                    localStorage.removeItem("wish");
                    localStorage.removeItem("compare");
                  }
                  // navigate('/')
                });
            } else {
              // navigate('/')
            }
            enqueueSnackbar("You have been loggedin", {
              variant: "success",
              vertical: "bottom",
              horizontal: "left",
            });
            navigate('/')
          } else if (data.email) {
            navigate(`/confirm/${data.email}`);
          } else {
            enqueueSnackbar("Error", {
              variant: "error",
              vertical: "bottom",
              horizontal: "left",
            });
          }
        });
    } catch (error) {
      enqueueSnackbar("Error", {
        variant: "error",
        vertical: "bottom",
        horizontal: "left",
      });// setError(t('khata_dar_amaliyat'))
      // loader(0)
      setLoader(0);
    }
  };

  return (
    <>
      <Header />
      <main className="bg-[rgba(0,0,0,0.05)] py-[60px]">
        <form className="w-[95%] md:w-[40%] bg-white mx-auto p-[60px_20px] border-[1px] border-solid border-[rgba(0,0,0,0.05)] shadow rounded-[15px]">
          <h1 className="text-center font-[500] text-[24px] mb-[15px]">
            Login
          </h1>
          <div className="mb-[6px]">
            <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
              {/* <div
                className={`w-[200px] bg-[rgba(0,0,0,0.05)] ${
                  error.username ? "text-[#f00]" : "text-[#000]"
                } p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right`}
              >
                Email *
              </div> */}
              <Input
                type="text"
                value={username}
                placeholder="Email *"
                onchange={(value) => {
                  setUsername(value);
                  setError({ ...error, username: "" });
                }}
                classes="text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]"
              />
            </div>
            {error.username && (
              <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                {error.username}
              </div>
            )}
          </div>
          <div className="mb-[6px]">
            <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
              {/* <div
                className={`w-[200px] bg-[rgba(0,0,0,0.05)] ${
                  error.password ? "text-[#f00]" : "text-[#000]"
                } p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right`}
              >
                Password *
              </div> */}
              <Input
                type="password"
                value={password}
                placeholder="Password *"
                onchange={(value) => {
                  setPassword(value);
                  setError({ ...error, password: "" });
                }}
                classes="text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]"
              />
            </div>
            {error.password && (
              <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                {error.password}
              </div>
            )}
          </div>
          <FormButton
            onclick={() => {
              if (!username) {
                setError({ ...error, username: "Please enter your email." });
                return;
              }
              if (!password) {
                setError({ ...error, password: "Please enter your password." });
                return;
              }
              setLoader(1);
              login();
            }}
            classes="cursor-pointer inline-block p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[15px]"
          >
            {loader === 1 && (
              <ClipLoader
                color="#ffffff"
                size="15px"
                className="rtl:ml-[10px] ltr:mr-[10px]"
              />
            )}
            Login
          </FormButton>
          <FormButton
            onclick={() => {
              navigate('/register')
            }}
            classes="mt-[15px] cursor-pointer inline-block cursor-pointer inline-block p-[15px] text-center upppercase bg-[#000000] text-[#fff] w-full rounded-[15px]"
          >
            Register
          </FormButton>
          {error.default && (
          <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
            {error.default}
          </div>
        )}
          <Link
            to="/reset"
            className="mt-[30px] inline-block upppercase text-[#000]"
          >
            Forget password?
          </Link>
        </form>
        
      </main>
      <Footer />
    </>
  );
}
