import { useNavigate } from "react-router-dom";

export default function BackButton() {
  const navigate = useNavigate();

  return (
    <div className="px-[1rem] md:px-[1.5rem] lg:px-[3.5rem] pt-[1.25rem] pb-[1.25rem] md:pb-[1.94rem]">
      <span
        className="flex items-center cursor-pointer text-black"
        onClick={() => navigate(-1)}
      >
        <span className="w-[1.875rem] h-[1.875rem] flex items-center justify-center">
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.25 15L20 20.75L18.25 22.5L10.75 15L18.25 7.5L20 9.25L14.25 15Z"
              fill="black"
              fillOpacity="0.8"
            />
          </svg>
        </span>
        <span className="text-[12px] leading-normal">Back</span>
      </span>
    </div>
  );
}
