import React from "react";
import AppContext from "../context/context";
import { useContext } from "react";
import { useState } from "react";
import Input from "../components/input/input";
import FormButton from "../components/form-button/form-button";
import { ClipLoader } from "react-spinners";
// import AddressCard from "../components/address-card/address-card";
import CloseIcon from "../assets/img/close_icon.svg";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Dialog } from "@headlessui/react";

export default function Profile() {
  const { PUBLIC_URL, getToken, getCustomer, logout, COUNTRY_NAMES } =
    useContext(AppContext);
  const customer = getCustomer().customer ? getCustomer().customer : null;
  const [name, setName] = useState(
    customer && customer.name ? customer.name : null
  );
  const [companyName, setCompanyName] = useState(
    customer && customer.company_name ? customer.company_name : null
  );
  const [lisence, setLisence] = useState(
    customer && customer.lisence_number ? customer.lisence_number : null
  );
  const [lisenceFile, setLisenceFile] = useState(
    customer && customer.lisence_number ? customer.lisence_number : null
  );
  const [OTRN, setOTRN] = useState(
    customer && customer.otrn_number ? customer.otrn_number : null
  );
  const [OTRNFile, setOTRNFile] = useState();
  const [countryId, setCountryId] = useState(
    customer && customer.country_id ? customer.country_id : 0
  );
  const [mobile, setMobile] = useState(
    customer && customer.mobile ? customer.mobile : null
  );
  const [email, setEmail] = useState(
    customer && customer.email ? customer.email : null
  );
  const [loader, setLoader] = useState(0);
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePasswordPage, setChangePasswordPage] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [zoomToggle, setZoomToggle] = useState(false);
  const [activeImage, setActiveImage] = useState();
  const navigate = useNavigate();
  const [error, setError] = useState({
    default: "",
    name: "",
    companyName: "",
    lisence: "",
    otrn: "",
    email: "",
    password: "",
    currentPassword: "",
    confirmPassword: "",
  });

  async function changeHandler() {
    try {
      if (!currentPassword) {
        setError({
          ...error,
          currentPassword: "Please, enter your current password.",
        });
        return;
      }

      if (!password) {
        setError({
          ...error,
          password: "Please, enter your new password.",
        });
        return;
      }

      if (!confirmPassword) {
        setError({
          ...error,
          confirmPassword: "Please, enter confirm password.",
        });
        return;
      }

      if (password !== confirmPassword) {
        setError({
          ...error,
          confirmPassword: "Your password and confirm password are not equal.",
        });
        return;
      }

      setLoader(1);
      await fetch(`${PUBLIC_URL}/api/customer/changepassword`, {
        method: "POST",
        body: JSON.stringify({
          currentPassword: currentPassword,
          password: password,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setLoader(0);
          if (data.token) {
            localStorage.setItem("token", data.token);
            setChangePasswordPage(false);
            enqueueSnackbar("Your password has been changed", {
              variant: "success",
              vertical: "bottom",
              horizontal: "left",
            });
          } else if (data.status === 2) {
            setError({
              ...error,
              default: "Please, enter required fields.",
            });
          }
        });
    } catch (error) {
      setError({
        ...error,
        default: "Error",
      });
      setLoader(0);
    }
  }

  async function editHandler() {
    try {
      if (!name) {
        setError({ ...error, name: "Please, enter your name." });
        return;
      }

      if (!companyName) {
        setError({ ...error, companyName: "Please, enter your company name." });
        return;
      }

      if (!email) {
        setError({ ...error, email: "Please, enter your email." });
        return;
      }
      setLoader(1);
      await fetch(`${PUBLIC_URL}/api/customer/edit`, {
        method: "POST",
        body: JSON.stringify({
          name: name,
          companyName: companyName,
          otrn: OTRNFile,
          lisence: lisenceFile,
          country: countryId,
          mobile: mobile,
          email: email,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          console.log(data)
          setLoader(0);
          if (data.status === 1) {
            // localStorage.removeItem("token");
            // enqueueSnackbar(
            //   "Your profile was edited. Your account will be available after Shawn's approval.",
            //   {
            //     variant: "success",
            //     vertical: "bottom",
            //     horizontal: "left",
            //   }
            // );
            // navigate("/");
          }else if (data.status === 3) {
            setError({
              ...error,
              email: "Please, enter your email correctly.",
            });
          } else {
            // setError({ ...error, default: t('amaliyat_namovafagh') })
          }
        });
    } catch (error) {
      // setError({ ...error, default: t('khata_dar_amaliyat') })
      setLoader(0);
    }
  }

  return changePasswordPage ? (
    <div className="">
      <form className="w-full md:w-[80%]">
        <div className="mb-[6px]">
          <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
            <div
              className={`w-[250px] bg-[rgba(0,0,0,0.05)] ${
                error.currentPassword ? "text-[#f00]" : "text-black"
              } p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right`}
            >
              Current_password *
            </div>
            <Input
              type="password"
              value={currentPassword}
              onchange={(value) => {
                setCurrentPassword(value);
                setError({ ...error, currentPassword: "" });
              }}
              classes="text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]"
            />
          </div>
          {error.currentPassword && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.currentPassword}
            </div>
          )}
        </div>
        <div className="mb-[6px]">
          <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
            <div
              className={`w-[250px] bg-[rgba(0,0,0,0.05)] ${
                error.password ? "text-[#f00]" : "text-[#000]"
              } p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right`}
            >
              Password *
            </div>
            <Input
              type="password"
              value={password}
              onchange={(value) => {
                setPassword(value);
                setError({ ...error, password: "" });
              }}
              classes="text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]"
            />
          </div>
          {error.password && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.password}
            </div>
          )}
        </div>
        <div className="mb-[6px]">
          <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
            <div
              className={`w-[250px] bg-[rgba(0,0,0,0.05)] ${
                error.confirmPassword ? "text-[#f00]" : "text-[#000]"
              } p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right`}
            >
              Confirm_password *
            </div>
            <Input
              type="password"
              value={confirmPassword}
              onchange={(value) => {
                setConfirmPassword(value);
                setError({ ...error, confirmPassword: "" });
              }}
              classes="text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]"
            />
          </div>
          {error.confirmPassword && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.confirmPassword}
            </div>
          )}
        </div>
        <FormButton
          onclick={() => {
            changeHandler();
          }}
          classes="cursor-pointer inline-block p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[15px]"
        >
          {loader === 1 && (
            <ClipLoader
              color="#ffffff"
              size="15px"
              className="rtl:ml-[10px] ltr:mr-[10px]"
            />
          )}
          Change
        </FormButton>
        <FormButton
          onclick={() => {
            setChangePasswordPage(false);
          }}
          classes="cursor-pointer inline-block mt-[6px] p-[15px] text-center upppercase bg-[#00000033] text-[#000] w-full rounded-[20px]"
        >
          Back
        </FormButton>
      </form>
    </div>
  ) : (
    <div className="relative">
      <form className="w-full md:w-[70%]">
        <div className="mb-[6px]">
          <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
            <div
              className={`w-[250px] bg-[rgba(0,0,0,0.05)] ${
                error.name ? "text-[#f00]" : "text-[#000]"
              } p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right`}
            >
              Name *
            </div>
            <Input
              type="text"
              value={name}
              onchange={(value) => {
                setName(value);
                setError({ ...error, name: "" });
              }}
              classes="w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]"
            />
          </div>
          {error.name && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.name}
            </div>
          )}
        </div>

        <div className="mb-[6px]">
          <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
            <div
              className={`w-[250px] bg-[rgba(0,0,0,0.05)] ${
                error.companyName ? "text-[#f00]" : "text-[#000]"
              } p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right`}
            >
              Company Name *
            </div>
            <Input
              type="text"
              value={companyName}
              onchange={(value) => {
                setCompanyName(value);
                setError({ ...error, companyName: "" });
              }}
              classes="w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]"
            />
          </div>
          {error.companyName && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.companyName}
            </div>
          )}
        </div>

        <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)] mb-[6px]">
          <div className="w-[250px] bg-[rgba(0,0,0,0.05)] text-[#000] p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right">
            Country
          </div>
          <select
            value={countryId}
            onChange={(event) => {
              setCountryId(event.target.value);
            }}
            className="w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)] px-[10px] text-[11px] md:text-[14px]"
          >
            {COUNTRY_NAMES.map((country, index) => {
              return (
                <option key={index} value={country.id}>
                  {country.name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="mb-[6px]">
          <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
            <div
              className={`w-[250px] bg-[rgba(0,0,0,0.05)] ${
                error.lisence ? "text-[#f00]" : "text-[#000]"
              } p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right`}
            >
              Lisence
            </div>
            <input
              type="file"
              accept=".jpg,.jpeg,.png,.webp,.pdf"
              onChange={(event) => {
                var file = event.target.files[0];

                var reader = new FileReader();
                reader.onload = async (event) => {
                  setLisenceFile(event.target.result);
                };

                reader.readAsDataURL(file);
              }}
              className={`text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]`}
            />
          </div>
          {lisence && (
            <div className="relative w-full md:w-[40%]">
              {lisence.split(".").pop() === "pdf" ? (
                <a
                  href={`${PUBLIC_URL}/uploads/${lisence}`}
                  target="_blank"
                  className="bg-black text-white rounded-[15px] cursor-pointer p-[5px_15px] inline-block my-[10px]"
                >
                  Download Lisence PDF
                </a>
              ) : (
                <>
                  <img src={`${PUBLIC_URL}/uploads/${lisence}`} />
                  <a
                    href={`${PUBLIC_URL}/uploads/${lisence}`}
                    target="_blank"
                    className="absolute top-[0.62rem] right-[0.62rem] z-[2] cursor-pointer"
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_b_806_3587)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.67969 15.6294C1.67969 23.7089 8.22948 30.2587 16.3091 30.2587C24.3886 30.2587 30.9384 23.7089 30.9384 15.6294C30.9384 7.54979 24.3886 1 16.3091 1C8.22948 1 1.67969 7.54979 1.67969 15.6294ZM16.3091 0C7.6772 0 0.679688 6.99751 0.679688 15.6294C0.679688 24.2612 7.6772 31.2587 16.3091 31.2587C24.9409 31.2587 31.9384 24.2612 31.9384 15.6294C31.9384 6.99751 24.9409 0 16.3091 0ZM12.1119 7.339C9.41632 9.14285 8.26156 12.7012 10.0187 15.6424C11.8225 18.338 15.3809 19.4927 18.3221 17.7356C21.0177 15.9318 22.1724 12.3734 20.4153 9.43218C18.6114 6.73664 15.0531 5.58187 12.1119 7.339ZM18.3749 18.8469C15.0023 20.5433 11.1385 19.125 9.18002 16.1872C9.17562 16.1806 9.17138 16.1739 9.1673 16.1671C7.08101 12.69 8.49128 8.55087 11.5671 6.50034C11.5737 6.49594 11.5804 6.4917 11.5872 6.48761C15.0643 4.40132 19.2034 5.81159 21.254 8.88739C21.2584 8.89399 21.2626 8.90069 21.2667 8.90749C23.2694 12.2453 22.0501 16.1932 19.2284 18.3179L19.9586 19.7784C20.6248 19.7195 21.2065 20.0681 21.5127 20.3743C21.5464 20.408 21.5752 20.4465 21.5981 20.4884L23.5252 24.0215C23.5281 24.0268 23.5309 24.032 23.5335 24.0374C23.9905 24.9513 23.5184 25.8208 23.1187 26.2205C23.0807 26.2585 23.0368 26.2901 22.9887 26.3142C22.0747 26.7712 21.2053 26.2991 20.8056 25.8993C20.7718 25.8655 20.743 25.8271 20.7202 25.7852L18.793 22.252C18.7914 22.2491 18.7899 22.2462 18.7883 22.2433C18.7871 22.2409 18.7859 22.2386 18.7847 22.2362C18.3813 21.4294 18.7019 20.6573 19.0576 20.2124L18.3749 18.8469ZM19.8496 20.8221C19.6403 21.0678 19.5105 21.4429 19.6757 21.782L21.5601 25.2368C21.7899 25.4396 22.1409 25.5739 22.4687 25.4515C22.6779 25.2058 22.8078 24.8307 22.6425 24.4916L20.7581 21.0368C20.5283 20.8339 20.1774 20.6996 19.8496 20.8221ZM14.7365 13.5795V16.304H15.8501V13.5795H18.5746V12.4659H15.8501V9.74148H14.7365V12.4659H12.0121V13.5795H14.7365Z"
                          fill="black"
                          fillOpacity="0.5"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_b_806_3587"
                          x="-3.32031"
                          y="-4"
                          width="39.2588"
                          height="39.2587"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feGaussianBlur
                            in="BackgroundImageFix"
                            stdDeviation="2"
                          />
                          <feComposite
                            in2="SourceAlpha"
                            operator="in"
                            result="effect1_backgroundBlur_806_3587"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_backgroundBlur_806_3587"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </a>
                </>
              )}
            </div>
          )}
          {error.lisence && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.lisence}
            </div>
          )}
        </div>

        <div className="mb-[6px]">
          <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
            <div
              className={`w-[250px] bg-[rgba(0,0,0,0.05)] ${
                error.otrn ? "text-[#f00]" : "text-[#000]"
              } p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right`}
            >
              TRN
            </div>
            <input
              type="file"
              accept=".jpg,.jpeg,.png,.webp,.pdf"
              onChange={(event) => {
                var file = event.target.files[0];

                var reader = new FileReader();
                reader.onload = async (event) => {
                  setOTRNFile(event.target.result);
                };

                reader.readAsDataURL(file);
              }}
              className={`text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]`}
            />
          </div>
          {OTRN && (
            <div className="w-full md:w-[40%] relative">
              {OTRN.split(".").pop() === "pdf" ? (
                <a
                  href={`${PUBLIC_URL}/uploads/${OTRN}`}
                  target="_blank"
                  className="bg-black text-white rounded-[15px] cursor-pointer p-[5px_15px] inline-block my-[10px]"
                >
                  Download TRN PDF
                </a>
              ) : (
                <>
                  <img src={`${PUBLIC_URL}/uploads/${OTRN}`} />
                  <a
                    href={`${PUBLIC_URL}/uploads/${OTRN}`}
                    target="_blank"
                    className="absolute top-[0.62rem] right-[0.62rem] z-[2] cursor-pointer"
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_b_806_3587)">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.67969 15.6294C1.67969 23.7089 8.22948 30.2587 16.3091 30.2587C24.3886 30.2587 30.9384 23.7089 30.9384 15.6294C30.9384 7.54979 24.3886 1 16.3091 1C8.22948 1 1.67969 7.54979 1.67969 15.6294ZM16.3091 0C7.6772 0 0.679688 6.99751 0.679688 15.6294C0.679688 24.2612 7.6772 31.2587 16.3091 31.2587C24.9409 31.2587 31.9384 24.2612 31.9384 15.6294C31.9384 6.99751 24.9409 0 16.3091 0ZM12.1119 7.339C9.41632 9.14285 8.26156 12.7012 10.0187 15.6424C11.8225 18.338 15.3809 19.4927 18.3221 17.7356C21.0177 15.9318 22.1724 12.3734 20.4153 9.43218C18.6114 6.73664 15.0531 5.58187 12.1119 7.339ZM18.3749 18.8469C15.0023 20.5433 11.1385 19.125 9.18002 16.1872C9.17562 16.1806 9.17138 16.1739 9.1673 16.1671C7.08101 12.69 8.49128 8.55087 11.5671 6.50034C11.5737 6.49594 11.5804 6.4917 11.5872 6.48761C15.0643 4.40132 19.2034 5.81159 21.254 8.88739C21.2584 8.89399 21.2626 8.90069 21.2667 8.90749C23.2694 12.2453 22.0501 16.1932 19.2284 18.3179L19.9586 19.7784C20.6248 19.7195 21.2065 20.0681 21.5127 20.3743C21.5464 20.408 21.5752 20.4465 21.5981 20.4884L23.5252 24.0215C23.5281 24.0268 23.5309 24.032 23.5335 24.0374C23.9905 24.9513 23.5184 25.8208 23.1187 26.2205C23.0807 26.2585 23.0368 26.2901 22.9887 26.3142C22.0747 26.7712 21.2053 26.2991 20.8056 25.8993C20.7718 25.8655 20.743 25.8271 20.7202 25.7852L18.793 22.252C18.7914 22.2491 18.7899 22.2462 18.7883 22.2433C18.7871 22.2409 18.7859 22.2386 18.7847 22.2362C18.3813 21.4294 18.7019 20.6573 19.0576 20.2124L18.3749 18.8469ZM19.8496 20.8221C19.6403 21.0678 19.5105 21.4429 19.6757 21.782L21.5601 25.2368C21.7899 25.4396 22.1409 25.5739 22.4687 25.4515C22.6779 25.2058 22.8078 24.8307 22.6425 24.4916L20.7581 21.0368C20.5283 20.8339 20.1774 20.6996 19.8496 20.8221ZM14.7365 13.5795V16.304H15.8501V13.5795H18.5746V12.4659H15.8501V9.74148H14.7365V12.4659H12.0121V13.5795H14.7365Z"
                          fill="black"
                          fillOpacity="0.5"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_b_806_3587"
                          x="-3.32031"
                          y="-4"
                          width="39.2588"
                          height="39.2587"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood
                            floodOpacity="0"
                            result="BackgroundImageFix"
                          />
                          <feGaussianBlur
                            in="BackgroundImageFix"
                            stdDeviation="2"
                          />
                          <feComposite
                            in2="SourceAlpha"
                            operator="in"
                            result="effect1_backgroundBlur_806_3587"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_backgroundBlur_806_3587"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </a>
                </>
              )}
            </div>
          )}

          {error.otrn && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.otrn}
            </div>
          )}
        </div>

        <div className="flex rounded-[20px] overflow-hidden mb-[6px] bg-[rgba(0,0,0,0.05)]">
          <div className="w-[250px] bg-[rgba(0,0,0,0.05)] text-[#000] p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right">
            Contact Number
          </div>
          <Input
            type="text"
            value={mobile}
            onchange={(value) => {
              setMobile(value);
            }}
            classes="w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]"
          />
        </div>
        <div className="mb-[6px]">
          <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)] bg-[rgba(0,0,0,0.05)]">
            <div
              className={`w-[250px] bg-[rgba(0,0,0,0.05)] ${
                error.email ? "text-[#f00]" : "text-[#000]"
              } p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right`}
            >
              Email *
            </div>
            <Input
              type="text"
              value={email}
              onchange={(value) => {
                setEmail(value);
                setError({ ...error, email: "" });
              }}
              classes="w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]"
            />
          </div>
          {error.email && (
            <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
              {error.email}
            </div>
          )}
        </div>

        <FormButton
          onclick={() => {
            editHandler();
          }}
          classes="p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[15px]"
        >
          {loader === 1 && (
            <ClipLoader
              color="#ffffff"
              size="15px"
              className="rtl:ml-[10px] ltr:mr-[10px]"
            />
          )}
          Edit
        </FormButton>
        <FormButton
          onclick={() => {
            setChangePasswordPage(true);
          }}
          classes="mt-[10px] p-[15px] text-center upppercase bg-[#00000033] text-[#000] w-full rounded-[20px]"
        >
          Change Password
        </FormButton>
        <FormButton
          onclick={() => {
            logout();
            navigate("/");
          }}
          classes="mt-[10px] p-[15px] text-center upppercase bg-[#000] text-[#fff] w-full rounded-[20px]"
        >
          Logout
        </FormButton>
      </form>
      {error.default && (
        <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
          {error.default}
        </div>
      )}
    </div>
  );
}
