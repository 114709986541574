import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";
import { Autoplay } from "swiper/modules";
// import Slide1 from "../../assets/img/banner-17-1.jpg";
import Slide2 from "../../assets/img/banner-17-2.jpg";
import Slide3 from "../../assets/img/banner-17-3.jpg";

// import MobileSlide1 from "../../assets/img/banner-m-17-1.jpg";
import MobileSlide2 from "../../assets/img/banner-m-17-2.jpg";
import MobileSlide3 from "../../assets/img/banner-m-17-3.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function BannerSlider() {
  return window.innerWidth > 767 ? (
    <LazyLoadImage src={Slide3} alt="slider 3" style={{ width: "100%" }} />
  ) : (
    <LazyLoadImage
      src={MobileSlide3}
      alt="slider 3"
      style={{ width: "100%" }}
    />
  );
  // <Swiper
  //   loop={true}
  //   modules={[Autoplay]}
  //   speed={2000}
  //   autoplay={{
  //     delay: 5000,
  //     disableOnInteraction: false,
  //   }}
  // >
  //   {window.innerWidth > 767 ? (
  //     <>
  //       {/* <SwiperSlide>
  //         <LazyLoadImage
  //           src={Slide1}
  //           alt="slider 1"
  //           style={{ width: "100%" }}
  //         />
  //       </SwiperSlide> */}
  //       <SwiperSlide>
  //         <LazyLoadImage
  //           src={Slide2}
  //           alt="slider 2"
  //           style={{ width: "100%" }}
  //         />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <LazyLoadImage
  //           src={Slide3}
  //           alt="slider 3"
  //           style={{ width: "100%" }}
  //         />
  //       </SwiperSlide>
  //     </>
  //   ) : (
  //     <>
  //       {/* <SwiperSlide>
  //         <LazyLoadImage
  //           src={MobileSlide1}
  //           alt="slider 1"
  //           style={{ width: "100%" }}
  //         />
  //       </SwiperSlide> */}
  //       <SwiperSlide>
  //         <LazyLoadImage
  //           src={MobileSlide2}
  //           alt="slider 2"
  //           style={{ width: "100%" }}
  //         />
  //       </SwiperSlide>
  //       <SwiperSlide>
  //         <LazyLoadImage
  //           src={MobileSlide3}
  //           alt="slider 3"
  //           style={{ width: "100%" }}
  //         />
  //       </SwiperSlide>
  //     </>
  //   )}
  // </Swiper>
}
