import ShippingIcon from "../assets/img/shipping.png";
import QualityIcon from "../assets/img/quality.png";
import MoneyIcon from "../assets/img/money.png";
import SupportIcon from "../assets/img/support.png";
import FacebookIcon from "../assets/img/facebook-white.svg";
import TwitterIcon from "../assets/img/twitter-white.svg";
import InstagramIcon from "../assets/img/instagram-white.svg";

export default function Footer() {
  return (
    <footer className="bg-[#000] p-[3.44rem_1rem_1.33rem_1rem] md:p-[1.75rem_1.5rem] lg:p-[1.75rem_6.25rem]">
      <div className="block md:hidden h-[1px] w-full bg-white mb-[1.37rem]"></div>
      <div className="flex items-start md:items-center">
        <div className="flex items-start md:items-center text-white w-full justify-center md:justify-start text-center md:text-start flex-wrap md:flex-nowrap">
          <img src={ShippingIcon} alt="shopping" />
          <span className="w-full lg:w-auto ml-0 md:ml-[1.19rem]">
            Shipping to all parts of the country
          </span>
        </div>
        <div className="hidden lg:flex items-center justify-center text-white w-full">
          <img src={QualityIcon} alt="quality" />
          <span className="ml-[1.19rem]">Product quality assurance</span>
        </div>
        <div className="flex items-start md:items-center justify-center text-white w-full text-center md:text-start flex-wrap md:flex-nowrap">
          <img src={MoneyIcon} alt="guarantee" />
          <span className="w-full lg:w-auto ml-0 md:ml-[1.19rem]">
            Money back guarantee
          </span>
        </div>
        <div className="flex items-start md:items-center justify-center md:justify-end text-white w-full text-center md:text-start flex-wrap md:flex-nowrap">
          <img src={SupportIcon} alt="support"/>
          <span className="w-full lg:w-auto ml-0 md:ml-[1.19rem]">
            Excellent response
          </span>
        </div>
      </div>
      <div className="h-[1px] bg-white w-full my-[1.6rem]"></div>
      <div className="flex text-white flex-wrap md:flex-nowrap">
        <div className="w-full">
          <div className="flex justify-center md:justify-start">
            Company Name : SHAWN TRADING CO L.L.C
          </div>
          <div className="flex justify-center md:justify-start">
            Tel : <a href="tel:009716372879">+971506372879</a>
          </div>
          <div className="flex justify-center md:justify-start">
            Web : www.shawn.ae
          </div>
          <div className="flex justify-center md:justify-start">
            Email : <a href="mailto:info@shawn.ae">info@shawn.ae</a>
          </div>
        </div>
        <div className="w-full">
          <div className="flex md:hidden justify-center my-[1.75rem] gap-[0.62rem]">
            <a
              href="https://www.facebook.com/shawn.uae"
              rel="noopener noreferrer"
            >
              <img src={FacebookIcon} className="w-[20px] h-[20px]" alt="facebook" />
            </a>
            <a
              href="https://x.com/shawnpet96252?s=11"
              rel="noopener noreferrer"
            >
              <img src={TwitterIcon} className="w-[20px] h-[20px]" alt="twitter" />
            </a>
            <a
              href="https://www.instagram.com/shawn.uae"
              rel="noopener noreferrer"
            >
              <img src={InstagramIcon} className="w-[20px] h-[20px]" alt="instagram" />
            </a>
          </div>
          <div className="flex justify-center md:justify-end">
            License No : 1133371
          </div>
          <div className="flex justify-center md:justify-end">
            Register No : 1872714
          </div>
          <div className="hidden md:flex justify-end mt-[0.58rem] gap-[0.62rem]">
            <a
              href="https://www.facebook.com/shawn.uae"
              rel="noopener noreferrer"
            >
              <img src={FacebookIcon} className="w-[20px] h-[20px]" alt="facebook" />
            </a>
            <a
              href="https://x.com/shawnpet96252?s=11"
              rel="noopener noreferrer"
            >
              <img src={TwitterIcon} className="w-[20px] h-[20px]" alt="twitter" />
            </a>
            <a
              href="https://www.instagram.com/shawn.uae"
              rel="noopener noreferrer"
            >
              <img src={InstagramIcon} className="w-[20px] h-[20px]" alt="instagram" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
