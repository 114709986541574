import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/effect-fade";
import { Autoplay, EffectFade } from "swiper/modules";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function FadedSlider(props) {
  return (
    <Swiper
      loop={true}
      effect="fade"
      speed="2000"
      modules={[Autoplay, EffectFade]}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
    >
      {props.images &&
        props.images.map((image, index) => {
          return (
            <SwiperSlide key={index}>
              <LazyLoadImage src={image} alt="slider 1" style={{ width: "100%" }} />
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
}
