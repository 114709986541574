import React from "react";
import { useContext } from "react";
import { useState } from "react";
import Input from "../components/input/input";
import FormButton from "../components/form-button/form-button";
import { ClipLoader } from "react-spinners";
import { useSnackbar } from "notistack";
import AppContext from "../context/context";
import Header from "./header";
import Footer from "./footer";
import { useNavigate, useParams } from "react-router-dom";

export default function ConfirmAccount() {
  const { PUBLIC_URL } = useContext(AppContext);
  let { email } = useParams();
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(0);
  const [loaderResend, setLoaderResend] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [error, setError] = useState({
    default: "",
    email: "",
    code: "",
  });

  async function confirmRequest() {
    try {
      if (!code) {
        setError({ ...error, code: "Please, enter confirm code." });
        return;
      }
      setLoader(1);
      await fetch(`${PUBLIC_URL}/api/customer/confirm`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
          code: code,
        }),
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.status === 1) {
            setLoader(0);
            setMessage("Your account will be activated after admin approval.");
          } else {
            setError({ ...error, default: "Error" });
            setLoader(0);
          }
        });
    } catch (error) {
      setError({ ...error, default: "Error" });
      setLoader(0);
    }
  }

  async function resendRequest() {
    // setError('')
    setLoaderResend(1);
    try {
      await fetch(`${PUBLIC_URL}/api/customer/confirmresend`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
        }),
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setLoaderResend(0);
          if (data.status === 1) {
            // setMessage(t('code_taid_dobare_ersal_shod'))
            enqueueSnackbar("New code has been send.", {
              variant: "success",
              vertical: "bottom",
              horizontal: "left",
            });
          } else {
            setError({ ...error, default: "Error" });
          }
        });
    } catch (error) {
      setError({ ...error, default: "Error" });
      setLoaderResend(0);
    }
  }

  return (
    <>
      <Header />
      <main className="bg-[rgba(0,0,0,0.05)] py-[60px]">
        {message ? (
          <div className="w-[95%] md:w-[40%] bg-white mx-auto p-[60px_20px] border-[1px] border-solid border-[rgba(0,0,0,0.05)] shadow rounded-[15px]">
            <h1 className="text-center font-[500] text-[24px] mb-[15px]">
              Confirm Your Account
            </h1>
            <div className="mb-[6px]">
              <div className="text-center">{message}</div>
              <FormButton
                onclick={() => {
                  navigate("/");
                }}
                classes="mt-[15px] cursor-pointer inline-block cursor-pointer inline-block p-[15px] text-center upppercase bg-[#000000] text-[#fff] w-full rounded-[15px]"
              >
                Back Home Page
              </FormButton>
            </div>
          </div>
        ) : (
          <form className="w-[95%] md:w-[40%] bg-white mx-auto p-[60px_20px] border-[1px] border-solid border-[rgba(0,0,0,0.05)] shadow rounded-[15px]">
            <h1 className="text-center font-[500] text-[24px] mb-[15px]">
              Confirm Your Account
            </h1>

            <div className="mb-[6px]">
              <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
                {/* <div
                  className={`w-[250px] bg-[rgba(0,0,0,0.05)] ${
                    error.code ? "text-[#f00]" : "text-[#000]"
                  } p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right`}
                >
                  Confirm Code *
                </div> */}
                <Input
                  type="text"
                  placeholder="Confirm Code *"
                  value={code}
                  onchange={(value) => {
                    setCode(value);
                    setError({ ...error, code: "" });
                  }}
                  classes="text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]"
                />
              </div>
              {error.code && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.code}
                </div>
              )}
            </div>
            <FormButton
              onclick={() => {
                confirmRequest();
              }}
              classes="cursor-pointer inline-block cursor-pointer inline-block p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[15px]"
            >
              {loader === 1 && (
                <ClipLoader
                  color="#ffffff"
                  size="15px"
                  className="rtl:ml-[10px] ltr:mr-[10px]"
                />
              )}
              Confirm
            </FormButton>
            <FormButton
              onclick={() => {
                resendRequest();
              }}
              classes="mt-[15px] cursor-pointer inline-block cursor-pointer inline-block p-[15px] text-center upppercase bg-[#000000] text-[#fff] w-full rounded-[15px]"
            >
              {loaderResend === 1 && (
                <ClipLoader
                  color="#ffffff"
                  size="15px"
                  className="rtl:ml-[10px] ltr:mr-[10px]"
                />
              )}
              Resend Code
            </FormButton>
            {error.default && (
              <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                {error.default}
              </div>
            )}
          </form>
        )}
      </main>
      <Footer />
    </>
  );
}
