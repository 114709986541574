import FormButton from "../form-button/form-button";

export default function CartAddressItemCard(props) {
  const item = props.item ? props.item : null;

  return (
    <div className="mt-[6px]">
      <div className="w-full">
        <span className="text-[#603085] text-[12px] inline-block">

            <span onClick={props.onClick} className="cursor-pointer">
              {item.name && item.name}
            </span>
        </span>
      </div>
      <div
        className={`flex mt-[5px] pb-[7px] border-b-[1px] border-solid border-[rgba(30,30,30,0.15)]`}
      >
        <div className="w-full !text-[12px]" onClick={props.onClick}>

            <span className="cursor-pointer">{item.address}</span>


            <div className="w-full cursor-pointer">{item.postal_code}</div>
        </div>

        <div className="w-[20%] flex items-start justify-end gap-[5px]">
            <FormButton
              onclick={() => {
                props.onClick();
              }}
              classes="p-[2px_15px] text-center bg-black text-white rounded-[50px] text-[12px] capitalize"
            >
              Select
            </FormButton>
          
        </div>
      </div>
    </div>
  );
}
