import React from "react";
import { useContext } from "react";
import { useState } from "react";
import Input from "../components/input/input";
import FormButton from "../components/form-button/form-button";
import { ClipLoader } from "react-spinners";
import { useSnackbar } from "notistack";
import AppContext from "../context/context";
import Header from "./header";
import Footer from "./footer";
import { useNavigate, useParams } from "react-router-dom";

export default function ResetPassword() {
  const { PUBLIC_URL } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [error, setError] = useState({
    default: "",
    email: "",
  });

  async function resetRequest() {
    try {
      if (!email) {
        setError({ ...error, email: "Please, enter your email." });
        return;
      }

      setError({
        ...error,
        default: "",
      });

      setLoader(1);

      await fetch(`${PUBLIC_URL}/api/customer/reset`, {
        method: "POST",
        body: JSON.stringify({
          email: email,
        }),
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setLoader(0);
          if (data.status === 1) {
            setMessage("Reset password link has been send to your email.");
          } else {
            setError({ ...error, default: "Error" });
          }
        });
    } catch (err) {
      enqueueSnackbar("Error", {
        variant: "error",
        vertical: "bottom",
        horizontal: "left",
      });
      setLoader(0);
    }
  }

  const resetHandler = () => {
    resetRequest();
  };

  return (
    <>
      <Header />
      <main className="bg-[rgba(0,0,0,0.05)] py-[60px]">
        {message ? (
          <div className="w-[95%] md:w-[40%] bg-white mx-auto p-[60px_20px] border-[1px] border-solid border-[rgba(0,0,0,0.05)] shadow rounded-[15px]">
            <h1 className="text-center font-[500] text-[24px] mb-[15px]">
              Reset Password
            </h1>
            <div className="mb-[6px]">
              <div className="text-center">{message}</div>
            </div>
          </div>
        ) : (
          <form className="w-[95%] md:w-[40%] bg-white mx-auto p-[60px_20px] border-[1px] border-solid border-[rgba(0,0,0,0.05)] shadow rounded-[15px]">
            <h1 className="text-center font-[500] text-[24px] mb-[15px]">
              Reset Password
            </h1>

            <div className="mb-[6px]">
              <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
                {/* <div
                  className={`w-[250px] bg-[rgba(0,0,0,0.05)] ${
                    error.email ? "text-[#f00]" : "text-[#000]"
                  } p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right`}
                >
                  Email *
                </div> */}
                <Input
                  type="text"
                  placeholder="Email *"
                  value={email}
                  onchange={(value) => {
                    setEmail(value);
                    setError({ ...error, email: "" });
                  }}
                  classes="text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]"
                />
              </div>
              {error.email && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.email}
                </div>
              )}
            </div>
            <FormButton
              onclick={() => {
                resetHandler();
              }}
              classes="cursor-pointer inline-block p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[15px]"
            >
              {loader === 1 && (
                <ClipLoader
                  color="#ffffff"
                  size="15px"
                  className="rtl:ml-[10px] ltr:mr-[10px]"
                />
              )}
              Reset Password
            </FormButton>
            <FormButton
              onclick={() => {
                navigate("/login");
              }}
              classes="mt-[15px] cursor-pointer inline-block cursor-pointer inline-block p-[15px] text-center upppercase bg-[#000000] text-[#fff] w-full rounded-[15px]"
            >
              Login
            </FormButton>
            {error.default && (
              <div className="text-[#f00] mt-[6px]">{error.default}</div>
            )}
          </form>
        )}
      </main>
      <Footer />
    </>
  );
}
