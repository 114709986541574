import AddToCartButton from "../add-to-cart-button/add-to-cart-button";
import Price from "../price/price";
import { Link } from "react-router-dom";
import React,{useState} from "react";
import ProductDefaultImage from "../../assets/img/produc-placeholder.png";
// import { extractProductImages } from "../functions";

export default function CartProductItemCard(props) {
  const item = props.item;
  return (
    <div className="relative rounded-[20px] border-[0.5px] border-solid border-[rgba(0,0,0,0.25)] shadow-[0_4px_10px_0_rgba(0,0,0,0.25)] flex p-[5px] lg:p-[5px] bg-[#fff] mb-[5px]">
      <div className="w-[15.6%] flex items-center justify-center border-r-[1px] border-solid border-[rgba(0,0,0,0.20)]">
        <img
          src={
            item.images
              ? item.images.split(",")[0]
              : item.thumbnail
              ? item.thumbnail
              : ProductDefaultImage
          }
          alt={item.name}
          className="mx-auto"
        />
      </div>
      <div className="w-[calc(100%-15.6%)] pl-[4px] flex flex-col">
        {item.sale_price ? (
          <span className="absolute top-0 end-0 bg-[#FF547D] text-[#F9F9F9] rounded-[0_15px] text-[14px] 2xl:text-[18px] w-[45px] h-[45px] flex items-center justify-center shadow">
            {(100 - (+item.sale_price * 100) / +item.price).toFixed(0)}%
          </span>
        ) : (
          ""
        )}
        <Link
          to={`/product/${item.slug}`}
          className="text-[15px] md:text-[18px]"
        >
          <div>{item.name}</div>
        </Link>

        {item.attributes && (
              <div className="text-[10px]">
                {item.attributes.map((aitem, index) => {
                  return (
                    <span key={index} className="mr-[10px]">
                      {aitem.type === 1 ? (
                        <span
                          className={`w-[15px] h-[15px] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                          style={{ background: aitem.value }}
                        ></span>
                      ) : (
                        aitem.type_name + ":" + aitem.name
                      )}
                    </span>
                  );
                })}
              </div>
            )}

        <div className="mt-auto">
          {item.count > 30 && (
            <div className="text-[#FF547D] text-[10px] mb-[3px]">
              This item needs Shawn approval.
            </div>
          )}
          <div className="mt-auto flex items-end">
            <div className="w-[70%]">
              {item.sale_price ? (
                <>
                  <div className="text-[14px] font-[300] text-[#FF547D]">
                    <del>
                      <Price price={item.price} />
                    </del>
                  </div>
                  <div className="text-[#0BC154] text-[18px]">
                    <Price price={item.sale_price} />
                  </div>
                </>
              ) : (
                <span className="text-[#0BC154] text-[18px]">
                  <Price price={item.price} />
                </span>
              )}
            </div>
            <div className="w-[30%] flex justify-end items-end">
              <AddToCartButton
                attribute={item}
                text="Add To Cart"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
