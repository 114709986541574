import { Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Home from "./layouts/home";
import Shop from "./layouts/shop";
import { AppContextProvider } from "./context/context";
import Product from "./layouts/product";
import WishList from "./layouts/wishlist";
import ProductMeta from "./layouts/product-meta";
import Brands from "./layouts/brands";
import AboutUs from "./layouts/about-us";
import Login from "./layouts/login";
import Register from "./layouts/register";
import ConfirmAccount from "./layouts/confirm-account";
import ResetPassword from "./layouts/reset";
import ResetNewPassword from "./layouts/reset-password";
import Cart from "./layouts/cart";
import Dashboard from "./layouts/dashboard";
import ProductNew from "./layouts/product-new";

function App() {
  const theme = createTheme({
    typography: {},
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
    palette: {
      primary: {
        light: "#e9ecf4",
        main: "#234090",
        dark: "#2c3333",
        contrastText: "#fff",
      },
      secondary: {
        light: "#e9ecf4",
        main: "#ec4899",
        dark: "#2c3333",
        contrastText: "#fff",
      },
      purple: {
        light: "#603085",
        main: "#603085",
        dark: "#603085",
        contrastText: "#fff",
      },
      light: {
        light: "#ccc",
        main: "#666666",
        dark: "#2c3333",
        contrastText: "#fff",
      },
      dark: {
        light: "#000000",
        main: "#000000",
        dark: "#000000",
        contrastText: "#fff",
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/shop/:id" element={<Shop />} />
          <Route path="/shop/:id/:p" element={<Shop />} />
          <Route path="/brand" element={<Brands />} />
          <Route path="/brand/:id" element={<Brands />} />
          <Route path="/product/:slug" element={<Product />} />
          <Route path="/meta/:id" element={<ProductMeta />} />
          <Route path="/new" element={<ProductNew />} />
          <Route path="/wishlist" element={<WishList />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/confirm/:email" element={<ConfirmAccount />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/reset/:code" element={<ResetNewPassword />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/:slug" element={<Dashboard />} />
        </Routes>
      </AppContextProvider>
    </ThemeProvider>
  );
}

export default App;
