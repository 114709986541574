import { useContext, useState } from "react";
import SearchLogo from "../../assets/img/search-logo.png";
import SearchIconBlack from "../../assets/img/search-logo.png";
import CloseIcon from "../../assets/img/close_icon.svg";
import AppContext from "../../context/context";
import { Link, useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import MenuCart from "../menu-cart/menu_cart";
import Logo from "../../assets/img/logo.png";
import MobileLogo from "../../assets/img/logo-black.png";
import WishListIcon from "../../assets/img/header-wishlist-icon.svg";

export default function SearchBox() {
  const { filters, setFilters, getToken } = useContext(AppContext);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <img
        src={SearchIconBlack}
        className="inline-block md:hidden"
        onClick={() => {
          setToggle(!toggle);
        }}
        alt="search"
      />
      <img
        src={SearchLogo}
        className="hidden md:inline-block"
        onClick={() => {
          setToggle(!toggle);
        }}
        alt="search"
      />
      <Transition
        show={toggle}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {window.innerWidth > 767 ? (
          <div className="fixed top-0 left-0 flex items-center justify-center w-full h-[6vh] md:h-[10vh] bg-[#000e] p-[0.5625rem_1.02875rem_0.56013rem_1rem] md:p-[0.5rem_1.5rem] lg:p-[0.5rem_3.5rem]">
            <div className="w-[20%] lg:w-[12%]">
              <Link to="/" className="">
                {window.innerWidth > 991 ? (
                  <img src={Logo} alt="Shawn" className="inline-block" />
                ) : (
                  <img src={MobileLogo} alt="Shawn" className="inline-block" />
                )}
              </Link>
            </div>
            <form className="w-full md:w-[73%] flex items-center border-[1px] border-solid border-[#fff] lg:mt-0 p-[5px_25px]">
              <span
                onClick={() => {
                  navigate("/shop");
                  setToggle(false);
                }}
                className="w-[20px]"
              >
                <svg
                  width="17"
                  height="22"
                  viewBox="0 0 17 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9099 13.1582L11.8735 15.0854M10.9099 13.1582C13.8006 11.2311 15.0854 7.3767 13.1582 4.16474C11.2311 1.27397 7.3767 -0.0108178 4.16474 1.91636C1.27397 3.84354 -0.0108177 7.6979 1.91636 10.9099C3.84354 13.8006 7.6979 15.0854 10.9099 13.1582ZM4.16474 9.30388C3.20115 7.3767 3.84354 5.12833 5.44952 4.16474M11.8735 15.4066C12.5158 15.0854 13.1582 15.4066 13.4794 15.7278L15.4066 19.261C15.7278 19.9034 15.4066 20.5458 15.0854 20.867C14.443 21.1882 13.8006 20.867 13.4794 20.5458L11.5523 17.0126C11.2311 16.3702 11.5523 15.7278 11.8735 15.4066Z"
                    stroke="white"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
              <input
                value={filters.searchTerm}
                onChange={(event) => {
                  setFilters({ ...filters, searchTerm: event.target.value });
                }}
                className="w-[calc(100%-18px)] p-[5px] bg-transparent text-white"
              />
            </form>
            <div className="w-[80%] lg:w-[15%] flex items-center justify-end gap-[2.87rem] lg:gap-[1.31rem]">
              <span className="cursor-pointer w-[1.25rem] h-[1.25rem]">
                <span
                  onClick={() => {
                    setToggle(false);
                    if (filters.searchTerm) {
                      setFilters({ ...filters, searchTerm: "" });
                    }
                  }}
                >
                  <svg
                    width="25"
                    height="26"
                    viewBox="0 0 25 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.519531"
                      width="24"
                      height="24"
                      rx="12"
                      fill="black"
                      stroke="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.1875 12.6212L6.38867 17.42L7.80289 18.8342L12.6017 14.0354L17.4005 18.8342L18.8147 17.42L14.0159 12.6212L18.8147 7.82242L17.4005 6.4082L12.6017 11.207L7.80289 6.40821L6.38867 7.82242L11.1875 12.6212Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </span>
              <Link to="/wishlist" className="flex">
                <span className="inline-block lg:hidden w-[1.25rem] h-[1.25rem]">
                  <svg
                    width="23"
                    height="20"
                    viewBox="0 0 23 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.564 10.756L20.5639 10.7561C19.6547 11.6986 18.759 12.6497 17.8651 13.5989C16.0755 15.4993 14.2929 17.3922 12.4222 19.1938C11.9763 19.6157 11.2685 19.5996 10.8425 19.1581L2.7369 10.7561C0.473763 8.41012 0.473768 4.61703 2.73691 2.27112C5.01438 -0.089662 8.71847 -0.0896596 10.9959 2.27111L10.996 2.27115L11.2906 2.57654C11.3849 2.6742 11.5148 2.72936 11.6505 2.72936C11.7862 2.72935 11.9161 2.67418 12.0103 2.57651L12.3047 2.2713L12.3048 2.2712C13.3985 1.13695 14.8848 0.5 16.4343 0.5C17.9839 0.5 19.4701 1.13688 20.5639 2.27105L20.564 2.27118C21.6509 3.39737 22.2618 4.92261 22.2618 6.51358C22.2618 8.10457 21.6508 9.62991 20.564 10.756Z"
                      stroke="white"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <img
                  src={WishListIcon}
                  alt="wishlist"
                  className="hidden lg:inline-block w-[1.25rem] h-[1.25rem]"
                />
              </Link>

              <Link
                to={`${getToken() ? "/dashboard" : "/login"}`}
                className="flex"
              >
                <span className="w-[1.25rem] h-[1.25rem]">
                  <svg
                    width="19"
                    height="22"
                    viewBox="0 0 19 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 21V19.75C1 14.9175 4.91751 11 9.75 11C14.5825 11 18.5 14.9175 18.5 19.75V21"
                      stroke="white"
                      strokeLinecap="round"
                    />
                    <path
                      d="M9.75012 11C12.5115 11 14.7501 8.76138 14.7501 6C14.7501 3.23857 12.5115 1 9.75012 1C6.9887 1 4.75012 3.23857 4.75012 6C4.75012 8.76138 6.9887 11 9.75012 11Z"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </Link>

              {getToken() && (
                <span className="w-[1.25rem] h-[1.25rem] cursor-pointer">
                  <MenuCart />
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className="absolute left-0 flex items-center justify-center w-full h-[6vh] md:h-[10vh] bg-[#000e] p-[12px_15px]">
            <form className="w-full flex items-center border-[0.5px] border-solid border-[#fff] p-[0_10px]">
              <input
                value={filters.searchTerm}
                onChange={(event) => {
                  setFilters({ ...filters, searchTerm: event.target.value });
                }}
                className="w-[calc(100%-18px)] p-[5px] bg-transparent text-white"
              />
              <span
                onClick={() => {
                  navigate("/shop");
                  setToggle(false);
                }}
                className="w-[20px]"
              >
                <svg
                  width="17"
                  height="22"
                  viewBox="0 0 17 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9099 13.1582L11.8735 15.0854M10.9099 13.1582C13.8006 11.2311 15.0854 7.3767 13.1582 4.16474C11.2311 1.27397 7.3767 -0.0108178 4.16474 1.91636C1.27397 3.84354 -0.0108177 7.6979 1.91636 10.9099C3.84354 13.8006 7.6979 15.0854 10.9099 13.1582ZM4.16474 9.30388C3.20115 7.3767 3.84354 5.12833 5.44952 4.16474M11.8735 15.4066C12.5158 15.0854 13.1582 15.4066 13.4794 15.7278L15.4066 19.261C15.7278 19.9034 15.4066 20.5458 15.0854 20.867C14.443 21.1882 13.8006 20.867 13.4794 20.5458L11.5523 17.0126C11.2311 16.3702 11.5523 15.7278 11.8735 15.4066Z"
                    stroke="white"
                    stroke-miterlimit="22.9256"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </form>
          </div>
        )}
      </Transition>
    </>
  );
}
