window.addEventListener("scroll", function (ev) {
  var sticky_header = document.querySelector(".site-header");
  if (sticky_header) {
    if (window.scrollY >= 200) {
      sticky_header.classList.add("site-header-sticky");
    } else {
      sticky_header.classList.remove("site-header-sticky");
    }
  }
});
