import Header from "./header";
import { Helmet } from "react-helmet";
import Footer from "./footer";
import AboutUs1 from "../assets/img/about-us-1.jpg";
import AboutUs2 from "../assets/img/about-us-2.jpg";
import AboutUs3 from "../assets/img/about-us-3.jpg";
import AboutUs4 from "../assets/img/about-us-4.jpg";
import AboutUs5 from "../assets/img/about-us-5.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AppContext from "../context/context";
import { useContext } from "react";
import BackButton from "../components/back-button/back-button";

export default function AboutUs() {
  const { setPageID } = useContext(AppContext);
  setPageID(5);
  return (
    <>
      <Helmet>
        <title>About Shawn</title>
        <meta name="description" content="Pet supplies store" />
      </Helmet>
      <Header />
      <main className="bg-white md:bg-[#f2f2f4] pb-[3.5rem]">
        <BackButton />
        <div className="px-[1rem] md:px-[1.5rem] lg:px-[3.5rem]">
          <div className="px-0 md:px-[68px]">
            <div className="text-[2rem] md:text-[4.375rem] font-[900] leading-normal uppercase text-center mb-[0.31rem] md:mb-[1.88rem]">
              ABOUT COMPANY
            </div>
            <div className="text-[0.875rem] md:text-[1rem]">
              Welcome to Shawn Trading Co L.L.C, a leading trading company
              established in 2023 in Dubai, UAE. With a focus on retail and
              chain stores, as well as regional wholesale, we have chosen Dubai
              as our hub due to its unrivaled potential within the GCC region.
            </div>
            <div className="text-[0.875rem] md:text-[1rem]">
              As a holding company, we are actively involved in the production
              and distribution of pet supplies, catering to a large number of
              wholesalers, chain stores, and retailers, both online and offline.
              Our platforms seamlessly integrate technology and traditional
              brick-and-mortar establishments, ensuring convenience and
              accessibility for our valued customers.
            </div>
            <div className="text-[0.875rem] md:text-[1rem]">
              Driven by our commitment to growth and innovation, we have
              ambitious plans to expand our business across various aspects,
              including a diverse range of products, exploration of new
              countries and industries, and more. With our expansion strategy in
              place, we aim to continually enhance our offerings and provide
              comprehensive solutions to meet the evolving needs of our
              clientele.
            </div>
            <div className="text-[0.875rem] md:text-[1rem]">
              At Shawn Trading Co L.L.C, we prioritize delivering exceptional
              services and products that set us apart from the competition. Our
              team is dedicated to ensuring the utmost satisfaction of our
              customers, by constantly researching and sourcing cutting-edge
              products and developing strategic partnerships.
            </div>
            <div className="text-[0.875rem] md:text-[1rem]">
              We invite you to explore our website and discover the wide array
              of high-quality goods we offer. Whether you are a wholesaler
              seeking reliable supplies or a retailer looking to expand your
              product range, you can trust Shawn Trading Co L.L.C to deliver the
              best products, backed by outstanding customer support and
              competitive pricing.
            </div>
            <div className="text-[0.875rem] md:text-[1rem]">
              Thank you for choosing Shawn Trading Co L.L.C, your trusted
              partner for all your retail, wholesale, and distribution needs. We
              look forward to serving you and building long-lasting partnerships
              based on trust, efficiency, and mutual success.
            </div>
          </div>
          <div className="flex flex-wrap md:flex-nowrap items-center gap-[10px] mt-[1.88rem] md:mt-[3.5rem]">
            <div className="flex justify-center w-full order-3 md:order-1">
              <LazyLoadImage src={AboutUs1} alt="" className="rounded-[20px]" />
            </div>
            <div className="w-full order-1 md:order-2">
              <LazyLoadImage src={AboutUs2} alt="" className="rounded-[20px]" />
            </div>
            <div className="w-full order-2 md:order-3">
              <LazyLoadImage src={AboutUs3} alt="" className="rounded-[20px]" />
            </div>
            <div className="flex justify-center w-full order-4 md:order-4">
              <LazyLoadImage src={AboutUs4} alt="" className="rounded-[20px]" />
            </div>
          </div>

          <div className="mt-[1.88rem] md:mt-[3.5rem] px-0 md:px-[68px]">
            <div className="text-[1.5rem] md:text-[2.1875rem] leading-normal font-[900] uppercase text-center">
              OUR COMPETENCIES
            </div>
            <div className="mt-[0.31rem] md:mt-[1.88rem]">
              Our competencies are the foundation of our success in the pet
              industry. With over 12 years of international experience, we have
              established ourselves as a trusted and reliable partner in this
              market. Our extensive knowledge and expertise have allowed us to
              navigate the complexities of the industry, enabling us to provide
              exceptional services to our clients.
            </div>

            <div className="mt-[1rem]">
              One of our key strengths lies in our IT team, which comprises of
              over 90 skilled professionals. This dedicated team is well-versed
              in the latest technological advancements relevant to the pet
              industry. Through their expertise and continuous learning, we are
              able to offer cutting-edge solutions to our clients, ensuring
              their businesses stay competitive in the digital era.
            </div>

            <div className="mt-[1rem]">
              Moreover, our vast experience as a third-party service provider
              has allowed us to work with 60 renowned international brands. This
              exposure has provided us with valuable insights into various
              market strategies, trends, and consumer needs. Our ability to
              collaborate and adapt to different brand requirements has further
              strengthened our competencies and positioned us as a go-to partner
              for global players.
            </div>

            <div className="mt-[1rem]">
              Additionally, our well-established network of suppliers is a
              testament to our commitment to quality and reliability. Over the
              years, we have built strong relationships with suppliers across
              the globe, ensuring a steady supply of top-notch products for our
              clients. This network allows us to meet diverse demands while
              adhering to the highest standards of quality control.
            </div>

            <div className="mt-[1rem]">
              In conclusion, our competencies are the culmination of our rich
              experience, skilled IT team, strong partnerships with
              international brands, and extensive network of suppliers. These
              factors enable us to provide exceptional services to our clients,
              setting us apart in the pet industry. We are proud of our
              achievements and remain dedicated to continuously enhancing our
              competencies to meet the evolving needs of the market.
            </div>
          </div>
          <LazyLoadImage
            src={AboutUs5}
            alt=""
            className="rounded-[20px] my-[1.5rem] md:mt-[3.5rem]"
          />
        </div>
      </main>
      <Footer />
    </>
  );
}
