import { NumericFormat } from "react-number-format";

export default function Price(props) {
  return (
    props.price && (
      <>
        <NumericFormat
          value={props.price.toFixed(2)}
          displayType={"text"}
          thousandSeparator={true}
        />
      </>
    )
  );
}
