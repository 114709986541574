import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import MobileLogo from "../assets/img/logo.png";
import MobileMenuIcon from "../assets/img/mobile-menu-icon.png";
import MobileMenuClose from "../assets/img/mobile-close-icon.png";
import MobileMenuBack from "../assets/img/mobile-back-icon.png";
import WishListIcon from "../assets/img/header-wishlist-icon.svg";
import { useCallback, useContext, useEffect, useState } from "react";
import SearchBox from "../components/search-box/search-box";
import AppContext from "../context/context";
import { LazyLoadImage } from "react-lazy-load-image-component";
import MenuCart from "../components/menu-cart/menu_cart";

export default function Header() {
  const {
    PUBLIC_URL,
    pageID,
    setPageID,
    brands,
    setBrands,
    getToken,
    setCart,
  } = useContext(AppContext);
  const [toggle, setToggle] = useState(false);
  const [menus, setMenus] = useState([]);
  const [selectedMenu, setSelectMenu] = useState({
    menu: 0,
    sub_menu: 0,
  });

  useEffect(() => {
    setMenus([
      {
        id: 1,
        name: "Dog",
        sub_menu: [],
        link: "/shop/4",
      },
      {
        id: 2,
        name: "Cat",
        sub_menu: [],
        link: "/shop/5",
      },
      {
        id: 24,
        name: "new & featured products",
        sub_menu: [],
        link: "/new",
      },
      {
        id: 25,
        name: "our brands",
        link: "/brand",
        sub_menu: brands.map((brand) => {
          return {
            id: brand.id,
            name: brand.name,
            link: `/brand/${brand.id}`,
            image: brand.thumbnail,
          };
        }),
      },
      {
        id: 26,
        name: "about shawn",
        sub_menu: [],
        link: "/about-us",
      },
    ]);
  }, [brands]);

  const getBrands = useCallback(async () => {
    try {
      await fetch(`${PUBLIC_URL}/api/product/brands`)
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setBrands(data);
        });
    } catch (error) {}
  }, [PUBLIC_URL]);

  useEffect(() => {
    getBrands();
  }, [getBrands]);

  const getCartProducts = useCallback(async () => {
    if (getToken()) {
      try {
        await fetch(`${PUBLIC_URL}/api/cart/cartlist`, {
          headers: {
            Authorization: "Bearer " + getToken(),
          },
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json();
            }
            throw new Error("error");
          })
          .then((data) => {
            setCart(data);
          });
      } catch (error) {}
    } else {
      try {
        const localCart = localStorage.getItem("cart");
        if (localStorage) {
          await fetch(`${PUBLIC_URL}/api/cart/cartlist`, {
            method: "POST",
            body: JSON.stringify({
              localcart: localCart,
            }),
          })
            .then((respose) => {
              if (respose.ok) {
                return respose.json();
              }
              throw new Error("error");
            })
            .then((data) => {
              setCart(data);
            });
        }
      } catch (error) {}
    }
  }, [PUBLIC_URL, getToken, setCart]);

  useEffect(() => {
    getCartProducts();
  }, [setCart]);

  return (
    <header
      className={`site-header bg-[#000] lg:bg-black border-solid border-b-[1px] lg:border-none border-[#6E6E73] flex items-center justify-center p-[0.5625rem_1.02875rem_0.56013rem_1rem] md:p-[0.5rem_1.5rem] lg:p-[0.5rem_3.5rem] relative w-full top-0 left-0 z-10`}
    >
      <div className="w-[20%] lg:w-[12%]">
        <Link to="/" className="">
          {window.innerWidth > 991 ? (
            <img src={Logo} alt="Shawn" className="inline-block" />
          ) : (
            <img src={MobileLogo} alt="Shawn" className="inline-block" />
          )}
        </Link>
      </div>
      {window.innerWidth > 991 ? (
        <div className={`w-full md:w-[73%] lg:w-[63%] flex`}>
          <div className="w-full">
            <ul className="p-0 m-0 flex items-center gap-[2.125rem] w-full">
              <li className="w-full flex items-center justify-center">
                <Link
                  to="/shop/4"
                  className={`capitalize border-b-[1px] border-solid ${
                    pageID === 1 ? "border-[#fff]" : "border-transparent"
                  } text-white text-[0.875rem] font-[400] text-center py-[5px] h-full min-w-[30px]`}
                  onClick={() => {
                    setToggle(false);
                    setPageID(1);
                  }}
                >
                  Dog
                </Link>
              </li>
              <li className="w-full flex items-center justify-center">
                <Link
                  to="/shop/5"
                  className={`capitalize border-b-[1px] border-solid ${
                    pageID === 2 ? "border-[#fff]" : "border-transparent"
                  } text-white text-[0.875rem] font-[400] text-center py-[5px] h-full min-w-[30px]`}
                  onClick={() => {
                    setToggle(false);
                    setPageID(2);
                  }}
                >
                  Cat
                </Link>
              </li>
              <li className="w-full flex items-center justify-center">
                <Link
                  to="/new"
                  className={`capitalize border-b-[1px] border-solid ${
                    pageID === 3 ? "border-[#fff]" : "border-transparent"
                  } text-white text-[0.875rem] font-[400] text-center py-[5px] h-full min-w-[170px]`}
                  onClick={() => {
                    setToggle(false);
                    // setPageID(3)
                  }}
                >
                  new & featured products
                </Link>
              </li>
              <li className="w-full flex items-center justify-center">
                <Link
                  to="/brand"
                  className={`capitalize border-b-[1px] border-solid ${
                    pageID === 4 ? "border-[#fff]" : "border-transparent"
                  } text-white text-[0.875rem] font-[400] text-center py-[5px] h-full min-w-[80px]`}
                  onClick={() => {
                    setToggle(false);
                    // setPageID(4)
                  }}
                >
                  our brands
                </Link>
              </li>
              <li className="w-full flex items-center justify-center">
                <Link
                  to="/about-us"
                  onClick={() => {
                    // setPageID(5)
                  }}
                  className={`capitalize border-b-[1px] border-solid ${
                    pageID === 5 ? "border-[#fff]" : "border-transparent"
                  } text-white text-[0.875rem] font-[400] text-center py-[5px] h-full min-w-[90px]`}
                >
                  about shawn
                </Link>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <div className={`w-full ${toggle === true ? "show-menu" : "hidden"}`}>
          {selectedMenu.menu === 0 && selectedMenu.sub_menu === 0 && (
            <div className="absolute top-[60px] left-0 w-full z-10 bg-black p-[0_1rem_2.44rem_1rem]">
              {menus.map((menu, index) => {
                return (
                  <div
                    className={`flex items-center w-full flex-wrap mt-[2.44rem]`}
                  >
                    {menu.sub_menu.length === 0 ? (
                      <Link
                        to={menu.link}
                        key={index}
                        className="capitalize text-white text-[0.875rem] font-[400] block w-full"
                        onClick={() => {
                          setSelectMenu({ ...selectedMenu, menu: menu.id });
                          setToggle(false);
                        }}
                      >
                        {menu.name}
                      </Link>
                    ) : (
                      <div
                        key={index}
                        className="capitalize text-white text-[0.875rem] font-[400] block w-full"
                        onClick={() => {
                          setSelectMenu({ ...selectedMenu, menu: menu.id });
                        }}
                      >
                        {menu.name}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}

          {menus.map((menu) => {
            return (
              menu.sub_menu.length > 0 &&
              selectedMenu.menu > 0 &&
              selectedMenu.sub_menu === 0 && (
                <div
                  className={`h-transition fixed left-0 top-0 bg-black p-[0_1rem_2.44rem_1rem] max-h-full overflow-y-auto ${
                    selectedMenu.menu === menu.id
                      ? "visible opacity-100"
                      : "invisible opacity-0"
                  } w-full`}
                >
                  <div className="flex items-center pt-[1.1rem]">
                    <div className="w-[50%]">
                      <span
                        onClick={() => {
                          setSelectMenu({
                            menu: 0,
                            sub_menu: 0,
                          });
                        }}
                      >
                        <img src={MobileMenuBack} alt="back" />
                      </span>
                    </div>
                    <div className="w-[50%] flex items-center justify-end">
                      <span
                        onClick={() => {
                          setToggle(!toggle);
                          setSelectMenu({
                            menu: 0,
                            sub_menu: 0,
                          });
                        }}
                      >
                        <img src={MobileMenuClose} alt="close" />
                      </span>
                    </div>
                  </div>
                  {menu.sub_menu.map((sub_menu, index) => {
                    return (
                      <div className="w-full" key={index}>
                        {sub_menu.sub_menu && sub_menu.sub_menu.length > 0 ? (
                          <div
                            className={`capitalize text-white mt-[2.44rem] text-block text-[0.875rem] font-[400] ${
                              sub_menu.image ? "flex justify-center" : "block"
                            } w-full `}
                            onClick={() => {
                              setSelectMenu({
                                ...selectedMenu,
                                sub_menu: sub_menu.id,
                              });
                            }}
                          >
                            {sub_menu.image ? (
                              <LazyLoadImage
                                src={sub_menu.image}
                                alt={sub_menu.name}
                                className="w-[80.233px] h-[69px] rounded-[10px] overflow-hidden"
                              />
                            ) : (
                              <>{sub_menu.name}</>
                            )}
                          </div>
                        ) : (
                          <Link
                            to={sub_menu.link}
                            className={`capitalize text-white mt-[2.44rem] text-block text-[0.875rem] font-[400] ${
                              sub_menu.image ? "flex justify-center" : "block"
                            } w-full`}
                            onClick={() => {
                              setSelectMenu({
                                ...selectedMenu,
                                sub_menu: sub_menu.id,
                              });
                              setToggle(!toggle);
                            }}
                          >
                            {sub_menu.image ? (
                              <LazyLoadImage
                                src={sub_menu.image}
                                alt={sub_menu.name}
                                className="w-[80.233px] h-[69px] rounded-[10px] overflow-hidden"
                              />
                            ) : (
                              <>{sub_menu.name}</>
                            )}
                          </Link>
                        )}
                      </div>
                    );
                  })}
                </div>
              )
            );
          })}
          {menus.map((menu) => {
            if (menu.sub_menu.length > 0) {
              return menu.sub_menu.map((sub_menu) => {
                return (
                  sub_menu.sub_menu &&
                  sub_menu.sub_menu.length > 0 &&
                  selectedMenu.menu > 0 &&
                  selectedMenu.sub_menu > 0 && (
                    <div
                      className={`h-transition fixed left-0 top-0 bg-black p-[0_1rem_2.44rem_1rem] max-h-full overflow-y-auto ${
                        selectedMenu.sub_menu === sub_menu.id
                          ? "visible opacity-100"
                          : "invisible opacity-0"
                      } w-full`}
                    >
                      <div className="flex items-center pt-[1.1rem]">
                        <div className="w-[50%]">
                          <span
                            onClick={() => {
                              setSelectMenu({
                                ...selectedMenu,
                                sub_menu: 0,
                              });
                            }}
                          >
                            <img src={MobileMenuBack} alt="back" />
                          </span>
                        </div>
                        <div className="w-[50%] flex items-center justify-end">
                          <span
                            onClick={() => {
                              setToggle(!toggle);
                              setSelectMenu({
                                menu: 0,
                                sub_menu: 0,
                              });
                            }}
                          >
                            <img src={MobileMenuClose} alt="close" />
                          </span>
                        </div>
                      </div>
                      {sub_menu.sub_menu.map((_sub_menu, index) => {
                        return (
                          <div className="w-full" key={index}>
                            <div
                              className="capitalize text-white mt-[2.44rem] text-block text-[0.875rem] font-[400] block w-full"
                              onClick={() => {
                                setSelectMenu({
                                  ...selectedMenu,
                                  _sub_menu: _sub_menu.id,
                                });
                              }}
                            >
                              {_sub_menu.name}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )
                );
              });
            } else {
              return null;
            }
          })}
        </div>
      )}

      <div className="w-[80%] lg:w-[25%] flex items-center justify-end gap-[2.87rem] lg:gap-[1.31rem]">
        <span className="cursor-pointer w-[1.25rem] h-[1.25rem]">
          <SearchBox />
        </span>
        <Link to="/wishlist" className="flex">
          <span className="inline-block lg:hidden w-[1.25rem] h-[1.25rem]">
            <svg
              width="23"
              height="20"
              viewBox="0 0 23 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.564 10.756L20.5639 10.7561C19.6547 11.6986 18.759 12.6497 17.8651 13.5989C16.0755 15.4993 14.2929 17.3922 12.4222 19.1938C11.9763 19.6157 11.2685 19.5996 10.8425 19.1581L2.7369 10.7561C0.473763 8.41012 0.473768 4.61703 2.73691 2.27112C5.01438 -0.089662 8.71847 -0.0896596 10.9959 2.27111L10.996 2.27115L11.2906 2.57654C11.3849 2.6742 11.5148 2.72936 11.6505 2.72936C11.7862 2.72935 11.9161 2.67418 12.0103 2.57651L12.3047 2.2713L12.3048 2.2712C13.3985 1.13695 14.8848 0.5 16.4343 0.5C17.9839 0.5 19.4701 1.13688 20.5639 2.27105L20.564 2.27118C21.6509 3.39737 22.2618 4.92261 22.2618 6.51358C22.2618 8.10457 21.6508 9.62991 20.564 10.756Z"
                stroke="white"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <img
            src={WishListIcon}
            alt="wishlist"
            className="hidden lg:inline-block w-[1.25rem] h-[1.25rem]"
          />
        </Link>

        <Link to={`${getToken() ? "/dashboard" : "/login"}`} className="flex">
          <span className="w-[1.25rem] h-[1.25rem]">
            <svg
              width="19"
              height="22"
              viewBox="0 0 19 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 21V19.75C1 14.9175 4.91751 11 9.75 11C14.5825 11 18.5 14.9175 18.5 19.75V21"
                stroke="white"
                strokeLinecap="round"
              />
              <path
                d="M9.75012 11C12.5115 11 14.7501 8.76138 14.7501 6C14.7501 3.23857 12.5115 1 9.75012 1C6.9887 1 4.75012 3.23857 4.75012 6C4.75012 8.76138 6.9887 11 9.75012 11Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </Link>

        {getToken() && (
          <span className="w-[1.25rem] h-[1.25rem] cursor-pointer">
            <MenuCart />
          </span>
        )}

        {window.innerWidth < 992 && (
          <span
            className="flex w-[30px] h-[30px] items-center justify-end"
            onClick={() => {
              setToggle(!toggle);
              setSelectMenu({
                menu: 0,
                sub_menu: 0,
              });
            }}
          >
            {toggle === true ? (
              <img src={MobileMenuClose} alt="close" />
            ) : (
              <img src={MobileMenuIcon} alt="menu" />
            )}
          </span>
        )}
      </div>
    </header>
  );
}
