import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/pagination";
import "./home-footer-slider.css";
import { Autoplay, Pagination } from "swiper/modules";
import Slide1 from "../../assets/img/footer-slider-17-1.jpg";
import Slide2 from "../../assets/img/footer-slider-17-2.jpg";
import Slide3 from "../../assets/img/footer-slider-17-3.jpg";
import Slide4 from "../../assets/img/footer-slider-17-4.jpg";
import Slide5 from "../../assets/img/footer-slider-17-5.jpg";
import Slide6 from "../../assets/img/footer-slider-17-6.jpg";
import Slide7 from "../../assets/img/footer-slider-17-7.jpg";
import Slide8 from "../../assets/img/footer-slider-17-8.jpg";
import MobileSlide1 from "../../assets/img/footer-slider2-m-1.jpg";
import MobileSlide2 from "../../assets/img/footer-slider2-m-2.jpg";
import MobileSlide3 from "../../assets/img/footer-slider2-m-3.jpg";
import MobileSlide4 from "../../assets/img/footer-slider2-m-4.jpg";
import MobileSlide5 from "../../assets/img/footer-slider2-m-5.jpg";
import MobileSlide6 from "../../assets/img/footer-slider2-m-6.jpg";
import MobileSlide7 from "../../assets/img/footer-slider2-m-7.jpg";
import MobileSlide8 from "../../assets/img/footer-slider2-m-8.jpg";
import MobileSlide9 from "../../assets/img/footer-slider2-m-9.jpg";
import MobileSlide10 from "../../assets/img/footer-slider2-m-10.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function HomeFooterSlider() {
  return (
    <>
      {window.innerWidth > 767 ? (
        <Swiper
          loop={true}
          slidesPerView="auto"
          centeredSlides="true"
          spaceBetween="12"
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          speed={2000}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          className="footer-slider mt-[0.75rem]"
          style={{
            "--swiper-pagination-color": "rgba(0, 0, 0, 0.80)",
            "--swiper-pagination-bullet-inactive-color": "#6B6B6B",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "0.5rem",
            "--swiper-pagination-bullet-horizontal-gap": "0.69rem",
          }}
        >
          <SwiperSlide>
            <LazyLoadImage src={Slide1} alt="slider 1" />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage src={Slide2} alt="slider 2" />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage src={Slide3} alt="slider 3" />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage src={Slide4} alt="slider 4" />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage src={Slide5} alt="slider 4" />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage src={Slide6} alt="slider 4" />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage src={Slide7} alt="slider 4" />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage src={Slide8} alt="slider 4" />
          </SwiperSlide>
        </Swiper>
      ) : (
        <Swiper
          loop={true}
          slidesPerView="auto"
          centeredSlides="true"
          spaceBetween="12"
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          speed={2000}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          className="footer-slider mt-[0.75rem] "
          style={{
            "--swiper-pagination-color": "rgba(0, 0, 0, 0.80)",
            "--swiper-pagination-bullet-inactive-color": "#6B6B6B",
            "--swiper-pagination-bullet-inactive-opacity": "1",
            "--swiper-pagination-bullet-size": "0.5rem",
            "--swiper-pagination-bullet-horizontal-gap": "0.8rem",
          }}
        >
          <SwiperSlide>
            <LazyLoadImage src={MobileSlide1} alt="slider 1" />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage src={MobileSlide2} alt="slider 2" />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage src={MobileSlide3} alt="slider 3" />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage src={MobileSlide4} alt="slider 4" />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage src={MobileSlide5} alt="slider 4" />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage src={MobileSlide6} alt="slider 4" />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage src={MobileSlide7} alt="slider 4" />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage src={MobileSlide8} alt="slider 4" />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage src={MobileSlide9} alt="slider 4" />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage src={MobileSlide10} alt="slider 4" />
          </SwiperSlide>
        </Swiper>
      )}
    </>
  );
}
