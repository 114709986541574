import Price from "../price/price";

export default function PurchaseItemCard(props) {
  const item = props.item ? props.item : null;
  const details = item.details ? item.details : null;

  return (
    <div
      className={`flex flex-wrap md:flex-nowrap rounded-[15px] mt-[5px] overflow-hidden text-start border-solid border-[1px] border-black`}
    >
      <div className={`w-full md:w-[26%] bg-white text-black`}>
        <div className="bg-black text-white p-[8px] text-[12px] uppercase font-[700]">
          Order Number: {item.order_id}
        </div>
        <div className="p-[8px] text-[12px] font-[400]">
          <div>Name: {item.customer_name}</div>
          <div>
            Date:{" "}
            {new Date(item.date).toLocaleString("en-EN", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}
          </div>
          <div>
            Address: {item.address} {item.postal_code}
          </div>
          {item.mobile && <div>Contact Number: {item.mobile}</div>}
        </div>
      </div>
      <div className="w-full md:w-[74%] bg-white border-solid border-l-0 md:border-l-[1px] border-black">
        <table className="w-full">
          <thead>
            <tr className={`bg-black text-white`}>
              <th className={`w-[28.96%] p-[6.5px] text-[14px] font-[400]`}>
                Commodity
              </th>
              <th className={`w-[18.3%] p-[6.5px] text-[14px] font-[400]`}>
                Count
              </th>
              <th className={`w-[17.21%] p-[6.5px] text-[14px] font-[400]`}>
                Price
              </th>
              <th className={`w-[35.53%] p-[6.5px] text-[14px] font-[400]`}>
                Total Purchase
              </th>
            </tr>
          </thead>
          <tbody>
            {details &&
              details.map((detail, index) => {
                return (
                  <tr key={index} className="text-[#555] bg-white">
                    <td
                      className={`w-[28.96%] p-[2px_16px] border-solid ltr:border-r-[0.5px] rtl:border-l-[0.5px] border-b-[#808080] border-b-[1px] text-[14px] font-[400]`}
                    >
                      <div>{detail.product_name}</div>
                      {detail.attributes && (
                        <div className="flex gap-[5px] text-[11px]">
                          {detail.attributes.map((att, index) => {
                            return (
                              <span
                                className="ltr:mr-[10px] rtl:ml-[10px]"
                                key={index}
                              >
                                <span className="ltr:mr-[3px] rtl:ml-[3px]">
                                  {att.type_name}:
                                </span>
                                {att.type === 1 && (
                                  <span
                                    className={`w-[10px] h-[10px] ltr:mr-[5px] rtl:ml-[5px] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                    style={{
                                      background: att.value,
                                    }}
                                  ></span>
                                )}
                                {att.name}
                              </span>
                            );
                          })}
                        </div>
                      )}
                    </td>
                    <td
                      className={`w-[18.3%] p-[2px_16px] border-solid ltr:border-r-[0.5px] rtl:border-l-[0.5px] border-b-[#808080] border-b-[1px] text-[14px] font-[400] text-center`}
                    >
                      {detail.count}
                    </td>
                    <td
                      className={`w-[17.21%] p-[2px_16px] border-solid ltr:border-r-[0.5px] rtl:border-l-[0.5px] border-b-[#808080] border-b-[1px] text-[14px] font-[400] text-center`}
                    >
                      AED <Price price={detail.unit_price} />
                    </td>
                    <td
                      className={`w-[35.53%] p-[2px_16px] border-solid border-b-[#808080] border-b-[1px] text-[14px] font-[400] text-center`}
                    >
                      AED <Price price={detail.unit_price * detail.count} />
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td colSpan={2} className="w-[47.26%]"></td>
              <td className="w-[17.21%] p-[2px_16px] border-solid border-l-[1px] border-b-[#808080] border-l-[#808080] border-b-[1px] text-[14px] font-[700] text-center">
                Total
              </td>
              <td className="w-[35.53%] p-[2px_16px] border-solid border-b-[#808080] border-b-[1px] text-[14px] font-[700] text-center">
              AED {details
                  ? details?.reduce(
                      (total, detail) =>
                        (total = total + detail.count * detail.unit_price),
                      0
                    )
                  : 0}
              </td>
            </tr>
          </tbody>
        </table>
        {item.state === 1 ? (
          <div className="p-[2px_16px] my-[3px] mx-[16px] border-solid border-[1px] border-[#0BC154] rounded-[15px] text-[#0BC154] text-[14px]">
            This order has been approved.
          </div>
        ) : item.state === 2 ? (
          <div className="p-[2px_16px] my-[3px] mx-[16px] border-solid border-[1px] border-[#FF547D] rounded-[15px] text-[#FF547D] text-[14px]">
            This order waiting for approval.
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
