import Header from "./header";
import { Helmet } from "react-helmet";
import React, { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "../context/context";
import Footer from "./footer";
// import Banner from "./components/banner/banner";
import CartProductItemCard from "../components/cart-product-item-card/cart-product-item-card";
import Price from "../components/price/price";
import BackButton from "../components/back-button/back-button";
import Input from "../components/input/input";
import Textarea from "../components/textarea/textarea";
import FormButton from "../components/form-button/form-button";
import { ClipLoader } from "react-spinners";
import CartAddressItemCard from "../components/cart-address-item-card/cart-address-item-card";
// import Breadcrumb from "./components/breadcrumb/breadcrumb";
import ShopPagination from "../components/pagination/pagination";
import { Box, Modal } from "@mui/material";
import CloseIcon from "../assets/img/close_icon.svg";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

export default function Cart() {
  const { cart, PUBLIC_URL, getToken, getCustomer, COUNTRY_NAMES } =
    useContext(AppContext);
  const navigate = useNavigate();
  if (!getToken()) {
    navigate("/login");
  }
  const customer = getCustomer().customer ? getCustomer().customer : null;
  const [totalPrice, setTotalPrice] = useState(0);
  const [loader, setLoader] = useState(0);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState();
  const [addressName, setAddressName] = useState("");
  const [address, setAddress] = useState("");
  const [note, setNote] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [addressLoader, setAddressLoader] = useState(0);
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // const [loader, setLoader] = useState(0);

  const calcTotal = useCallback(() => {
    let _totalPrice = cart
      ? cart?.reduce(
          (total, product) =>
            (total =
              total +
              product.count *
                (product.sale_price ? product.sale_price : product.price)),
          0
        )
      : 0;
    setTotalPrice(_totalPrice);
  });

  useEffect(() => {
    calcTotal();
  }, [cart]);

  const [page, setPage] = useState(1);

  const getAddress = useCallback(async () => {
    try {
      await fetch(`${PUBLIC_URL}/api/customer/addresslist`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          if (data.addresses) {
            setAddresses(data.addresses);
            if (data.addresses.length > 0) {
              setSelectedAddress(
                data.addresses.find((item) => item.main_address === 1)
              );
            }
            if (data.addresses.length < 3) {
              setPage(1);
            }
          } else {
            // setError(t("amaliyat_namovafagh"));
          }
        });
    } catch (error) {
      // setError(t("khata_dar_amaliyat"));
    }
  }, [PUBLIC_URL, getToken]);

  useEffect(() => {
    getAddress();
  }, [getAddress]);

  async function addAddress() {
    try {
      setAddressLoader(1);
      if (!address || !addressName) {
        setAddressLoader(0);
        return;
      }
      await fetch(`${PUBLIC_URL}/api/customer/addaddress`, {
        method: "POST",
        body: JSON.stringify({
          addressName: addressName,
          address: address,
          postalCode: postalCode,
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setAddressLoader(0);
          if (data.addresses) {
            setAddressName("");
            setAddress("");
            setPostalCode("");

            setAddresses(data.addresses);
            // enqueueSnackbar(t("message_product_added"), {
            //   variant: "success",
            //   vertical: "bottom",
            //   horizontal: "left",
            // });
            if (data.addresses.length < 3) {
              setPage(1);
            }
          } else {
          }
        });
    } catch (error) {
      // setError(t("khata_dar_amaliyat"));
      setAddressLoader(0);
    }
  }

  const addOrder = async () => {
    try {
      setLoader(1);
      await fetch(`${PUBLIC_URL}/api/order/add`, {
        method: "POST",
        body: JSON.stringify({
          address: selectedAddress.address,
          postalCode: selectedAddress.postalCode
            ? selectedAddress.postalCode
            : "",
          note: note ? note : "",
        }),
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setLoader(0);
          if (data.order) {
            // successfull
            enqueueSnackbar("Invoce registered successfully.", {
              variant: "success",
              vertical: "bottom",
              horizontal: "left",
            });
            navigate("/dashboard/orders")
          } else {
            enqueueSnackbar("Error", {
              variant: "error",
              vertical: "bottom",
              horizontal: "left",
            });
          }
        });
    } catch (er) {
      // setLoader(0);
      // setError({
      //   ...error,
      //   default: "Error",
      // });
    }
  };

  return (
    <>
      <Helmet>
        <title>Shawn - Cart</title>
      </Helmet>
      <Header />
      <main className="bg-white md:bg-[#f2f2f4] pb-[2.51rem] md:pb-[6.62rem]">
        <BackButton />

        <div className="flex mx-auto">
          <div className="w-[100%] mx-[15px] md:mx-auto lg:w-[95%] 2xl:w-[76.0418%]">
            <div className="w-full bg-black text-white rounded-[20px_20px_0_0] text-center py-[10px] ">
              Your Cart
            </div>

            <div className="flex flex-wrap md:flex-nowrap gap-[10px] w-full py-[10px]">
              <div className="w-full md:w-[60%]">
                <div className="p-0 md:p-[17px] bg-white rounded-[15px]">
                  <div className="border-solid border-b-[1px] border-[rgba(30,30,30,0.30)] pb-[5px] mb-[5px]">
                    Name: <strong>{customer && customer.name}</strong>
                  </div>
                  <div className="border-solid border-b-[1px] border-[rgba(30,30,30,0.30)] pb-[5px] mb-[5px]">
                    Company Name:{" "}
                    <strong>{customer && customer.company_name}</strong>
                  </div>
                  <div className="border-solid border-b-[1px] border-[rgba(30,30,30,0.30)] pb-[5px] mb-[5px]">
                    Country:{" "}
                    {customer && (
                      <strong>
                        {
                          COUNTRY_NAMES.filter(
                            (item) => item.id === customer.country_id
                          )[0].name
                        }
                      </strong>
                    )}
                  </div>
                  {/* <div className="border-solid border-b-[1px] border-[rgba(30,30,30,0.30)] pb-[5px] mb-[5px]">
                    Lisence Number:{" "}
                    <strong>{customer && customer.lisence_number}</strong>
                  </div> */}
                  {/* <div className="border-solid border-b-[1px] border-[rgba(30,30,30,0.30)] pb-[5px] mb-[5px]">
                    OTRN Number:{" "}
                    <strong>{customer && customer.otrn_number}</strong>
                  </div> */}
                  <div className="border-solid border-b-[1px] border-[rgba(30,30,30,0.30)] pb-[5px] mb-[5px]">
                    Email: <strong>{customer && customer.email}</strong>
                  </div>
                  <div className="border-solid border-b-[1px] border-[rgba(30,30,30,0.30)] pb-[5px] mb-[5px]">
                    Contact Number:{" "}
                    <strong>{customer && customer.mobile}</strong>
                  </div>
                  <div className="flex items-center border-solid border-b-[1px] border-[rgba(30,30,30,0.30)] pb-[5px] mb-[5px]">
                    <div className="w-[90%]">
                      Address:{" "}
                      <strong>
                        {selectedAddress && selectedAddress.address}
                      </strong>
                    </div>
                    <div className="w-[10%]">
                      <FormButton
                        onclick={() => {
                          // addAddress();
                          setAddressModalOpen(true);
                        }}
                        classes="p-[2px_15px] text-center bg-black text-white rounded-[50px] text-[12px] capitalize"
                      >
                        {addresses && addresses.length > 0
                          ? window.innerWidth > 767
                            ? "Change"
                            : "+"
                          : window.innerWidth > 767
                          ? "Add"
                          : "+"}
                      </FormButton>
                    </div>
                  </div>
                  {selectedAddress && selectedAddress.postal_code && (
                    <div className="border-solid border-b-[1px] border-[rgba(30,30,30,0.30)] pb-[5px] mb-[5px]">
                      Postal Code:{" "}
                      <strong>{selectedAddress.postal_code}</strong>  
                    </div>
                  )}

                  <div>
                    <Textarea
                      value={note}
                      onchange={(value) => {
                        setNote(value);
                      }}
                      placeholder="Purchase Note"
                      classes="mt-[3px] w-full p-[3px_5px] bg-transparent border-solid border-[1px] border-[rgba(30,30,30,0.30)] rounded-[5px] min-h-[80px] !text-[12px]"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-full md:w-[40%] p-0 md:p-[5px] bg-white rounded-[20px]">
                {cart &&
                  cart.length > 0 &&
                  cart.map((item, index) => {
                    return <CartProductItemCard item={item} key={index} />;
                  })}

                <div className="mt-auto p-0 pt-[15px] md:p-[15px]">
                  <div className="flex items-center">
                    <div className="w-[80%]">Total</div>
                    <div className="w-[20%] flex justify-end text-[#0BC154] text-[22px]">
                      <Price price={totalPrice} />
                    </div>
                  </div>

                  <div
                    onClick={addOrder}
                    className="p-[5px] mt-[10px] cursor-pointer shadow text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[20px]"
                  >
                    {loader === 1 && (
                      <ClipLoader
                        color="#ffffff"
                        size="15px"
                        className="rtl:ml-[10px] ltr:mr-[10px]"
                      />
                    )}
                    Register Invoice
                  </div>
                  {/* <div className="text-[11px]">{t("order_description")}</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={addressModalOpen}
          onClose={() => {
            setAddressModalOpen(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "95%", md: "50%" },
              bgcolor: "background.paper",
              boxShadow: 24,
              borderRadius: "15px",
              p: { xs: 2, md: 4 },
            }}
          >
            <div className="w-full flex justify-end">
              <img
                src={CloseIcon}
                alt=""
                className="p-[5px] bg-[#fff] rounded-full cursor-pointer border-[1px] border-solid border-[rgba(30,30,30,0.30)]"
                onClick={() => {
                  setAddressModalOpen(false);
                }}
              />
            </div>
            <div>
              {addresses && (
                <div className="text-left">
                  {addresses.map((item, index) => {
                    if ((page - 1) * 2 <= index && index < page * 2) {
                      return (
                        <CartAddressItemCard
                          item={item}
                          key={index}
                          setAddresses={setAddresses}
                          setPage={setPage}
                          isSelected={
                            selectedAddress && selectedAddress.id === item.id
                              ? 1
                              : 0
                          }
                          onClick={() => {
                            setSelectedAddress(item);
                            setAddressModalOpen(false);
                          }}
                        />
                      );
                    } else {
                      return <React.Fragment key={index}></React.Fragment>;
                    }
                  })}
                  {addresses.length > 2 && (
                    <div className="w-full mt-[10px]">
                      <ShopPagination
                        pagesCount={
                          addresses.length % 2 === 0
                            ? addresses.length / 2
                            : Math.floor(addresses.length / 2) + 1
                        }
                        page={page}
                        handleChange={(event, value) => {
                          setPage(value);
                        }}
                        className="mx-0"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            <div>
              <div className="text-[18px] 2xl:text-[24px]">
                Register New Address
              </div>
              <div className="mt-[11px]">
                <Input
                  type="text"
                  value={addressName}
                  onchange={(value) => {
                    setAddressName(value);
                  }}
                  placeholder="Address Name"
                  classes="w-[50%] p-[3px_5px] bg-transparent border-solid border-[1px] border-[rgba(30,30,30,0.30)] rounded-[5px] !text-[12px]"
                />
                <Textarea
                  value={address}
                  onchange={(value) => {
                    setAddress(value);
                  }}
                  placeholder="Address"
                  classes="mt-[3px] w-full p-[3px_5px] bg-transparent border-solid border-[1px] border-[rgba(30,30,30,0.30)] rounded-[5px] min-h-[80px] !text-[12px]"
                />

                <Input
                  type="text"
                  value={postalCode}
                  onchange={(value) => {
                    setPostalCode(value);
                  }}
                  placeholder="Postal Code"
                  classes="w-[50%] p-[3px_5px] bg-transparent border-solid border-[1px] border-[rgba(30,30,30,0.30)] rounded-[5px] !text-[12px]"
                />

                <div className="mt-[3px] flex items-center justify-end">
                  <FormButton
                    onclick={() => {
                      addAddress();
                    }}
                    classes="p-[2px_15px] text-center bg-black text-white rounded-[50px] text-[12px] capitalize"
                  >
                    {addressLoader === 1 && (
                      <ClipLoader
                        color="#ffffff"
                        size="15px"
                        className="rtl:ml-[10px] ltr:mr-[10px]"
                      />
                    )}
                    Add Address
                  </FormButton>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </main>
      <Footer />
    </>
  );
}
