import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import Price from "../price/price";
import ProductDefaultImage from "../../assets/img/produc-placeholder.png";
import CloseIcon from "../../assets/img/close_icon.svg";
import WishlistIcon from "../wishlist/wishlisticon";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AppContext from "../../context/context";
import AddToCartButton from "../add-to-cart-button/add-to-cart-button";

// function getText(html, len = 15) {
//   var divContainer = document.createElement("div");
//   divContainer.innerHTML = html;
//   return (
//     divContainer.textContent.substring(
//       0,
//       nthIndex(divContainer.textContent, " ", len)
//     ) ||
//     divContainer.innerText.substring(
//       0,
//       nthIndex(divContainer.textContent, " ", len)
//     ) ||
//     ""
//   );
// }

// function nthIndex(str, pat, n) {
//   var L = str.length,
//     i = -1;
//   while (n-- && i++ < L) {
//     i = str.indexOf(pat, i);
//     if (i < 0) break;
//   }
//   return i;
// }

function getText(html) {
  var divContainer = document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
}

export default function ProductCard(props) {
  const { getToken } = useContext(AppContext);
  const product = props.product;
  const [toggle, setToggle] = useState(false);
  const attributes = product.attributes ? JSON.parse(product.attributes) : [];
  const packages = JSON.parse(product.attributes).filter(
    (item) => item.attribute_type === 3
  );
  const metas = product.metas ? JSON.parse(product.metas) : [];
  const [selectedAttribute, setSelectedAttribute] = useState(
    attributes.length > 0 ? attributes[0] : packages[0]
  );
  let images = product.images ? product.images.split(",") : [];
  if (product.thumbnail) {
    images = [product.thumbnail, ...images];
  }
  if (product.thumbnail2) {
    images = [product.thumbnail2, ...images];
  }
  // let images = product.thumbnail ? [product.thumbnail] : [];
  // if (product.thumbnail2) {
  //   images = [...images, product.thumbnail2];
  // }
  // if (product.images) {
  //   images = [...images, product.images.split(",")];
  // }
  // if(images.length === 0){
  //   images=[ProductDefaultImage]
  // }

  let att_images = attributes.filter((attribute) => {
    return attribute.thumbnail !== null;
  });
  if (att_images.length > 0) {
    images = [
      ...images,
      att_images.map((item) => {
        return item.thumbnail;
      }),
    ];
  }
  const [activeImage, setActiveImage] = useState(
    images.length > 0 ? images[0] : null
  );
  const firstVariant = useRef({
    type: 0,
    type_name: "",
    items: [],
  });
  const secondVariant = useRef({
    type: 0,
    type_name: "",
    items: [],
  });

  const [selectedVariant, setSelectedVariant] = useState({
    first: null,
    second: null,
  });

  if (product.product_attributes && product.product_attributes.length > 0) {
    firstVariant.current.items = [];
    secondVariant.current.items = [];
    product.product_attributes.forEach((element) => {
      if (firstVariant.current.type === 0) {
        firstVariant.current = {
          type: element.attribute_type,
          type_name: element.attribute_type_name,
          items: [element],
        };
      } else if (firstVariant.current.type === element.attribute_type) {
        firstVariant.current = {
          ...firstVariant.current,
          items: [...firstVariant.current.items, element],
        };
      } else {
        secondVariant.current = {
          type: element.attribute_type,
          type_name: element.attribute_type_name,
          items: [...secondVariant.current.items, element],
        };
      }
    });
  }

  useEffect(() => {
    if (attributes) {
      const tattributes = attributes.filter(
        (item) => item.attribute_type !== 3
      );

      if (tattributes.length > 0) {
        setSelectedAttribute(tattributes[0]);

        const ids = tattributes[0].attribute_id.split(",");

        if (ids[0] && ids[1]) {
          setSelectedVariant({ first: +ids[0], second: +ids[1] });
        } else if (ids[0] && !ids[1]) {
          setSelectedVariant({ first: +ids[0], second: null });
        }
      }
    }
  }, []);

  return (
    <>
      {window.innerWidth > 767 ? (
        <div
          className={`h-product h-transition max-w-[100%] min-h-[100%] xl:max-w-[20rem] xl:min-h-[25rem] 2xl:max-w-[100%] 2xl:max-h-[100%] w-full flex flex-col relative flex-wrap rounded-[20px] overflow-hidden bg-white h-product-shadow`}
          onMouseEnter={() => {
            if (images.length > 1) {
              setActiveImage(images[1]);
            }
          }}
          onMouseLeave={() => {
            if (images.length > 0) {
              setActiveImage(images[0]);
            }
          }}
        >
          <div className="relative w-full">
            <div className="flex min-h-[2.81rem]">
              <div className="flex items-center p-[10px_15px]">
                <WishlistIcon productId={product.id} />
              </div>
              <div className="w-[calc(100%-50px)] flex justify-end">
                {metas &&
                  metas.map((meta, index) => {
                    if (meta.display !== 0) {
                      return (
                        <span
                          key={index}
                          className={`bg-[#FFD954] text-[#1E1E1EB2] rounded-[0_0_0_1.25rem] text-[15px] w-[60px] h-[45px] pl-[15px] pr-[30px] mr-[-15px] flex items-center justify-center shadow`}
                        >
                          {meta.name}
                        </span>
                      );
                    } else {
                      return <React.Fragment key={index}></React.Fragment>;
                    }
                  })}
                {!props.hideDiscount &&
                selectedAttribute &&
                selectedAttribute.sale_price ? (
                  <span className="bg-[#FF547D] text-[#F9F9F9] rounded-[0_1.25rem] text-[15px] w-[45px] h-[45px] flex items-center justify-center shadow">
                    {(
                      100 -
                      (+selectedAttribute.sale_price * 100) /
                        +selectedAttribute.price
                    ).toFixed(0)}
                    %
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <Link
              to={`/product/${product.slug}`}
              className="h-transition block"
            >
              <div className="block mt-[12px] px-[0.94rem] cursor-pointer w-full min-h-[14.4375rem]">
                <LazyLoadImage
                  src={activeImage ? activeImage : ProductDefaultImage}
                  alt={product.name}
                  className={`h-transition h-auto w-auto max-h-[14.4375rem] max-w-full m-auto`}
                />
              </div>
              <div className="p-[0_0.94rem] mt-[18px] font-[500] text-[16px] text-[#1E1E1EB2] leading-normal">
                {product.name}
              </div>
              {product.excerpt && (
                <div className="text-[12px] p-[0_15px] mt-[0.06rem] leading-normal">
                  {getText(product.excerpt)}
                </div>
              )}
            </Link>
          </div>
          <div className="mt-auto w-full">
            <div className="mt-[5px] p-[0_0.94rem_0.16rem_0.94rem] flex items-center">
              {selectedAttribute && (
                <div className="w-[50%] mt-[0.22rem]">
                  {getToken() ? (
                    <span className="flex flex-wrap items-center">
                      <span className="text-[#0BC154] text-[1rem] leading-normal">
                        AED{" "}
                        <Price
                          price={
                            !selectedAttribute.b2b_sale_price
                              ? selectedAttribute.b2b_price
                              : selectedAttribute.b2b_sale_price
                          }
                        />
                      </span>
                      <span className="text-[0.75rem] text-[#0004] ml-[0.25rem] leading-normal">
                        <del>
                          AED{" "}
                          <Price
                            price={
                              !selectedAttribute.sale_price
                                ? selectedAttribute.price
                                : selectedAttribute.sale_price
                            }
                          />
                        </del>
                      </span>
                    </span>
                  ) : !selectedAttribute.sale_price ? (
                    <span className="text-[#0BC154] text-[1rem] leading-normal">
                      AED <Price price={selectedAttribute.price} />
                    </span>
                  ) : (
                    <span className="flex flex-wrap items-center">
                      <span className="text-[#0BC154] text-[1rem] leading-normal">
                        AED <Price price={selectedAttribute.sale_price} />
                      </span>
                      <span className="text-[0.75rem] text-[#0004] ml-[0.25rem] leading-normal">
                        <del>
                          AED <Price price={selectedAttribute.price} />
                        </del>
                      </span>
                    </span>
                  )}
                </div>
              )}
              {getToken() && (
                <div className="w-[50%] flex items-center justify-end">
                  {attributes.length > 1 ? (
                    <span
                      onClick={() => {
                        setToggle(!toggle);
                      }}
                      className="inline-block cursor-pointer bg-black text-white rounded-[15px] p-[5px_15px] text-[12px] leading-normal"
                    >
                      Add To Cart
                    </span>
                  ) : (
                    <AddToCartButton
                      attribute={selectedAttribute}
                      text="Add To Cart"
                      buttonText=" "
                    />
                  )}
                </div>
              )}
            </div>
          </div>
          {attributes.length > 1 && (
            <div
              className={`${
                toggle ? "flex" : "hidden"
              } flex-col absolute top-0 left-0 w-full h-full bg-white px-[10px] pb-[10px]`}
            >
              <span className="flex items-center justify-center w-[25px] h-[25px] rounded-full bg-[#00000033] absolute top-[10px] right-[10px]">
                <img
                  src={CloseIcon}
                  alt=""
                  className="p-[5px] rounded-full cursor-pointer"
                  onClick={() => {
                    setToggle(false);
                  }}
                />
              </span>
              <div className="p-[10px_0]">
                <div className="text-[18px] md:text-[22px] text-[#000]">
                  {product.name}
                </div>
              </div>
              <div className="h-[1px] w-full bg-[#00000033]"></div>

              {product.product_attributes ? (
                <>
                  {firstVariant.current.items.length > 0 && (
                    <>
                      <div className="text-[0.875rem] font-[700] text-[#000] mb-[0.25rem]">
                        {firstVariant.current.type_name}:
                        {selectedVariant.first &&
                          firstVariant.current.items &&
                          firstVariant.current.items.length > 0 &&
                          firstVariant.current.items.filter((item) => {
                            return item.attribute_id === selectedVariant.first;
                          })[0] &&
                          firstVariant.current.items.filter((item) => {
                            return item.attribute_id === selectedVariant.first;
                          })[0].attribute_description && (
                            <span className="text-[12px]">
                              {
                                firstVariant.current.items.filter((item) => {
                                  return (
                                    item.attribute_id === selectedVariant.first
                                  );
                                })[0].attribute_description
                              }
                            </span>
                          )}
                      </div>
                      <div className="flex flex-wrap gap-[0.5rem]">
                        {firstVariant.current.items.map(
                          (product_attribute, index) => {
                            return (
                              <div
                                className={`w-auto ${
                                  selectedVariant.first ===
                                  product_attribute.attribute_id
                                    ? "bg-[#603085] text-white border-[#603085]"
                                    : "bg-[#fff] text-[#626262] border-[#7F7F7F]"
                                }  hover:bg-[#603085] hover:border-[#603085] hover:text-white text-[0.875rem] rounded-[1.25rem] min-w-[5.25rem] flex items-center cursor-pointer border-solid border-[0.5px]`}
                                key={index}
                                onClick={() => {
                                  setSelectedVariant({
                                    ...selectedVariant,
                                    first: product_attribute.attribute_id,
                                  });

                                  if (
                                    secondVariant.current.items.length === 0
                                  ) {
                                    const _selectedAttribute =
                                      attributes.filter((item) => {
                                        return (
                                          +item.attribute_id ===
                                          +product_attribute.attribute_id
                                        );
                                      })[0];
                                    setSelectedAttribute(_selectedAttribute);
                                    if (_selectedAttribute.thumbnail) {
                                      setActiveImage(
                                        _selectedAttribute.thumbnail
                                      );
                                    }
                                  } else if (selectedVariant.second) {
                                    const _selectedAttribute =
                                      attributes.filter((item) => {
                                        return (
                                          item.attribute_id ===
                                            (product_attribute.attribute_id +
                                              "," +
                                              selectedVariant.second ||
                                              selectedVariant.second +
                                                "," +
                                                product_attribute.attribute_id) ||
                                          item.attribute_id ===
                                            (selectedVariant.second +
                                              "," +
                                              product_attribute.attribute_id ||
                                              product_attribute.attribute_id +
                                                "," +
                                                selectedVariant.second)
                                        );
                                      })[0];
                                    if (_selectedAttribute) {
                                      setSelectedAttribute(_selectedAttribute);
                                      if (_selectedAttribute.thumbnail) {
                                        setActiveImage(
                                          _selectedAttribute.thumbnail
                                        );
                                      }
                                    } else {
                                      setSelectedAttribute(null);
                                    }
                                  }
                                }}
                              >
                                {product_attribute.attribute_type === 1 ? (
                                  <>
                                    <span
                                      className={`w-[1.625rem] h-[1.625rem] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                      style={{
                                        background:
                                          product_attribute.attribute_value,
                                      }}
                                    ></span>
                                    <div className="flex items-center justify-center w-[calc(100%-1.625rem)]">
                                      {product_attribute.attribute_name}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="flex items-center justify-center w-full py-[0.25rem]">
                                      {product_attribute.attribute_name}
                                    </div>
                                  </>
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </>
                  )}

                  {secondVariant.current.items &&
                    secondVariant.current.items.length > 0 && (
                      <>
                        <div className="text-[0.875rem] font-[700] text-[#000] mb-[0.25rem] mt-[1rem]">
                          {secondVariant.current.type_name}:
                          {selectedVariant.second &&
                            secondVariant.current.items &&
                            secondVariant.current.items.length > 0 &&
                            secondVariant.current.items.filter((item) => {
                              return (
                                item.attribute_id === selectedVariant.second
                              );
                            })[0] &&
                            secondVariant.current.items.filter((item) => {
                              return (
                                item.attribute_id === selectedVariant.second
                              );
                            })[0].attribute_description && (
                              <span className="text-[12px]">
                                {
                                  secondVariant.current.items.filter((item) => {
                                    return (
                                      item.attribute_id ===
                                      selectedVariant.second
                                    );
                                  })[0].attribute_description
                                }
                              </span>
                            )}
                        </div>
                        <div className="flex flex-wrap gap-[0.5rem]">
                          {secondVariant.current.items.map(
                            (product_attribute, index) => {
                              return (
                                <div
                                  className={`w-[4.06rem] p-[0.12rem] min-w-[5.25rem] ${
                                    selectedVariant.second ===
                                    product_attribute.attribute_id
                                      ? product_attribute.attribute_type === 1
                                        ? "bg-[#000] hover:bg-[#000] text-white  border-[#000]  hover:border-[#000]"
                                        : "bg-[#603085] text-white hover:text-white border-[#603085] hover:bg-[#603085] hover:border-[#603085]"
                                      : product_attribute.attribute_type === 1
                                      ? "bg-[#fff] hover:bg-[#000] text-[#000] hover:text-white border-[#7F7F7F] hover:border-[#000]"
                                      : "bg-[#fff] hover:bg-[#603085] text-[#000] hover:text-white border-[#7F7F7F] hover:border-[#603085]"
                                  }    text-[0.75rem] rounded-[1.25rem] flex items-center cursor-pointer border-solid border-[0.5px]`}
                                  key={index}
                                  onClick={() => {
                                    setSelectedVariant({
                                      ...selectedVariant,
                                      second: product_attribute.attribute_id,
                                    });
                                    const _selectedAttribute =
                                      attributes.filter((item) => {
                                        return (
                                          item.attribute_id ===
                                            selectedVariant.first +
                                              "," +
                                              product_attribute.attribute_id ||
                                          item.attribute_id ===
                                            product_attribute.attribute_id +
                                              "," +
                                              selectedVariant.first
                                        );
                                      })[0];
                                    if (_selectedAttribute) {
                                      setSelectedAttribute(_selectedAttribute);
                                      if (_selectedAttribute.thumbnail) {
                                        setActiveImage(
                                          _selectedAttribute.thumbnail
                                        );
                                      }
                                    } else {
                                      setSelectedAttribute(null);
                                    }
                                  }}
                                >
                                  {product_attribute.attribute_type === 1 ? (
                                    <>
                                      <span
                                        className={`w-[1.625rem] h-[1.625rem] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                        style={{
                                          background:
                                            product_attribute.attribute_value,
                                        }}
                                      ></span>
                                      <div className="flex items-center justify-center w-[calc(100%-1.625rem)]">
                                        {product_attribute.attribute_name}
                                      </div>
                                    </>
                                  ) : (
                                    <div className="flex items-center justify-center w-full py-[0.25rem]">
                                      {product_attribute.attribute_name}
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                        {selectedVariant.second &&
                          secondVariant.current.items &&
                          secondVariant.current.items.length > 0 &&
                          secondVariant.current.items.filter((item) => {
                            return item.attribute_id === selectedVariant.second;
                          })[0] &&
                          secondVariant.current.items.filter((item) => {
                            return item.attribute_id === selectedVariant.second;
                          })[0].attribute_description && (
                            <>
                              <div className="text-[14px]">
                                {secondVariant.current.type_name +
                                  " Description"}
                              </div>
                              <div className="text-[12px]">
                                {
                                  secondVariant.current.items.filter((item) => {
                                    return (
                                      item.attribute_id ===
                                      selectedVariant.second
                                    );
                                  })[0].attribute_description
                                }
                              </div>
                            </>
                          )}
                      </>
                    )}
                </>
              ) : (
                <div className=" flex flex-wrap gap-[5px] mt-[15px]">
                  {attributes.map((attribute, index) => {
                    return (
                      <div
                        className={`w-[calc(50%-4px)] p-[3px] ${
                          attribute.id === selectedAttribute.id
                            ? "bg-[#603085] text-white"
                            : "bg-[#D9D9D9] text-[#626262]"
                        }  hover:bg-[#603085]  hover:text-white rounded-[20px] flex items-center justify-center cursor-pointer`}
                        key={index}
                        onClick={() => {
                          setSelectedAttribute(attribute);
                        }}
                      >
                        {attribute.attribute_type === 1 && (
                          <span
                            className={`w-[20px] h-[20px] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                            style={{
                              background: attribute.attribute_value,
                            }}
                          ></span>
                        )}
                        {attribute.attribute_name}
                      </div>
                    );
                  })}
                </div>
              )}

              <div className="flex items-end flex-grow-[1] justify-end">
                {selectedAttribute && selectedAttribute.stock > 0 ? (
                  <div>
                    {getToken() && (
                      <span className="flex flex-wrap items-center">
                        <span className="text-[#0BC154] text-[1rem] leading-normal">
                          AED{" "}
                          <Price
                            price={
                              !selectedAttribute.b2b_sale_price
                                ? selectedAttribute.b2b_price
                                : selectedAttribute.b2b_sale_price
                            }
                          />
                        </span>
                        <span className="text-[0.75rem] text-[#0004] ml-[0.25rem] leading-normal">
                          <del>
                            AED{" "}
                            <Price
                              price={
                                !selectedAttribute.sale_price
                                  ? selectedAttribute.price
                                  : selectedAttribute.sale_price
                              }
                            />
                          </del>
                        </span>
                      </span>
                    )}
                  </div>
                ) : (
                  <span className="text-[#7F7F7F]">Unavailable</span>
                )}
                <div className="flex items-end flex-grow-[1] justify-end">
                  {selectedAttribute && (
                    <AddToCartButton
                      attribute={selectedAttribute}
                      text="Add"
                      className="bg-[#000] hover:bg-[#603085] w-full text-center"
                      clickHandler={() => {
                        // setToggle(false);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className={`w-full flex gap-[0.9375rem] overflow-hidden rounded-[1.25rem] bg-[#F2F2F4] shadow p-[1.6875rem_0.9375rem] mb-[0.94rem] relative`}
        >
          <Link
            to={`/product/${product.slug}`}
            className="w-[8.5rem] h-[8.5rem] flex items-center justify-center p-[1px] rounded-[1.25rem] bg-white overflow-hidden border-solid border-[1px] border-[#6E6E73]"
          >
            {images.length > 0 ? (
              <LazyLoadImage
                src={product.images.split(",")[0]}
                alt={product.name}
                className="h-full m-auto "
              />
            ) : (
              <LazyLoadImage
                src={ProductDefaultImage}
                alt={product.name}
                className="h-full m-auto "
              />
            )}
          </Link>
          <div className="w-[calc(100%-8.5rem)] flex flex-col">
            <Link to={`/product/${product.slug}`}>
              <h3 className="font-[600] text-[1rem] text-[#000] mb-[0.94rem]">
                {product.name}
              </h3>
              {product.excerpt && product.name.length < 24 && (
                <div className="text-[0.875rem] text-[#000b] mb-[0.94rem]">
                  {getText(product.excerpt)}...
                </div>
              )}
              <div className="flex items-center">
                {selectedAttribute && (
                  <div className="w-full">
                    {getToken() ? (
                      <span className="flex items-center">
                        <span className="text-[#0BC154] text-[1rem] font-[500]">
                          AED{" "}
                          <Price
                            price={
                              !selectedAttribute.b2b_sale_price
                                ? selectedAttribute.b2b_price
                                : selectedAttribute.b2b_sale_price
                            }
                          />
                        </span>
                        <span className="text-[0.875rem] text-[#0004] ml-[0.25rem]">
                          <del>
                            AED{" "}
                            <Price
                              price={
                                !selectedAttribute.sale_price
                                  ? selectedAttribute.price
                                  : selectedAttribute.sale_price
                              }
                            />
                          </del>
                        </span>
                      </span>
                    ) : !selectedAttribute.sale_price ? (
                      <span className="text-[#0BC154] text-[1rem] font-[500]">
                        AED <Price price={selectedAttribute.price} />
                      </span>
                    ) : (
                      <span className="flex items-center">
                        <span className="text-[#0BC154] text-[1rem] font-[500]">
                          AED <Price price={selectedAttribute.sale_price} />
                        </span>
                        <span className="text-[0.875rem] text-[#0004] ml-[0.25rem]">
                          <del>
                            AED <Price price={selectedAttribute.price} />
                          </del>
                        </span>
                      </span>
                    )}
                  </div>
                )}
              </div>
            </Link>

            {getToken() && (
              <>
                {selectedAttribute && attributes.length > 1 ? (
                  <div className="mt-auto flex items-center justify-end">
                    <span
                      className="mt-[0.94rem] cursor-pointer inline-block bg-black text-white rounded-[15px] p-[3px_10px] text-[12px] leading-normal"
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setToggle(true);
                      }}
                    >
                      Add To Cart
                    </span>
                  </div>
                ) : (
                  <div className="mt-auto flex items-center justify-end">
                    <AddToCartButton
                      attribute={selectedAttribute}
                      text="Add To Cart"
                      buttonText=" "
                      className="mt-[0.94rem] cursor-pointer inline-block bg-black text-white !rounded-[15px] !p-[3px_10px] !text-[12px] !leading-normal"
                    />
                  </div>
                )}
              </>
            )}
          </div>
          {attributes.length > 1 && (
            <div
              className={`${
                toggle ? "flex" : "hidden"
              } flex-col absolute top-0 left-0 w-full h-full bg-white px-[10px] pb-[10px]`}
            >
              <span className="flex items-center justify-center w-[25px] h-[25px] rounded-full bg-[#00000033] absolute top-[10px] right-[10px]">
                <img
                  src={CloseIcon}
                  alt=""
                  className="p-[5px] rounded-full cursor-pointer"
                  onClick={() => {
                    setToggle(false);
                  }}
                />
              </span>
              <div className="p-[5px_0]">
                <div className="text-[18px] text-[#000]">{product.name}</div>
              </div>
              <div className="h-[1px] w-full bg-[#00000033]"></div>

              {product.product_attributes ? (
                <>
                  {firstVariant.current.items.length > 0 && (
                    <>
                      <div className="text-[12px] font-[700] text-[#000]">
                        {firstVariant.current.type_name}:
                        {selectedVariant.first &&
                          firstVariant.current.items &&
                          firstVariant.current.items.length > 0 &&
                          firstVariant.current.items.filter((item) => {
                            return item.attribute_id === selectedVariant.first;
                          })[0] &&
                          firstVariant.current.items.filter((item) => {
                            return item.attribute_id === selectedVariant.first;
                          })[0].attribute_description && (
                            <span className="text-[10px]">
                              {
                                firstVariant.current.items.filter((item) => {
                                  return (
                                    item.attribute_id === selectedVariant.first
                                  );
                                })[0].attribute_description
                              }
                            </span>
                          )}
                      </div>
                      <div className="flex flex-wrap gap-[0.25rem]">
                        {firstVariant.current.items.map(
                          (product_attribute, index) => {
                            return (
                              <div
                                className={`w-auto ${
                                  selectedVariant.first ===
                                  product_attribute.attribute_id
                                    ? "bg-[#603085] text-white border-[#603085]"
                                    : "bg-[#fff] text-[#626262] border-[#7F7F7F]"
                                }  hover:bg-[#603085] hover:border-[#603085] hover:text-white text-[12px] rounded-[1.25rem] min-w-[4.25rem] flex items-center cursor-pointer border-solid border-[0.5px]`}
                                key={index}
                                onClick={() => {
                                  setSelectedVariant({
                                    ...selectedVariant,
                                    first: product_attribute.attribute_id,
                                  });

                                  if (
                                    secondVariant.current.items.length === 0
                                  ) {
                                    const _selectedAttribute =
                                      attributes.filter((item) => {
                                        return (
                                          +item.attribute_id ===
                                          +product_attribute.attribute_id
                                        );
                                      })[0];
                                    setSelectedAttribute(_selectedAttribute);
                                    if (_selectedAttribute.thumbnail) {
                                      setActiveImage(
                                        _selectedAttribute.thumbnail
                                      );
                                    }
                                  } else if (selectedVariant.second) {
                                    const _selectedAttribute =
                                      attributes.filter((item) => {
                                        return (
                                          item.attribute_id ===
                                            (product_attribute.attribute_id +
                                              "," +
                                              selectedVariant.second ||
                                              selectedVariant.second +
                                                "," +
                                                product_attribute.attribute_id) ||
                                          item.attribute_id ===
                                            (selectedVariant.second +
                                              "," +
                                              product_attribute.attribute_id ||
                                              product_attribute.attribute_id +
                                                "," +
                                                selectedVariant.second)
                                        );
                                      })[0];
                                    if (_selectedAttribute) {
                                      setSelectedAttribute(_selectedAttribute);
                                      if (_selectedAttribute.thumbnail) {
                                        setActiveImage(
                                          _selectedAttribute.thumbnail
                                        );
                                      }
                                    } else {
                                      setSelectedAttribute(null);
                                    }
                                  }
                                }}
                              >
                                {product_attribute.attribute_type === 1 ? (
                                  <>
                                    <span
                                      className={`w-[1rem] h-[1rem] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                      style={{
                                        background:
                                          product_attribute.attribute_value,
                                      }}
                                    ></span>
                                    <div className="flex items-center justify-center w-[calc(100%-1.625rem)]">
                                      {product_attribute.attribute_name}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="flex items-center justify-center w-full py-[0.1rem]">
                                      {product_attribute.attribute_name}
                                    </div>
                                  </>
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </>
                  )}

                  {secondVariant.current.items &&
                    secondVariant.current.items.length > 0 && (
                      <>
                        <div className="text-[12px] font-[700] text-[#000] mt-[0.25rem]">
                          {secondVariant.current.type_name}:
                          {selectedVariant.second &&
                            secondVariant.current.items &&
                            secondVariant.current.items.length > 0 &&
                            secondVariant.current.items.filter((item) => {
                              return (
                                item.attribute_id === selectedVariant.second
                              );
                            })[0] &&
                            secondVariant.current.items.filter((item) => {
                              return (
                                item.attribute_id === selectedVariant.second
                              );
                            })[0].attribute_description && (
                              <span className="text-[10px]">
                                {
                                  secondVariant.current.items.filter((item) => {
                                    return (
                                      item.attribute_id ===
                                      selectedVariant.second
                                    );
                                  })[0].attribute_description
                                }
                              </span>
                            )}
                        </div>
                        <div className="flex flex-wrap gap-[0.25rem]">
                          {secondVariant.current.items.map(
                            (product_attribute, index) => {
                              return (
                                <div
                                  className={`w-[4.06rem] min-w-[4.25rem] ${
                                    selectedVariant.second ===
                                    product_attribute.attribute_id
                                      ? product_attribute.attribute_type === 1
                                        ? "bg-[#000] hover:bg-[#000] text-white  border-[#000]  hover:border-[#000]"
                                        : "bg-[#603085] text-white hover:text-white border-[#603085] hover:bg-[#603085] hover:border-[#603085]"
                                      : product_attribute.attribute_type === 1
                                      ? "bg-[#fff] hover:bg-[#000] text-[#000] hover:text-white border-[#7F7F7F] hover:border-[#000]"
                                      : "bg-[#fff] hover:bg-[#603085] text-[#000] hover:text-white border-[#7F7F7F] hover:border-[#603085]"
                                  }    text-[12px] rounded-[1.25rem] flex items-center cursor-pointer border-solid border-[0.5px]`}
                                  key={index}
                                  onClick={() => {
                                    setSelectedVariant({
                                      ...selectedVariant,
                                      second: product_attribute.attribute_id,
                                    });
                                    const _selectedAttribute =
                                      attributes.filter((item) => {
                                        return (
                                          item.attribute_id ===
                                            selectedVariant.first +
                                              "," +
                                              product_attribute.attribute_id ||
                                          item.attribute_id ===
                                            product_attribute.attribute_id +
                                              "," +
                                              selectedVariant.first
                                        );
                                      })[0];
                                    if (_selectedAttribute) {
                                      setSelectedAttribute(_selectedAttribute);
                                      if (_selectedAttribute.thumbnail) {
                                        setActiveImage(
                                          _selectedAttribute.thumbnail
                                        );
                                      }
                                    } else {
                                      setSelectedAttribute(null);
                                    }
                                  }}
                                >
                                  {product_attribute.attribute_type === 1 ? (
                                    <>
                                      <span
                                        className={`w-[1.625rem] h-[1.625rem] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                        style={{
                                          background:
                                            product_attribute.attribute_value,
                                        }}
                                      ></span>
                                      <div className="flex items-center justify-center w-[calc(100%-1.625rem)]">
                                        {product_attribute.attribute_name}
                                      </div>
                                    </>
                                  ) : (
                                    <div className="flex items-center justify-center w-full py-[0.1rem]">
                                      {product_attribute.attribute_name}
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          )}
                        </div>
                        {selectedVariant.second &&
                          secondVariant.current.items &&
                          secondVariant.current.items.length > 0 &&
                          secondVariant.current.items.filter((item) => {
                            return item.attribute_id === selectedVariant.second;
                          })[0] &&
                          secondVariant.current.items.filter((item) => {
                            return item.attribute_id === selectedVariant.second;
                          })[0].attribute_description && (
                            <>
                              <div className="text-[14px]">
                                {secondVariant.current.type_name +
                                  " Description"}
                              </div>
                              <div className="text-[12px]">
                                {
                                  secondVariant.current.items.filter((item) => {
                                    return (
                                      item.attribute_id ===
                                      selectedVariant.second
                                    );
                                  })[0].attribute_description
                                }
                              </div>
                            </>
                          )}
                      </>
                    )}
                </>
              ) : (
                <div className=" flex flex-wrap gap-[5px] mt-[15px]">
                  {attributes.map((attribute, index) => {
                    return (
                      <div
                        className={`w-[calc(50%-4px)] p-[3px] ${
                          attribute.id === selectedAttribute.id
                            ? "bg-[#603085] text-white"
                            : "bg-[#D9D9D9] text-[#626262]"
                        }  hover:bg-[#603085]  hover:text-white rounded-[20px] flex items-center justify-center cursor-pointer`}
                        key={index}
                        onClick={() => {
                          setSelectedAttribute(attribute);
                        }}
                      >
                        {attribute.attribute_type === 1 && (
                          <span
                            className={`w-[20px] h-[20px] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                            style={{
                              background: attribute.attribute_value,
                            }}
                          ></span>
                        )}
                        {attribute.attribute_name}
                      </div>
                    );
                  })}
                </div>
              )}

              <div className="flex items-end flex-grow-[1] justify-end">
                {selectedAttribute && selectedAttribute.stock > 0 ? (
                  <div>
                    {getToken() && (
                      <span className="flex flex-wrap items-center">
                        <span className="text-[#0BC154] text-[1rem] leading-normal">
                          AED{" "}
                          <Price
                            price={
                              !selectedAttribute.b2b_sale_price
                                ? selectedAttribute.b2b_price
                                : selectedAttribute.b2b_sale_price
                            }
                          />
                        </span>
                        <span className="text-[0.75rem] text-[#0004] ml-[0.25rem] leading-normal">
                          <del>
                            AED{" "}
                            <Price
                              price={
                                !selectedAttribute.sale_price
                                  ? selectedAttribute.price
                                  : selectedAttribute.sale_price
                              }
                            />
                          </del>
                        </span>
                      </span>
                    )}
                  </div>
                ) : (
                  <span className="text-[#7F7F7F]">Unavailable</span>
                )}
                <div className="flex items-end flex-grow-[1] justify-end">
                  {selectedAttribute && (
                    <AddToCartButton
                      attribute={selectedAttribute}
                      text="Add"
                      className="bg-[#000] hover:bg-[#603085] w-full text-center"
                      clickHandler={() => {
                        // setToggle(false);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
