import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import { Autoplay } from 'swiper/modules';
// import Slide1 from '../../assets/img/slider4-1.jpg'
import Slide2 from '../../assets/img/slider4-2.jpg'
import Slide3 from '../../assets/img/slider4-3.jpg'
// import MobileSlide1 from '../../assets/img/slider4-m-1.jpg'
import MobileSlide2 from '../../assets/img/slider4-m-2.jpg'
import MobileSlide3 from '../../assets/img/slider4-m-3.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function HomeSlider4() {
  return (
    <Swiper
    loop={true}
    modules={[
      Autoplay,
    ]}
    speed={2000}
    autoplay={{
      delay: 2000,
      disableOnInteraction: false,
    }}
    className='mt-[0.75rem]'
    >
      {/* <SwiperSlide>
        <LazyLoadImage src={Slide1} srcSet={`${MobileSlide1} 767w, ${Slide1}`} sizes="(max-width: 767px) 767px, 1920px" alt="slider 1" style={{ width: "100%" }} />
      </SwiperSlide> */}
      <SwiperSlide>
        <LazyLoadImage src={Slide2} srcSet={`${MobileSlide2} 767w, ${Slide2}`} sizes="(max-width: 767px) 767px, 1920px" alt="slider 2" style={{ width: "100%" }} />
      </SwiperSlide>
      <SwiperSlide>
        <LazyLoadImage src={Slide3} srcSet={`${MobileSlide3} 767w, ${Slide3}`} sizes="(max-width: 767px) 767px, 1920px" alt="slider 3" style={{ width: "100%" }} />
      </SwiperSlide>
    </Swiper>
  );
}
