import AddToCartButton from "../add-to-cart-button/add-to-cart-button";
import Price from "../price/price";
import { Link } from "react-router-dom";
import ProductDefaultImage from "../../assets/img/produc-placeholder.png";

export default function CartItemCard(props) {
  const item = props.item;

  return (
    <div className="rounded-[20px] shadow-[0_4px_10px_0_rgba(0,0,0,0.25)] flex p-[5px] mt-[5px]">
      <div className="w-[33.44%] max-w-auto 2xl:max-w-[5vw] overflow-hidden flex items-center justify-center border-solid ltr:border-r-[1px] rtl:border-l-[1px] border-[rgba(0,0,0,0.20)]">
        <img
          src={
            item.images
              ? item.images.split(",")[0]
              : item.thumbnail
              ? item.thumbnail
              : ProductDefaultImage
          }
          alt={item.name}
          className="max-w-auto 2xl:max-w-[5vw] max-h-auto 2xl:max-h-[5vw]"
        />
      </div>
      <div className="w-[66.56%] pl-[4px] flex flex-col">
        <div className="mb-[15px]">
          <Link to={`/product/${item.slug}`}>
            <h3 className="text-[14px] 2xl:text-[18px] text-[rgba(30,30,30,0.80)] leading-normal p-0 m-0">
              {item.name}
            </h3>
            {item.attributes && (
              <div className="text-[10px]">
                {item.attributes.map((aitem, index) => {
                  return (
                    <span key={index} className="mr-[10px]">
                      {aitem.type === 1 ? (
                        <span
                          className={`w-[15px] h-[15px] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                          style={{ background: aitem.value }}
                        ></span>
                      ) : (
                        aitem.type_name + ":" + aitem.name
                      )}
                    </span>
                  );
                })}
              </div>
            )}
            {(item.attribute_type === 3 || item.attribute_type === 2) && (
              <div className="text-[10px]">{item.attribute_name}</div>
            )}
          </Link>
        </div>
        <div className="mt-auto">
        {item.count > 30 && (
            <div className="text-[#FF547D] text-[10px] mb-[3px]">This item needs Shawn approval.</div>
          )}
          <div className="flex items-center">
            <div className="w-full text-[#0BC154]">
              {!item.sale_price ? (
                <span className="text-[#0BC154] text-[14px] 2xl:text-[18px] leading-normal">
                  <Price price={item.price} />
                </span>
              ) : (
                <>
                  <div className="text-[12px] 2xl:text-[14px] leading-normal text-[#FF547D]">
                    <del>
                      <Price price={item.price} />
                    </del>
                  </div>
                  <div className="text-[#0BC154] text-[16px] 2xl:text-[18px] leading-normal">
                    <Price price={item.sale_price} />
                  </div>
                </>
              )}
            </div>
            <div className="w-full flex justify-end">
              <AddToCartButton
                attribute={item}
                text="Add To Cart"
              />
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}
