import { useContext } from "react";
import AppContext from "../../context/context";
import Quantity from "../quantity/quantity";
import { useSnackbar } from "notistack";
import { useRef } from "react";
import TrashIcon from "../../assets/img/trash_white_icon.svg";

export default function AddToCartButton(props) {
  const { cart, addToCart, removeFromCart } = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();
  const attribute = props.attribute;

  const cartCount = useRef(0);

  return (
    attribute &&
    attribute.stock &&
    attribute.price &&
    attribute.stock > 0 &&
    attribute.price > 0 && (
      <div className="flex">
        {cart &&
        cart.find((item) => {
          return parseInt(item.id) === parseInt(attribute.id);
        }) ? (
          <>
            {props.deleteAll && (
              <div
                className="cursor-pointer shadow w-[30px] h-[30px] bg-[#000] ltr:mr-[5px] rtl:ml-[5px] text-white flex items-center justify-center rounded-[20px]"
                onClick={() => {
                  removeFromCart(attribute.id);
                }}
              >
                <img src={TrashIcon} alt="delete all" />
              </div>
            )}
            <Quantity
              min={0}
              key={"OKAYG_" + (10000 + Math.random() * (1000000 - 10000))}
              defaultValue={
                cart.find((item) => {
                  return item.id === attribute.id;
                }).count
              }
              valueChange={(value) => {
                // if (value > attribute.stock) {
                //   enqueueSnackbar(
                //     "You added more than 30 of this item. This action needs Shawn approval.",
                //     {
                //       variant: "error",
                //       vertical: "bottom",
                //       horizontal: "left",
                //     }
                //   );
                // }
                // if (cartCount.current <= attribute.stock) {
                cartCount.current = value;
                // } else {
                //   cartCount.current = attribute.stock;
                // }
                if (cartCount.current === 0) {
                  removeFromCart(attribute.id);
                  enqueueSnackbar("Product removed", {
                    variant: "error",
                    vertical: "bottom",
                    horizontal: "left",
                  });
                } else {
                  // if (cartCount.current <= attribute.stock) {
                  if (addToCart(attribute.id, cartCount.current)) {
                    enqueueSnackbar("Product added", {
                      variant: "success",
                      vertical: "bottom",
                      horizontal: "left",
                    });
                  } else {
                    enqueueSnackbar("Error", {
                      variant: "error",
                      vertical: "bottom",
                      horizontal: "left",
                    });
                  }
                  // } else {
                  //   enqueueSnackbar("This product is out of stock", {
                  //     variant: "error",
                  //     vertical: "bottom",
                  //     horizontal: "left",
                  //   });
                  // }
                }

                
              }}
            />
          </>
        ) : (
          <span
            className={`cursor-pointer p-[4px_17px] text-[14px] 2xl:text-[24px] inline-block text-[#E6E6E6] bg-[#000000] rounded-[100px] ${
              props.className ? props.className : ""
            }`}
            onClick={() => {
              if (cartCount.current <= attribute.stock) {
                cartCount.current = 1;
                if (addToCart(attribute.id, cartCount.current)) {
                  enqueueSnackbar("Product added", {
                    variant: "success",
                    vertical: "bottom",
                    horizontal: "left",
                  });
                } else {
                  enqueueSnackbar("Error", {
                    variant: "error",
                    vertical: "bottom",
                    horizontal: "left",
                  });
                }
              }

              if (props.clickHandler) {
                props.clickHandler();
              }
            }}
          >
            {props.text}
          </span>
        )}
      </div>
    )
  );
}
