import Header from "./header";
import { Helmet } from "react-helmet";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Footer from "./footer";
import ProductSkeleton from "../components/skeleton/product/product-skeleton";
import ProductCard from "../components/productcard/productcard";
import ShopPagination from "../components/pagination/pagination";
import AppContext from "../context/context";
import { useParams } from "react-router-dom";
import BackButton from "../components/back-button/back-button";

export default function ProductNew() {
  const { PUBLIC_URL, filters, setFilters, setPageID,getToken } = useContext(AppContext);
  setPageID(3);

  const page = useRef(1);
  const [products, setProducts] = useState([]);
  const [productsCount, setProductsCount] = useState(0);
  const [loader, setLoader] = useState(0);

  let { id } = useParams();

  const getProducts = useCallback(async () => {
    try {
      setLoader(1);

      let filter = "";

      if (page.current > 0) {
        filter = filter + "p=" + page.current;
      }

      if (filters.searchTerm) {
        if (!filter) {
          filter = filter + "term=" + filters.searchTerm;
        } else {
          filter = filter + "&term=" + filters.searchTerm;
        }
      }

      await fetch(`${PUBLIC_URL}/api/product/productlist?${filter}`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then(async (data) => {
          setProductsCount(data.count);
          setProducts(data.products);
          setLoader(0);
        });
    } catch (error) {
      setLoader(0);
    }
  }, [PUBLIC_URL, page, id, filters]);

  useEffect(() => {
    setProducts([]);
    getProducts();
  }, [page, getProducts, id]);

  useEffect(() => {
    setProducts([]);
    getProducts();
  }, [filters, setFilters, getProducts]);

  return (
    <>
      <Helmet>
        <title>Shawn</title>
        <meta name="description" content="Pet supplies store" />
      </Helmet>
      <Header />
      <main className="h-shop bg-white md:bg-[#f2f2f4]">
        <BackButton />
        <div className="block md:hidden text-[0.875rem] mb-[0.94rem] px-[1.5rem] text-[#000e]">
          {products && (
            <div>
              {(page.current - 1) * 24 + 1} - {(page.current - 1) * 24 + 24} of{" "}
              {productsCount} Products
            </div>
          )}
        </div>
        <div className="flex flex-wrap lg:flex-nowrap px-[1rem] md:px-[1.5rem] lg:px-[3.5rem]">
          <div className="w-full mx-auto text-[#4D4D4D]">
            <div className="h-products flex flex-wrap w-full">
              {loader === 0 ? (
                products ? (
                  products.map((item, index) => {
                    return <ProductCard product={item} key={index} />;
                  })
                ) : (
                  <div className="w-full p-[20px] bg-[#fff] rounded-[15px]">
                    There is no product.
                  </div>
                )
              ) : (
                <div className="flex flex-wrap gap-[10px]">
                  <ProductSkeleton count={24} />
                </div>
              )}
            </div>
            {productsCount > 24 && (
              <div className="w-full flex items-center justify-center">
                <ShopPagination
                  pagesCount={
                    productsCount % 24 === 0
                      ? productsCount / 24
                      : Math.floor(productsCount / 24) + 1
                  }
                  page={page.current}
                  handleChange={(event, value) => {
                    page.current = value;
                    getProducts();
                  }}
                />
              </div>
            )}
            <div className="mb-[0.94rem] md:mb-[6.5rem]"></div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
