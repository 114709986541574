import React, { useContext } from "react";
import Header from "./header";
import Footer from "./footer";
import BackButton from "../components/back-button/back-button";
import { Link, useNavigate, useParams } from "react-router-dom";
import Profile from "./profile";
import PurchaseCard from "../components/purchase-card/purchase-card";
import AppContext from "../context/context";

export default function Dashboard() {
  const { getToken } = useContext(AppContext);
  const navigate = useNavigate();

  if(!getToken()){
    navigate("/login")
  }

  let { slug } = useParams();
  return (
    <>
      <Header />
      <main className="bg-white md:bg-[#f2f2f4] pb-[2.51rem] md:pb-[6.62rem]">
        <BackButton />
        <div className="px-[1rem] md:px-[1.5rem] lg:px-[3.5rem]">
          <div className="flex flex-wrap md:flex-nowrap">
            <div className="flex flex-row md:flex-col gap-[15px] w-full md:w-[15%] px-0 md:px-[10px] mb-[15px]">
              <Link
                to="/dashboard/profile"
                className={`${
                  !slug || slug === "profile"
                    ? "bg-transparent text-black"
                    : "bg-black text-white"
                } w-full hover:bg-transparent hover:text-black border-black border-solid border-[1px] rounded-[15px] text-center p-[3px_5px] cursor-pointer`}
              >
                Profile
              </Link>
              <Link
                to="/dashboard/orders"
                className={`${
                  slug === "orders"
                    ? "bg-transparent text-black"
                    : "bg-black text-white"
                } w-full  hover:bg-transparent hover:text-black border-black border-solid border-[1px] rounded-[15px] text-center p-[3px_5px] cursor-pointer`}
              >
                Orders
              </Link>
            </div>
            <div className="w-full md:w-[85%] pl-0 md:pl-[15px]">
              {(!slug || slug === "profile") && <Profile />}
              {slug === "orders" && <PurchaseCard />}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
