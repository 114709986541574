import Header from "./header";
// import BannerBg from "../assets/img/banner.jpg";
// import MobileBannerBg from "../assets/img/banner-mobile.jpg";

import Leashes11 from "../assets/img/leashes1-1.jpg";
import Leashes12 from "../assets/img/leashes1-2.jpg";
import Leashes13 from "../assets/img/leashes1-3.jpg";
import Leashes14 from "../assets/img/leashes1-4.jpg";
import Leashes15 from "../assets/img/leashes1-5.jpg";
import Leashes16 from "../assets/img/leashes1-6.jpg";
import Leashes17 from "../assets/img/leashes1-7.jpg";
import Leashes18 from "../assets/img/leashes1-8.jpg";
import Leashes19 from "../assets/img/leashes1-9.jpg";
import Leashes110 from "../assets/img/leashes1-10.jpg";
import MobileLeashes11 from "../assets/img/leashes1-m-1.jpg";
import MobileLeashes12 from "../assets/img/leashes1-m-2.jpg";
import MobileLeashes13 from "../assets/img/leashes1-m-3.jpg";
import MobileLeashes14 from "../assets/img/leashes1-m-4.jpg";
import MobileLeashes15 from "../assets/img/leashes1-m-5.jpg";
import MobileLeashes16 from "../assets/img/leashes1-m-6.jpg";
import MobileLeashes17 from "../assets/img/leashes1-m-7.jpg";
import MobileLeashes18 from "../assets/img/leashes1-m-8.jpg";
import MobileLeashes19 from "../assets/img/leashes1-m-9.jpg";
import MobileLeashes110 from "../assets/img/leashes1-m-10.jpg";

import Leashes21 from "../assets/img/leashes2-1.jpg";
import Leashes22 from "../assets/img/leashes2-2.jpg";
import Leashes23 from "../assets/img/leashes2-3.jpg";
import Leashes24 from "../assets/img/leashes2-4.jpg";
import Leashes25 from "../assets/img/leashes2-5.jpg";
import Leashes26 from "../assets/img/leashes2-6.jpg";
import Leashes27 from "../assets/img/leashes2-7.jpg";
import Leashes28 from "../assets/img/leashes2-8.jpg";
import Leashes29 from "../assets/img/leashes2-9.jpg";
import Leashes210 from "../assets/img/leashes2-10.jpg";
import MobileLeashes21 from "../assets/img/leashes2-m-1.jpg";
import MobileLeashes22 from "../assets/img/leashes2-m-2.jpg";
import MobileLeashes23 from "../assets/img/leashes2-m-3.jpg";
import MobileLeashes24 from "../assets/img/leashes2-m-4.jpg";
import MobileLeashes25 from "../assets/img/leashes2-m-5.jpg";
import MobileLeashes26 from "../assets/img/leashes2-m-6.jpg";
import MobileLeashes27 from "../assets/img/leashes2-m-7.jpg";
import MobileLeashes28 from "../assets/img/leashes2-m-8.jpg";
import MobileLeashes29 from "../assets/img/leashes2-m-9.jpg";
import MobileLeashes210 from "../assets/img/leashes2-m-10.jpg";

import Leashes31 from "../assets/img/leashes3-1.jpg";
import Leashes32 from "../assets/img/leashes3-2.jpg";
import Leashes33 from "../assets/img/leashes3-3.jpg";
import Leashes34 from "../assets/img/leashes3-4.jpg";
import MobileLeashes31 from "../assets/img/leashes3-m-1.jpg";
import MobileLeashes32 from "../assets/img/leashes3-m-2.jpg";
import MobileLeashes33 from "../assets/img/leashes3-m-3.jpg";
import MobileLeashes34 from "../assets/img/leashes3-m-4.jpg";

import Toys11 from "../assets/img/toys1-1.jpg";
import Toys12 from "../assets/img/toys1-2.jpg";
import Toys13 from "../assets/img/toys1-3.jpg";
import Toys14 from "../assets/img/toys1-4.jpg";
import Toys15 from "../assets/img/toys1-5.jpg";
import Toys16 from "../assets/img/toys1-6.jpg";
import MobileToys11 from "../assets/img/toys1-m-1.jpg";
import MobileToys12 from "../assets/img/toys1-m-2.jpg";
import MobileToys13 from "../assets/img/toys1-m-3.jpg";
import MobileToys14 from "../assets/img/toys1-m-4.jpg";
import MobileToys15 from "../assets/img/toys1-m-5.jpg";
import MobileToys16 from "../assets/img/toys1-m-6.jpg";

import Clothes11 from "../assets/img/clothes1-1.jpg";
import Clothes12 from "../assets/img/clothes1-2.jpg";
import Clothes13 from "../assets/img/clothes1-3.jpg";
import Clothes14 from "../assets/img/clothes1-4.jpg";
import Clothes15 from "../assets/img/clothes1-5.jpg";
import Clothes16 from "../assets/img/clothes1-6.jpg";
import MobileClothes11 from "../assets/img/clothes1-m-1.jpg";
import MobileClothes12 from "../assets/img/clothes1-m-2.jpg";
import MobileClothes13 from "../assets/img/clothes1-m-3.jpg";
import MobileClothes14 from "../assets/img/clothes1-m-4.jpg";
import MobileClothes15 from "../assets/img/clothes1-m-5.jpg";
import MobileClothes16 from "../assets/img/clothes1-m-6.jpg";

import Clothes21 from "../assets/img/clothes2-1.jpg";
import Clothes22 from "../assets/img/clothes2-2.jpg";
import Clothes23 from "../assets/img/clothes2-3.jpg";
import Clothes24 from "../assets/img/clothes2-4.jpg";
import Clothes25 from "../assets/img/clothes2-5.jpg";
import Clothes26 from "../assets/img/clothes2-6.jpg";
import MobileClothes21 from "../assets/img/clothes2-m-1.jpg";
import MobileClothes22 from "../assets/img/clothes2-m-2.jpg";
import MobileClothes23 from "../assets/img/clothes2-m-3.jpg";
import MobileClothes24 from "../assets/img/clothes2-m-4.jpg";
import MobileClothes25 from "../assets/img/clothes2-m-5.jpg";
import MobileClothes26 from "../assets/img/clothes2-m-6.jpg";

// import MobileShampoo1 from "../assets/img/shampoo1-m-1.jpg";
// import MobileShampoo2 from "../assets/img/shampoo1-m-2.jpg";
// import MobileShampoo3 from "../assets/img/shampoo1-m-3.jpg";
// import MobileShampoo4 from "../assets/img/shampoo1-m-4.jpg";
// import MobileShampoo5 from "../assets/img/shampoo1-m-5.jpg";

import Collar1 from "../assets/img/collar2-1.jpg";
import Collar2 from "../assets/img/collar2-2.jpg";
import Collar3 from "../assets/img/collar2-3.jpg";
import Collar4 from "../assets/img/collar2-4.jpg";
import Collar5 from "../assets/img/collar2-5.jpg";
import Collar6 from "../assets/img/collar2-6.jpg";
import Collar7 from "../assets/img/collar2-7.jpg";
import Collar8 from "../assets/img/collar2-8.jpg";
import Collar9 from "../assets/img/collar2-9.jpg";
import Collar10 from "../assets/img/collar2-10.jpg";
import MobileCollar1 from "../assets/img/collar2-m-1.jpg";
import MobileCollar2 from "../assets/img/collar2-m-2.jpg";
import MobileCollar3 from "../assets/img/collar2-m-3.jpg";
import MobileCollar4 from "../assets/img/collar2-m-4.jpg";
import MobileCollar5 from "../assets/img/collar2-m-5.jpg";
import MobileCollar6 from "../assets/img/collar2-m-6.jpg";
import MobileCollar7 from "../assets/img/collar2-m-7.jpg";
import MobileCollar8 from "../assets/img/collar2-m-8.jpg";
import MobileCollar9 from "../assets/img/collar2-m-9.jpg";
import MobileCollar10 from "../assets/img/collar2-m-10.jpg";
import Leashes1 from "../assets/img/leashes-cat-1.jpg";
import Leashes2 from "../assets/img/leashes-cat-2.jpg";
import Leashes3 from "../assets/img/leashes-cat-3.jpg";
import Leashes4 from "../assets/img/leashes-cat-4.jpg";

import MobileLeashes1 from "../assets/img/leashes-cat-m-1.jpg";
import MobileLeashes2 from "../assets/img/leashes-cat-m-2.jpg";
import MobileLeashes3 from "../assets/img/leashes-cat-m-3.jpg";
import MobileLeashes4 from "../assets/img/leashes-cat-m-4.jpg";

import Bowl4_1 from "../assets/img/bowl4-1.jpg";
import Bowl4_2 from "../assets/img/bowl4-2.jpg";
import Bowl4_3 from "../assets/img/bowl4-3.jpg";
import Bowl4_4 from "../assets/img/bowl4-4.jpg";
import MobileBowl4_1 from "../assets/img/bowl4-m-1.jpg";
import MobileBowl4_2 from "../assets/img/bowl4-m-2.jpg";
import MobileBowl4_3 from "../assets/img/bowl4-m-3.jpg";
import MobileBowl4_4 from "../assets/img/bowl4-m-4.jpg";

import Bottle1_1 from "../assets/img/bottle1-1.jpg";
import Bottle1_2 from "../assets/img/bottle1-2.jpg";
import Bottle1_3 from "../assets/img/bottle1-3.jpg";
import Bottle1_4 from "../assets/img/bottle1-4.jpg";
import MobileBottle1_1 from "../assets/img/bottle1-m-1.jpg";
import MobileBottle1_2 from "../assets/img/bottle1-m-2.jpg";
import MobileBottle1_3 from "../assets/img/bottle1-m-3.jpg";
import MobileBottle1_4 from "../assets/img/bottle1-m-4.jpg";

import Leashes4_1 from "../assets/img/leashes4-1.jpg";
import Leashes4_2 from "../assets/img/leashes4-2.jpg";
import Leashes4_3 from "../assets/img/leashes4-3.jpg";
import Leashes4_4 from "../assets/img/leashes4-4.jpg";
import Leashes4_5 from "../assets/img/leashes4-5.jpg";
import MobileLeashes4_1 from "../assets/img/leashes4-m-1.jpg";
import MobileLeashes4_2 from "../assets/img/leashes4-m-2.jpg";
import MobileLeashes4_3 from "../assets/img/leashes4-m-3.jpg";
import MobileLeashes4_4 from "../assets/img/leashes4-m-4.jpg";
import MobileLeashes4_5 from "../assets/img/leashes4-m-5.jpg";

import Collar3_1 from "../assets/img/collar3-1.jpg";
import Collar3_2 from "../assets/img/collar3-2.jpg";
import Collar3_3 from "../assets/img/collar3-3.jpg";
import Collar3_4 from "../assets/img/collar3-4.jpg";
import Collar3_5 from "../assets/img/collar3-5.jpg";
import Collar3_6 from "../assets/img/collar3-6.jpg";
import Collar3_7 from "../assets/img/collar3-7.jpg";
import Collar3_8 from "../assets/img/collar3-8.jpg";
import Collar3_9 from "../assets/img/collar3-9.jpg";
import MobileCollar3_1 from "../assets/img/collar3-m-1.jpg";
import MobileCollar3_2 from "../assets/img/collar3-m-2.jpg";
import MobileCollar3_3 from "../assets/img/collar3-m-3.jpg";
import MobileCollar3_4 from "../assets/img/collar3-m-4.jpg";
import MobileCollar3_5 from "../assets/img/collar3-m-5.jpg";
import MobileCollar3_6 from "../assets/img/collar3-m-6.jpg";
import MobileCollar3_7 from "../assets/img/collar3-m-7.jpg";
import MobileCollar3_8 from "../assets/img/collar3-m-8.jpg";
import MobileCollar3_9 from "../assets/img/collar3-m-9.jpg";

import Soup1 from "../assets/img/bowl2-1.jpg";
import Soup2 from "../assets/img/bowl2-2.jpg";
import Soup3 from "../assets/img/bowl2-3.jpg";
import Soup4 from "../assets/img/bowl2-4.jpg";
import MobileSoup1 from "../assets/img/bowl2-m-1.jpg";
import MobileSoup2 from "../assets/img/bowl2-m-2.jpg";
import MobileSoup3 from "../assets/img/bowl2-m-3.jpg";
import MobileSoup4 from "../assets/img/bowl2-m-4.jpg";
import Fountain1 from "../assets/img/bowl3-1.jpg";
import Fountain2 from "../assets/img/bowl3-2.jpg";
import MobileFountain1 from "../assets/img/bowl3-m-1.jpg";
import MobileFountain2 from "../assets/img/bowl3-m-2.jpg";
import HomeSlider from "../components/home-slider/home-slider";
import FadedSlider from "../components/faded-slider/faded-slider";
import HomeFooterSlider from "../components/home-footer-slider/home-footer-slider";
import Footer from "./footer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import HomeSlider2 from "../components/home-slider-2/home-slider-2";
import HomeSlider3 from "../components/home-slider-3/home-slider-3";
import HomeSlider4 from "../components/home-slider-4/home-slider-4";
// import Slider5_1 from "../assets/img/slider5-1.jpg";
// import MobileSlider5_1 from "../assets/img/slider5-m-1.jpg";
import Slider6_1 from "../assets/img/slider6-1.jpg";
import MobileSlider6_1 from "../assets/img/slider6-m-1.jpg";
import AppContext from "../context/context";
import { useContext } from "react";
import BannerSlider from "../components/banner-slider/banner-slider";
import HomeSlider5 from "../components/home-slider-5/home-slider-5";
import HomeSlider1 from "../components/home-slider-1/home-slider-1";

export default function Home() {
  const { setPageID } = useContext(AppContext);
  setPageID(0);
  // const shampoo_mobile_images = [
  //   MobileShampoo1,
  //   MobileShampoo2,
  //   MobileShampoo3,
  //   MobileShampoo4,
  //   MobileShampoo5,
  // ];
  const leashes1_images = [
    Leashes11,
    Leashes12,
    Leashes13,
    Leashes14,
    Leashes15,
    Leashes16,
    Leashes17,
    Leashes18,
    Leashes19,
    Leashes110,
  ];
  const mobile_leashes1_images = [
    MobileLeashes11,
    MobileLeashes12,
    MobileLeashes13,
    MobileLeashes14,
    MobileLeashes15,
    MobileLeashes16,
    MobileLeashes17,
    MobileLeashes18,
    MobileLeashes19,
    MobileLeashes110,
  ];
  const leashes2_images = [
    Leashes21,
    Leashes22,
    Leashes23,
    Leashes24,
    Leashes25,
    Leashes26,
    Leashes27,
    Leashes28,
    Leashes29,
    Leashes210,
  ];
  const mobile_leashes2_images = [
    MobileLeashes21,
    MobileLeashes22,
    MobileLeashes23,
    MobileLeashes24,
    MobileLeashes25,
    MobileLeashes26,
    MobileLeashes27,
    MobileLeashes28,
    MobileLeashes29,
    MobileLeashes210,
  ];

  const leashes3_images = [Leashes31, Leashes32, Leashes33, Leashes34];
  const mobile_leashes3_images = [
    MobileLeashes31,
    MobileLeashes32,
    MobileLeashes33,
    MobileLeashes34,
  ];

  const toys1_images = [Toys11, Toys12, Toys13, Toys14, Toys15, Toys16];
  const mobile_toys1_images = [
    MobileToys11,
    MobileToys12,
    MobileToys13,
    MobileToys14,
    MobileToys15,
    MobileToys16,
  ];

  const clothes1_images = [
    Clothes11,
    Clothes12,
    Clothes13,
    Clothes14,
    Clothes15,
    Clothes16,
  ];
  const mobile_clothes1_images = [
    MobileClothes11,
    MobileClothes12,
    MobileClothes13,
    MobileClothes14,
    MobileClothes15,
    MobileClothes16,
  ];

  const clothes2_images = [
    Clothes21,
    Clothes22,
    Clothes23,
    Clothes24,
    Clothes25,
    Clothes26,
  ];
  const mobile_clothes2_images = [
    MobileClothes21,
    MobileClothes22,
    MobileClothes23,
    MobileClothes24,
    MobileClothes25,
    MobileClothes26,
  ];

  const collar_images = [
    Collar1,
    Collar2,
    Collar3,
    Collar4,
    Collar5,
    Collar6,
    Collar7,
    Collar8,
    Collar9,
    Collar10,
  ];
  const mobile_collar_images = [
    MobileCollar1,
    MobileCollar2,
    MobileCollar3,
    MobileCollar4,
    MobileCollar5,
    MobileCollar6,
    MobileCollar7,
    MobileCollar8,
    MobileCollar9,
    MobileCollar10,
  ];
  const leashes_images = [Leashes1, Leashes2, Leashes3, Leashes4];
  const mobile_leashes_images = [MobileLeashes1, MobileLeashes2, MobileLeashes3, MobileLeashes4];

  const soup_images = [Soup1, Soup2, Soup3, Soup4];
  const mobile_soup_images = [
    MobileSoup1,
    MobileSoup2,
    MobileSoup3,
    MobileSoup4,
  ];
  const fountain_images = [Fountain1, Fountain2];
  const mobile_fountain_images = [MobileFountain1, MobileFountain2];
  // const slider_urls = [
  //   "https://shawn.ae/product/-SHAWN-Pearl-Essence-shampoo",
  //   "https://shawn.ae/product/-SHAWN-Aroma-Intense-Shampoo-",
  //   "https://shawn.ae/product/SHAWN-White-Glow-Shampoo-",
  //   "",
  //   "https://shawn.ae/product/SHAWN-Puppy-Care-Shampoo-",
  // ];
  const bowl4_images = [Bowl4_1, Bowl4_2, Bowl4_3, Bowl4_4];
  const mobile_bowl4_images = [
    MobileBowl4_1,
    MobileBowl4_2,
    MobileBowl4_3,
    MobileBowl4_4,
  ];
  const bottle1_images = [Bottle1_1, Bottle1_2, Bottle1_3, Bottle1_4];
  const mobile_bottle1_images = [
    MobileBottle1_1,
    MobileBottle1_2,
    MobileBottle1_3,
    MobileBottle1_4,
  ];
  const leashes4_images = [
    Leashes4_1,
    Leashes4_2,
    Leashes4_3,
    Leashes4_4,
    Leashes4_5,
  ];
  const mobile_leashes4_images = [
    MobileLeashes4_1,
    MobileLeashes4_2,
    MobileLeashes4_3,
    MobileLeashes4_4,
    MobileLeashes4_5,
  ];
  const collar3_images = [
    Collar3_1,
    Collar3_2,
    Collar3_3,
    Collar3_4,
    Collar3_5,
    Collar3_6,
    Collar3_7,
    Collar3_8,
    Collar3_9,
  ];
  const mobile_collar3_images = [
    MobileCollar3_1,
    MobileCollar3_2,
    MobileCollar3_3,
    MobileCollar3_4,
    MobileCollar3_5,
    MobileCollar3_6,
    MobileCollar3_7,
    MobileCollar3_8,
    MobileCollar3_9,
  ];

  return (
    <>
      <Header />
      <main>
        <BannerSlider />

        {/* <LazyLoadImage
          src={BannerBg}
          srcSet={`${MobileBannerBg} 767w, ${BannerBg}`}
          sizes="(max-width: 767px) 767px, 1920px"
          style={{ width: "100%" }}
          className="mt-0 md:mt-[2rem] lg:mt-0"
        /> */}

        {window.innerWidth > 767 ? (
          <div className="flex mt-[0.75rem] gap-[0.75rem] flex-nowrap">
            <div className="w-[calc(50%-0.37rem)] block">
              <FadedSlider images={leashes1_images} />
            </div>
            <div className="w-[calc(50%-0.37rem)] block">
              <FadedSlider images={leashes2_images} />
            </div>
          </div>
        ) : (
          <>
            <div className="mt-[0.75rem]">
              <FadedSlider images={mobile_leashes2_images} />
            </div>
            <div className="mt-[0.75rem]">
              <FadedSlider images={mobile_leashes1_images} />
            </div>
          </>
        )}

        <HomeSlider />

        {window.innerWidth > 767 ? (
          <>
            <div className="flex mt-[0.75rem] gap-[0.75rem] flex-nowrap">
              <div className="w-[calc(50%-0.37rem)] block">
                <FadedSlider images={leashes3_images} />
              </div>
              <div className="w-[calc(50%-0.37rem)] block">
                <FadedSlider images={toys1_images} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="mt-[0.75rem]">
              <FadedSlider images={mobile_leashes3_images} />
            </div>
            <div className="mt-[0.75rem]">
              <FadedSlider images={mobile_toys1_images} />
            </div>
          </>
        )}

        <HomeSlider1 />

        {/* <div className="mt-[0.75rem]">
          <SwitchSlider images={slider_images} />
        </div> */}

        {window.innerWidth > 767 ? (
          <div className="flex mt-[0.75rem] gap-[0.75rem] flex-nowrap">
            <div className="w-[calc(50%-0.37rem)] block">
              <FadedSlider images={clothes1_images} />
            </div>
            <div className="w-[calc(50%-0.37rem)] block">
              <FadedSlider images={clothes2_images} />
            </div>
          </div>
        ) : (
          <>
            <div className="mt-[0.75rem]">
              <FadedSlider images={mobile_clothes2_images} />
            </div>
            <div className="mt-[0.75rem]">
              <FadedSlider images={mobile_clothes1_images} />
            </div>
          </>
        )}

        <HomeSlider2 />

        {window.innerWidth > 767 ? (
          <div className="flex mt-[0.75rem] gap-[0.75rem] flex-nowrap">
            <div className="w-[calc(50%-0.37rem)] block">
              <FadedSlider images={collar_images} />
            </div>

            <div className="w-[calc(50%-0.37rem)] block">
              <FadedSlider images={leashes_images} />
            </div>
          </div>
        ) : (
          <>
            <div className="mt-[0.75rem]">
              <FadedSlider images={mobile_collar_images} />
            </div>
            <div className="mt-[0.75rem]">
              <FadedSlider images={mobile_leashes_images} />
            </div>
          </>
        )}

        <HomeSlider3 />

        {window.innerWidth > 767 ? (
          <div className="flex mt-[0.75rem] gap-[0.75rem] flex-nowrap">
            <div className="w-[calc(50%-0.37rem)] block">
              <FadedSlider images={soup_images} />
            </div>

            <div className="w-[calc(50%-0.37rem)] block">
              <FadedSlider images={fountain_images} />
            </div>
          </div>
        ) : (
          <>
            <div className="mt-[0.75rem]">
              <FadedSlider images={mobile_soup_images} />
            </div>
            <div className="mt-[0.75rem]">
              <FadedSlider images={mobile_fountain_images} />
            </div>
          </>
        )}

        <HomeSlider4 />

        {window.innerWidth > 767 ? (
          <div className="flex mt-[0.75rem] gap-[0.75rem] flex-nowrap">
            <div className="w-[calc(50%-0.37rem)] block">
              <FadedSlider images={bowl4_images} />
            </div>

            <div className="w-[calc(50%-0.37rem)] block">
              <FadedSlider images={bottle1_images} />
            </div>
          </div>
        ) : (
          <>
            <div className="mt-[0.75rem]">
              <FadedSlider images={mobile_bowl4_images} />
            </div>
            <div className="mt-[0.75rem]">
              <FadedSlider images={mobile_bottle1_images} />
            </div>
          </>
        )}

        <HomeSlider5 />

        {window.innerWidth > 767 ? (
          <div className="flex mt-[0.75rem] gap-[0.75rem] flex-nowrap">
            <div className="w-[calc(50%-0.37rem)] block">
              <FadedSlider images={leashes4_images} />
            </div>

            <div className="w-[calc(50%-0.37rem)] block">
              <FadedSlider images={collar3_images} />
            </div>
          </div>
        ) : (
          <>
            <div className="mt-[0.75rem]">
              <FadedSlider images={mobile_leashes4_images} />
            </div>
            <div className="mt-[0.75rem]">
              <FadedSlider images={mobile_collar3_images} />
            </div>
          </>
        )}
        <div className="flex mt-[0.75rem] gap-[0.75rem] flex-nowrap">
          <LazyLoadImage
            src={Slider6_1}
            srcSet={`${MobileSlider6_1} 767w, ${Slider6_1}`}
            sizes="(max-width: 767px) 767px, 1920px"
            alt=""
            style={{ width: "100%" }}
          />
        </div>
        <HomeFooterSlider />
      </main>
      <Footer />
    </>
  );
}
