import { Helmet } from "react-helmet";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import AppContext from "../context/context";
import Footer from "./footer";
import { useParams } from "react-router-dom";
import GaranteeIcon from "../assets/img/garantee_icon.svg";
import CloseIcon from "../assets/img/close_icon.svg";
import InfoIcon from "../assets/img/info_icon.svg";
import LikeIcon from "../assets/img/like_icon.svg";
import FastDeliverIcon from "../assets/img/pink-delivery-icon.svg";
import { Box, Dialog, Rating, Tab, Tabs, Typography } from "@mui/material";
import Price from "../components/price/price";
import { styled } from "@mui/material/styles";
import ProductFAQCard from "../components/product-faq-card/product-faq-card";
import ProductDefaultImage from "../assets/img/produc-placeholder.png";
import Header from "./header";
import WishlistIcon from "../components/wishlist/wishlisticon";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/effect-fade";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import Accordion from "../components/accordion/accordion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BackButton from "../components/back-button/back-button";
import AddToCartButton from "../components/add-to-cart-button/add-to-cart-button";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    borderBottom: "1px #603085 solid",
  },
  // "@media (max-width:767px)": {
  "& .MuiTabs-flexContainer": {
    flexDirection: window.innerWidth < 768 ? "column" : "row",
  },
  // },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    flexDirection: "initial",
    gap: "5px",
    textTransform: "none",
    fontSize: "1rem",
    marginRight: theme.spacing(1),
    borderRadius: "20px",
    // minHeight: 'auto',
    color: "#000",
    fontWeight: 700,
    "&.Mui-selected": {
      color: "#603085",
      // background: "#603085",
    },
    "&.Mui-focusVisible": {
      // backgroundColor: "#333",
    },
  })
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        overflowY: "auto",
        padding: "15px",
        // background: "#fff",
        borderRadius: "20px",
        marginTop: "15px",
      }}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Product() {
  const { PUBLIC_URL, currentLanguage, pageID, setPageID, getToken, cart } =
    useContext(AppContext);
  if (pageID !== 1 && pageID !== 2) {
    setPageID(0);
  }
  const currentLanguageRef = useRef("");
  const [product, setProduct] = useState();
  let { slug } = useParams();
  const [attributes, setAttributes] = useState();
  const [metas, setMetas] = useState();
  const [selectedAttribute, setSelectedAttribute] = useState();
  const [packages, setPackages] = useState();
  const [reviews, setReviews] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [images, setImages] = useState([]);
  const [activeImage, setActiveImage] = useState();
  const [packageToggle, setPackageToggle] = useState(false);
  const [zoomToggle, setZoomToggle] = useState(false);
  const loaded = useRef(false);
  let tabCount = 0;
  const firstVariant = useRef({
    type: 0,
    type_name: "",
    items: [],
  });
  const secondVariant = useRef({
    type: 0,
    type_name: "",
    items: [],
  });

  const [selectedVariant, setSelectedVariant] = useState({
    first: null,
    second: null,
  });

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  useEffect(() => {
    currentLanguageRef.current = currentLanguage;
  }, [currentLanguage]);

  const getProducts = useCallback(async () => {
    try {
      // setLoader(1);

      let filter = "slug=" + slug;

      if (currentLanguageRef.current) {
        if (!filter) {
          filter = filter + "lng=" + currentLanguageRef.current;
        } else {
          filter = filter + "&lng=" + currentLanguageRef.current;
        }
      }

      await fetch(`${PUBLIC_URL}/api/product/productlist?${filter}`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then(async (data) => {
          if (data && data.count > 0) {
            const tproduct = data.products[0];
            setProduct(tproduct);
            let _attributes = JSON.parse(tproduct.attributes).filter(
              (item) => item.attribute_type !== 3
            )
              ? JSON.parse(tproduct.attributes).filter(
                  (item) => item.attribute_type !== 3
                )
              : [];
            setAttributes(_attributes);

            let _packages = JSON.parse(tproduct.attributes).filter(
              (item) => item.attribute_type === 3
            );
            setPackages(_packages);
            setMetas(tproduct.metas ? JSON.parse(tproduct.metas) : []);
            setSelectedAttribute(
              _attributes.length > 0 ? _attributes[0] : _packages[0]
            );

            setReviews(tproduct.reviews ? JSON.parse(tproduct.reviews) : null);

            if (
              tproduct.product_attributes &&
              tproduct.product_attributes.length > 0
            ) {
              firstVariant.current.items = [];
              secondVariant.current.items = [];
              tproduct.product_attributes.forEach((element) => {
                if (firstVariant.current.type === 0) {
                  firstVariant.current = {
                    type: element.attribute_type,
                    type_name: element.attribute_type_name,
                    items: [element],
                  };
                } else if (
                  firstVariant.current.type === element.attribute_type
                ) {
                  firstVariant.current = {
                    ...firstVariant.current,
                    items: [...firstVariant.current.items, element],
                  };
                } else {
                  secondVariant.current = {
                    type: element.attribute_type,
                    type_name: element.attribute_type_name,
                    items: [...secondVariant.current.items, element],
                  };
                }
              });
            }

            const ids = _attributes[0].attribute_id.split(",");

            if (ids[0] && ids[1]) {
              setSelectedVariant({ first: +ids[0], second: +ids[1] });
            } else if (ids[0] && !ids[1]) {
              setSelectedVariant({ first: +ids[0], second: null });
            }

            setFaqs(tproduct.faqs ? JSON.parse(tproduct.faqs) : null);

            let _images = tproduct.images ? tproduct.images.split(",") : [];

            _attributes.forEach((element) => {
              if (element.thumbnail) {
                _images = [..._images, element.thumbnail];
              }
            });

            setImages(_images);

            setActiveImage(
              _images.length > 0 ? _images[0] : ProductDefaultImage
            );
          }
          // setLoader(0);
          loaded.current = true;
        });
    } catch (error) {
      // setLoader(0);
      loaded.current = true;
    }
  }, [PUBLIC_URL, slug]);

  useEffect(() => {
    getProducts();
  }, [currentLanguage, getProducts]);

  return (
    <>
      <Helmet>
        <title>Shawn</title>
        {product && product.meta_description && (
          <meta name="description" content={product.meta_description} />
        )}
        {product && attributes && reviews && (
          <script type="application/ld+json">
            {JSON.stringify(`{
              "@context": "https://schema.org/", 
              "@type": "Product", 
              "name": "${product.name}",
              "image": "${
                product.images
                  ? product.images.split(",")[0]
                  : attributes
                  ? attributes.thumbnail
                  : ""
              }",
              "description": "${product.meta_description}",
              "brand": {
                "@type": "Brand",
                "name": "Shawn"
              },
              "sku": "",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": ${product.rate ? product.rate : 0},
                "ratingCount": ${reviews ? reviews.length : 0},
                "reviewCount": ${reviews ? reviews.length : 0}
              },
              "review": [
                ${
                  reviews &&
                  reviews.map((review, index) => {
                    return `${index > 0 && ","}{
                    "@type": "Review",
                    "name": "${review.customer_name}",
                    "reviewBody": "${review.comment}",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "${review.rate}"
                    },
                    "datePublished": "${review.date}",
                    "author": "",
                    "publisher": ""
                  }`;
                  })
                }
              ]
            }`)}
          </script>
        )}
      </Helmet>
      <Header />
      <main className="bg-white md:bg-[#f2f2f4] pb-[2.51rem] md:pb-[6.62rem]">
        <BackButton />
        {window.innerWidth > 767 ? (
          <>
            {product && (
              <div className="px-[1rem] md:px-[1.5rem] lg:px-[3.5rem]">
                <div className="flex">
                  <div className="w-full md:w-[50%]">
                    <div className="flex mt-[0.87rem]">
                      <div className="h-hide-scrollbar w-[120px] max-h-[33.8rem] relative">
                        {images.length > 4 && (
                          <>
                            <div
                              ref={navigationPrevRef}
                              className="absolute z-[10] top-[-30px] w-full flex items-center justify-center cursor-pointer"
                            >
                              <span className="flex items-center justify-center cursor-pointer">
                                <svg
                                  width="31"
                                  height="30"
                                  viewBox="0 0 31 30"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15.4199 14.25L9.66992 20L7.91992 18.25L15.4199 10.75L22.9199 18.25L21.1699 20L15.4199 14.25Z"
                                    fill="black"
                                    fillOpacity="0.8"
                                  />
                                </svg>
                              </span>
                            </div>
                            <div
                              ref={navigationNextRef}
                              className="absolute z-10 bottom-[-30px] w-full flex items-center justify-center cursor-pointer"
                            >
                              <span className="flex items-center justify-center cursor-pointer">
                                <svg
                                  width="31"
                                  height="30"
                                  viewBox="0 0 31 30"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15.4199 15.75L21.1699 10L22.9199 11.75L15.4199 19.25L7.91992 11.75L9.66992 10L15.4199 15.75Z"
                                    fill="black"
                                    fillOpacity="0.8"
                                  />
                                </svg>
                              </span>
                            </div>
                          </>
                        )}

                        <div className="h-hide-scrollbar overflow-y-auto max-h-[31.9375rem]">
                          {images.length > 4 ? (
                            <Swiper
                              slidesPerView={4}
                              spaceBetween={17}
                              navigation={{
                                prevEl: navigationNextRef.current,
                                nextEl: navigationPrevRef.current,
                                clickable: true,
                              }}
                              onBeforeInit={(swiper) => {
                                swiper.params.navigation.prevEl =
                                  navigationNextRef.current;
                                swiper.params.navigation.nextEl =
                                  navigationPrevRef.current;
                              }}
                              mousewheel={true}
                              direction="vertical"
                              modules={[Navigation]}
                            >
                              {images.map((image, index) => {
                                return (
                                  <SwiperSlide
                                    key={index}
                                    className="!h-[115px]"
                                  >
                                    <div
                                      key={index}
                                      className={`cursor-pointer flex items-center justify-center bg-white w-full border-solid border-[1px] ${
                                        activeImage === image
                                          ? "border-[#000000]"
                                          : "border-[#00000040]"
                                      }  rounded-[1.25rem] mb-[0.62rem] w-[4.5rem] h-[4.5rem] lg:w-[7.1876rem] lg:h-[7.1876rem] overflow-hidden`}
                                      onClick={() => {
                                        setActiveImage(image);
                                      }}
                                    >
                                      <LazyLoadImage
                                        src={image}
                                        alt={product.name}
                                        className="h-auto w-auto max-h-full max-w-full mx-auto"
                                      />
                                    </div>
                                  </SwiperSlide>
                                );
                              })}
                              {/* {product.images &&
                              product.images.split(",")?.map((image, index) => {
                                return (
                                  <SwiperSlide
                                    key={index}
                                    className="!h-[115px]"
                                  >
                                    <div
                                      key={index}
                                      className={`cursor-pointer flex items-center justify-center bg-white w-full border-solid border-[1px] ${
                                        activeImage === image
                                          ? "border-[#000000]"
                                          : "border-[#00000040]"
                                      }  rounded-[1.25rem] mb-[0.62rem] w-[4.5rem] h-[4.5rem] lg:w-[7.1876rem] lg:h-[7.1876rem] overflow-hidden`}
                                      onClick={() => {
                                        setActiveImage(image);
                                      }}
                                    >
                                      <LazyLoadImage
                                        src={image}
                                        alt={product.name}
                                        className="h-auto w-auto max-h-full max-w-full mx-auto"
                                      />
                                    </div>
                                  </SwiperSlide>
                                );
                              })}
                            {attributes &&
                              attributes?.map((attribute, index) => {
                                if (attribute.thumbnail) {
                                  return (
                                    <SwiperSlide
                                      key={index}
                                      className="!h-[115px]"
                                    >
                                      <div
                                        key={index}
                                        className={`cursor-pointer flex items-center justify-center bg-white w-full border-solid border-[1px] ${
                                          activeImage === attribute.thumbnail
                                            ? "border-[#000000]"
                                            : "border-[#00000040]"
                                        }  rounded-[1.25rem] mb-[1.06rem] w-[4.5rem] h-[4.5rem] lg:w-[7.1876rem] lg:h-[7.1876rem] overflow-hidden`}
                                        onClick={() => {
                                          setActiveImage(attribute.thumbnail);
                                        }}
                                      >
                                        <LazyLoadImage
                                          src={attribute.thumbnail}
                                          alt={product.name}
                                          className="h-auto w-auto max-h-full max-w-full mx-auto"
                                        />
                                      </div>
                                    </SwiperSlide>
                                  );
                                }
                                return null;
                              })} */}
                            </Swiper>
                          ) : (
                            images.map((image, index) => {
                              return (
                                <div
                                  key={index}
                                  className="!h-[115px] mb-[17px]"
                                >
                                  <div
                                    key={index}
                                    className={`cursor-pointer flex items-center justify-center bg-white w-full border-solid border-[1px] ${
                                      activeImage === image
                                        ? "border-[#000000]"
                                        : "border-[#00000040]"
                                    }  rounded-[1.25rem] mb-[0.62rem] w-[4.5rem] h-[4.5rem] lg:w-[7.1876rem] lg:h-[7.1876rem] overflow-hidden`}
                                    onClick={() => {
                                      setActiveImage(image);
                                    }}
                                  >
                                    <LazyLoadImage
                                      src={image}
                                      alt={product.name}
                                      className="h-auto w-auto max-h-full max-w-full mx-auto"
                                    />
                                  </div>
                                </div>
                              );
                            })
                          )}

                          {/* {product.images &&
                            product.images.split(",")?.map((image, index) => {
                              return (
                                <div
                                  key={index}
                                  className={`cursor-pointer flex items-center justify-center bg-white w-full border-solid border-[1px] ${
                                    activeImage === image
                                      ? "border-[#000000]"
                                      : "border-[#00000040]"
                                  }  rounded-[1.25rem] mb-[0.62rem] w-[4.5rem] h-[4.5rem] lg:w-[7.1876rem] lg:h-[7.1876rem] overflow-hidden`}
                                  onClick={() => {
                                    setActiveImage(image);
                                  }}
                                >
                                  <LazyLoadImage
                                    src={image}
                                    alt={product.name}
                                    className="h-auto w-auto max-h-full max-w-full mx-auto"
                                  />
                                </div>
                              );
                            })}
                          {attributes &&
                            attributes?.map((attribute, index) => {
                              if (attribute.thumbnail) {
                                return (
                                  <div
                                    key={index}
                                    className={`cursor-pointer flex items-center justify-center bg-white w-full border-solid border-[1px] ${
                                      activeImage === attribute.thumbnail
                                        ? "border-[#000000]"
                                        : "border-[#00000040]"
                                    }  rounded-[1.25rem] mb-[1.06rem] w-[4.5rem] h-[4.5rem] lg:w-[7.1876rem] lg:h-[7.1876rem] overflow-hidden`}
                                    onClick={() => {
                                      setActiveImage(attribute.thumbnail);
                                    }}
                                  >
                                    <LazyLoadImage
                                      src={attribute.thumbnail}
                                      alt={product.name}
                                      className="h-auto w-auto max-h-full max-w-full mx-auto"
                                    />
                                  </div>
                                );
                              }
                              return null;
                            })} */}
                        </div>
                      </div>
                      <div className="w-[calc(100%-120px)] flex ml-[20px]">
                        <div className="relative border-solid border-[1px] bg-white overflow-hidden border-[#00000040] rounded-[20px] w-full max-w-[31.9375rem]  min-h-[31.9375rem] max-h-[31.9375rem] flex items-center justify-center">
                          {activeImage ? (
                            <>
                              <LazyLoadImage
                                src={activeImage}
                                alt={product.name}
                                className="w-full max-h-[31.93rem] max-w-[31rem]"
                              />
                              <span
                                className="absolute top-[0.62rem] right-[0.62rem] z-[2] cursor-pointer"
                                onClick={() => {
                                  setZoomToggle(true);
                                }}
                              >
                                <svg
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g filter="url(#filter0_b_806_3587)">
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M1.67969 15.6294C1.67969 23.7089 8.22948 30.2587 16.3091 30.2587C24.3886 30.2587 30.9384 23.7089 30.9384 15.6294C30.9384 7.54979 24.3886 1 16.3091 1C8.22948 1 1.67969 7.54979 1.67969 15.6294ZM16.3091 0C7.6772 0 0.679688 6.99751 0.679688 15.6294C0.679688 24.2612 7.6772 31.2587 16.3091 31.2587C24.9409 31.2587 31.9384 24.2612 31.9384 15.6294C31.9384 6.99751 24.9409 0 16.3091 0ZM12.1119 7.339C9.41632 9.14285 8.26156 12.7012 10.0187 15.6424C11.8225 18.338 15.3809 19.4927 18.3221 17.7356C21.0177 15.9318 22.1724 12.3734 20.4153 9.43218C18.6114 6.73664 15.0531 5.58187 12.1119 7.339ZM18.3749 18.8469C15.0023 20.5433 11.1385 19.125 9.18002 16.1872C9.17562 16.1806 9.17138 16.1739 9.1673 16.1671C7.08101 12.69 8.49128 8.55087 11.5671 6.50034C11.5737 6.49594 11.5804 6.4917 11.5872 6.48761C15.0643 4.40132 19.2034 5.81159 21.254 8.88739C21.2584 8.89399 21.2626 8.90069 21.2667 8.90749C23.2694 12.2453 22.0501 16.1932 19.2284 18.3179L19.9586 19.7784C20.6248 19.7195 21.2065 20.0681 21.5127 20.3743C21.5464 20.408 21.5752 20.4465 21.5981 20.4884L23.5252 24.0215C23.5281 24.0268 23.5309 24.032 23.5335 24.0374C23.9905 24.9513 23.5184 25.8208 23.1187 26.2205C23.0807 26.2585 23.0368 26.2901 22.9887 26.3142C22.0747 26.7712 21.2053 26.2991 20.8056 25.8993C20.7718 25.8655 20.743 25.8271 20.7202 25.7852L18.793 22.252C18.7914 22.2491 18.7899 22.2462 18.7883 22.2433C18.7871 22.2409 18.7859 22.2386 18.7847 22.2362C18.3813 21.4294 18.7019 20.6573 19.0576 20.2124L18.3749 18.8469ZM19.8496 20.8221C19.6403 21.0678 19.5105 21.4429 19.6757 21.782L21.5601 25.2368C21.7899 25.4396 22.1409 25.5739 22.4687 25.4515C22.6779 25.2058 22.8078 24.8307 22.6425 24.4916L20.7581 21.0368C20.5283 20.8339 20.1774 20.6996 19.8496 20.8221ZM14.7365 13.5795V16.304H15.8501V13.5795H18.5746V12.4659H15.8501V9.74148H14.7365V12.4659H12.0121V13.5795H14.7365Z"
                                      fill="black"
                                      fillOpacity="0.5"
                                    />
                                  </g>
                                  <defs>
                                    <filter
                                      id="filter0_b_806_3587"
                                      x="-3.32031"
                                      y="-4"
                                      width="39.2588"
                                      height="39.2587"
                                      filterUnits="userSpaceOnUse"
                                      colorInterpolationFilters="sRGB"
                                    >
                                      <feFlood
                                        floodOpacity="0"
                                        result="BackgroundImageFix"
                                      />
                                      <feGaussianBlur
                                        in="BackgroundImageFix"
                                        stdDeviation="2"
                                      />
                                      <feComposite
                                        in2="SourceAlpha"
                                        operator="in"
                                        result="effect1_backgroundBlur_806_3587"
                                      />
                                      <feBlend
                                        mode="normal"
                                        in="SourceGraphic"
                                        in2="effect1_backgroundBlur_806_3587"
                                        result="shape"
                                      />
                                    </filter>
                                  </defs>
                                </svg>
                              </span>
                            </>
                          ) : (
                            <LazyLoadImage
                              src={ProductDefaultImage}
                              alt={product.name}
                              className="w-full max-h-[31.93rem] max-w-[31rem]"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-[50%] pl-[1.31rem]">
                    <div className="flex items-center mb-[1.75rem] mt-[0.5rem]">
                      <h1 className="font-[700] text-[2.1875rem] leading-normal w-[calc(100%-1.38rem)]">
                        {product.name}
                      </h1>
                      <div className="w-[1.38rem]">
                        <WishlistIcon productId={product.id} />
                      </div>
                    </div>
                    {product && product.categories && (
                      <div className="font-[700] text-[1.25rem] mb-[1.75rem] leading-normal">
                        {JSON.parse(product.categories).map(
                          (category, index) => {
                            return (
                              <React.Fragment key={index}>
                                {index !== 0 && (
                                  <span className="mx-[5px]">-</span>
                                )}
                                <span className="inline-block text-[#000]">
                                  {category.name}
                                </span>
                              </React.Fragment>
                            );
                          }
                        )}
                      </div>
                    )}
                    <div className="flex items-center relative mb-[1.75rem]">
                      <div className="w-[50%] z-[2]">
                        <span className="bg-[#f2f2f4] pr-[10px] text-[#0008] text-[0.75rem]">
                          SKU: {selectedAttribute && selectedAttribute.sku}
                        </span>
                      </div>
                      <div className="w-[50%] flex justify-end z-[2]">
                        <span className="bg-[#f2f2f4] pl-[10px] text-[#0008] text-[0.75rem] uppercase">
                          {product.brand}
                        </span>
                      </div>
                      <div className="absolute w-full h-[1px] top-[50%] left-0 bg-[#30303088]"></div>
                    </div>

                    {metas && metas.length > 0 && (
                      <>
                        <div className="mt-[0.75rem] font-[700] text-[#000] text-[0.875rem]">
                          Product feature:
                        </div>
                        <div className="w-full flex gap-[0.75rem] flex-wrap">
                          {metas.map((meta, index) => {
                            return (
                              <span
                                className="flex items-center w-auto"
                                key={index}
                              >
                                <img
                                  src={GaranteeIcon}
                                  alt={meta.name}
                                  className="mr-[0.75rem] w-[0.89rem] h-[0.89rem]"
                                />
                                {meta.name}
                              </span>
                            );
                          })}
                        </div>
                        <div className="w-full h-[1px] bg-[#30303088] my-[1.75rem]"></div>
                      </>
                    )}

                    {product.product_attributes ? (
                      <>
                        {firstVariant.current.items.length > 0 && (
                          <>
                            <div className="text-[0.875rem] font-[700] text-[#000] mb-[0.25rem]">
                              {firstVariant.current.type_name}:
                              {selectedVariant.first &&
                                firstVariant.current.items &&
                                firstVariant.current.items.length > 0 &&
                                firstVariant.current.items.filter((item) => {
                                  return (
                                    item.attribute_id === selectedVariant.first
                                  );
                                })[0] &&
                                firstVariant.current.items.filter((item) => {
                                  return (
                                    item.attribute_id === selectedVariant.first
                                  );
                                })[0].attribute_description && (
                                  <span className="text-[12px]">
                                    {
                                      firstVariant.current.items.filter(
                                        (item) => {
                                          return (
                                            item.attribute_id ===
                                            selectedVariant.first
                                          );
                                        }
                                      )[0].attribute_description
                                    }
                                  </span>
                                )}
                            </div>
                            <div className="flex flex-wrap gap-[0.5rem]">
                              {firstVariant.current.items.map(
                                (product_attribute, index) => {
                                  return (
                                    <div
                                      className={`w-auto ${
                                        selectedVariant.first ===
                                        product_attribute.attribute_id
                                          ? "bg-[#603085] text-white border-[#603085]"
                                          : "bg-[#fff] text-[#626262] border-[#7F7F7F]"
                                      }  hover:bg-[#603085] hover:border-[#603085] hover:text-white text-[0.875rem] rounded-[1.25rem] min-w-[6.25rem] flex items-center cursor-pointer border-solid border-[0.5px]`}
                                      key={index}
                                      onClick={() => {
                                        setSelectedVariant({
                                          ...selectedVariant,
                                          first: product_attribute.attribute_id,
                                        });

                                        if (
                                          secondVariant.current.items.length ===
                                          0
                                        ) {
                                          const _selectedAttribute =
                                            attributes.filter((item) => {
                                              return (
                                                +item.attribute_id ===
                                                +product_attribute.attribute_id
                                              );
                                            })[0];
                                          setSelectedAttribute(
                                            _selectedAttribute
                                          );
                                          if (_selectedAttribute.thumbnail) {
                                            setActiveImage(
                                              _selectedAttribute.thumbnail
                                            );
                                          }
                                        } else if (selectedVariant.second) {
                                          const _selectedAttribute =
                                            attributes.filter((item) => {
                                              return (
                                                item.attribute_id ===
                                                  (product_attribute.attribute_id +
                                                    "," +
                                                    selectedVariant.second ||
                                                    selectedVariant.second +
                                                      "," +
                                                      product_attribute.attribute_id) ||
                                                item.attribute_id ===
                                                  (selectedVariant.second +
                                                    "," +
                                                    product_attribute.attribute_id ||
                                                    product_attribute.attribute_id +
                                                      "," +
                                                      selectedVariant.second)
                                              );
                                            })[0];
                                          if (_selectedAttribute) {
                                            setSelectedAttribute(
                                              _selectedAttribute
                                            );
                                            if (_selectedAttribute.thumbnail) {
                                              setActiveImage(
                                                _selectedAttribute.thumbnail
                                              );
                                            }
                                          }
                                          else{
                                            setSelectedAttribute(null)
                                          }
                                        }
                                      }}
                                    >
                                      {product_attribute.attribute_type ===
                                      1 ? (
                                        <>
                                          <span
                                            className={`w-[1.625rem] h-[1.625rem] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                            style={{
                                              background:
                                                product_attribute.attribute_value,
                                            }}
                                          ></span>
                                          <div className="flex items-center justify-center w-[calc(100%-1.625rem)]">
                                            {product_attribute.attribute_name}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="flex items-center justify-center w-full py-[0.25rem]">
                                            {product_attribute.attribute_name}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </>
                        )}

                        {secondVariant.current.items &&
                          secondVariant.current.items.length > 0 && (
                            <>
                              <div className="text-[0.875rem] font-[700] text-[#000] mb-[0.25rem] mt-[1rem]">
                                {secondVariant.current.type_name}:
                                {selectedVariant.second &&
                                  secondVariant.current.items &&
                                  secondVariant.current.items.length > 0 &&
                                  secondVariant.current.items.filter((item) => {
                                    return (
                                      item.attribute_id ===
                                      selectedVariant.second
                                    );
                                  })[0] &&
                                  secondVariant.current.items.filter((item) => {
                                    return (
                                      item.attribute_id ===
                                      selectedVariant.second
                                    );
                                  })[0].attribute_description && (
                                    <span className="text-[12px]">
                                      {
                                        secondVariant.current.items.filter(
                                          (item) => {
                                            return (
                                              item.attribute_id ===
                                              selectedVariant.second
                                            );
                                          }
                                        )[0].attribute_description
                                      }
                                    </span>
                                  )}
                              </div>
                              <div className="flex flex-wrap gap-[0.5rem]">
                                {secondVariant.current.items.map(
                                  (product_attribute, index) => {
                                    return (
                                      <div
                                        className={`w-[4.06rem] p-[0.12rem] min-w-[6.25rem] ${
                                          selectedVariant.second ===
                                          product_attribute.attribute_id
                                            ? product_attribute.attribute_type ===
                                              1
                                              ? "bg-[#000] hover:bg-[#000] text-white  border-[#000]  hover:border-[#000]"
                                              : "bg-[#603085] text-white hover:text-white border-[#603085] hover:bg-[#603085] hover:border-[#603085]"
                                            : product_attribute.attribute_type ===
                                              1
                                            ? "bg-[#fff] hover:bg-[#000] text-[#000] hover:text-white border-[#7F7F7F] hover:border-[#000]"
                                            : "bg-[#fff] hover:bg-[#603085] text-[#000] hover:text-white border-[#7F7F7F] hover:border-[#603085]"
                                        }    text-[0.75rem] rounded-[1.25rem] flex items-center cursor-pointer border-solid border-[0.5px]`}
                                        key={index}
                                        onClick={() => {
                                          setSelectedVariant({
                                            ...selectedVariant,
                                            second:
                                              product_attribute.attribute_id,
                                          });
                                          const _selectedAttribute =
                                            attributes.filter((item) => {
                                              return (
                                                item.attribute_id ===
                                                  selectedVariant.first +
                                                    "," +
                                                    product_attribute.attribute_id ||
                                                item.attribute_id ===
                                                  product_attribute.attribute_id +
                                                    "," +
                                                    selectedVariant.first
                                              );
                                            })[0];
                                          
                                          if (_selectedAttribute) {
                                            setSelectedAttribute(
                                              _selectedAttribute
                                            );
                                            if (_selectedAttribute.thumbnail) {
                                              setActiveImage(
                                                _selectedAttribute.thumbnail
                                              );
                                            }
                                          } else {
                                            setSelectedAttribute(null)
                                          }
                                        }}
                                      >
                                        {product_attribute.attribute_type ===
                                        1 ? (
                                          <>
                                            <span
                                              className={`w-[1.625rem] h-[1.625rem] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                              style={{
                                                background:
                                                  product_attribute.attribute_value,
                                              }}
                                            ></span>
                                            <div className="flex items-center justify-center w-[calc(100%-1.625rem)]">
                                              {product_attribute.attribute_name}
                                            </div>
                                          </>
                                        ) : (
                                          <div className="flex items-center justify-center w-full py-[0.25rem]">
                                            {product_attribute.attribute_name}
                                          </div>
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                              {selectedVariant.second &&
                                secondVariant.current.items &&
                                secondVariant.current.items.length > 0 &&
                                secondVariant.current.items.filter((item) => {
                                  return (
                                    item.attribute_id === selectedVariant.second
                                  );
                                })[0] &&
                                secondVariant.current.items.filter((item) => {
                                  return (
                                    item.attribute_id === selectedVariant.second
                                  );
                                })[0].attribute_description && (
                                  <>
                                    <div className="text-[14px]">
                                      {secondVariant.current.type_name +
                                        " Description"}
                                    </div>
                                    <div className="text-[12px]">
                                      {
                                        secondVariant.current.items.filter(
                                          (item) => {
                                            return (
                                              item.attribute_id ===
                                              selectedVariant.second
                                            );
                                          }
                                        )[0].attribute_description
                                      }
                                    </div>
                                  </>
                                )}
                            </>
                          )}
                      </>
                    ) : (
                      <>
                        {attributes.length > 1 && (
                          <div className="text-[0.875rem] font-[700] text-[#000] mb-[0.25rem]">
                            {attributes[0].attribute_type_name}
                          </div>
                        )}
                        <div className="flex flex-wrap">
                          {attributes.length > 1
                            ? attributes.map((attribute, index) => {
                                return (
                                  <span
                                    key={index}
                                    onClick={() => {
                                      setSelectedAttribute(attribute);
                                      if (attribute.thumbnail) {
                                        setActiveImage(attribute.thumbnail);
                                      }
                                    }}
                                    className={`text-[0.875rem] font-[700] rounded-[1.25rem] ${
                                      attribute.id === selectedAttribute.id
                                        ? "bg-[#603085] text-white"
                                        : "bg-transparent text-[#626262]"
                                    } cursor-pointer flex items-center rounded-[20px] min-w-[6.25rem] mr-[0.5rem] mb-[0.5rem] border-[#7F7F7F] border-solid border-[0.5px]`}
                                  >
                                    {attribute.attribute_type === 1 ? (
                                      <>
                                        <span
                                          className={`w-[1.625rem] h-[1.625rem] ml-[0.12rem] mr-[0.03rem] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                          style={{
                                            background:
                                              attribute.attribute_value,
                                          }}
                                        ></span>
                                        <div className="flex items-center justify-center w-[calc(100%-1.625rem)] py-[0.25rem]">
                                          {attribute.attribute_name}
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="flex items-center justify-center w-full py-[0.25rem]">
                                          {attribute.attribute_name}
                                        </div>
                                      </>
                                    )}
                                  </span>
                                );
                              })
                            : attributes[0] && (
                                <>
                                  {attributes.length == 1 && (
                                    <div className="text-[0.875rem] font-[700] text-[#000] mr-[5px]">
                                      {attributes[0].attribute_type_name}:{" "}
                                    </div>
                                  )}
                                  <span
                                    className={`text-[0.75rem] flex items-center justify-center`}
                                  >
                                    {attributes[0].attribute_type === 1 && (
                                      <span
                                        className={`w-[1.625rem] h-[1.625rem] ml-[0.12rem] mr-[0.03rem] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                        style={{
                                          background:
                                            attributes[0].attribute_value,
                                        }}
                                      ></span>
                                    )}
                                    {attributes[0].attribute_name}
                                  </span>
                                </>
                              )}
                        </div>
                      </>
                    )}

                    <div className="mt-[1.75rem] flex items-center">
                      <div className="w-[80%] text-[1.25rem] leading-normal text-[#303030bb]">
                        {selectedAttribute && selectedAttribute.stock > 0 ? (
                          <>
                            {!selectedAttribute.sale_price ? (
                              <div>
                                Price:{" "}
                                <span className="text-[#0BC154] font-[500]">
                                  AED{" "}
                                  <Price price={selectedAttribute.price} />
                                </span>
                              </div>
                            ) : (
                              <>
                                <div>
                                  Price:{" "}
                                  <span className="text-[#0004] font-[500]">
                                    AED{" "}
                                    {selectedAttribute && (
                                      <Price price={selectedAttribute.price} />
                                    )}
                                  </span>
                                </div>
                                <div>
                                  Price:{" "}
                                  <span className="text-[#0BC154] font-[500]">
                                    AED{" "}
                                    {selectedAttribute && (
                                      <Price
                                        price={selectedAttribute.sale_price}
                                      />
                                    )}
                                  </span>
                                </div>
                              </>
                            )}

                            {getToken() && (
                              <>
                                {!selectedAttribute.b2b_sale_price ? (
                                  <div>
                                    B2B Price:{" "}
                                    <span className="text-[#0004] font-[500]">
                                      AED{" "}
                                      {selectedAttribute && (
                                        <Price
                                          price={selectedAttribute.b2b_price}
                                        />
                                      )}
                                    </span>
                                  </div>
                                ) : (
                                  <div className="mt-[15px]">
                                    B2B Price:{" "}
                                    <span className="text-[#0BC154] font-[500]">
                                      AED{" "}
                                      {selectedAttribute && (
                                        <Price
                                          price={
                                            selectedAttribute.b2b_sale_price
                                              ? selectedAttribute.b2b_sale_price
                                              : selectedAttribute.b2b_price
                                          }
                                        />
                                      )}
                                    </span>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <span className="text-[#7F7F7F]">Unavailable</span>
                        )}
                      </div>
                      <div className="w-[20%] flex items-center justify-end text-[0.75rem] text-[#0BC154]">
                        {selectedAttribute && selectedAttribute.stock > 0 && (
                          <>Available</>
                        )}
                      </div>
                    </div>

                    {packages && packages.length > 0 && (
                      <>
                        <span className="flex items-center mt-[1.75rem]">
                          <span className="mr-[0.62rem]">
                            <svg
                              width="26"
                              height="24"
                              viewBox="0 0 26 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16.6294 7.51293C15.6432 7.25728 14.2728 6.88135 13.1743 6.88135C7.7574 6.88135 3.36621 10.2509 3.36621 14.4076C3.36621 16.7963 4.81624 18.9249 7.07767 20.3038L6.46496 22.3246C6.31739 22.8113 6.68163 23.3024 7.19024 23.3024H9.41859C9.58265 23.3024 9.74229 23.2492 9.87351 23.1507L11.6351 21.8287H14.7133L16.4749 23.1507C16.6061 23.2492 16.7658 23.3024 16.9299 23.3024H19.1582C19.6668 23.3024 20.0311 22.8113 19.8834 22.3246L19.2708 20.3038C20.618 19.4824 21.6773 18.3947 22.3136 17.1445"
                                stroke="#603085"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M16.6289 7.51266L22.3131 5.61792L22.207 10.201L24.8394 11.3021V15.7232L22.4067 16.9863"
                                stroke="#603085"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M17.8923 13.197C17.5436 13.197 17.2607 12.9142 17.2607 12.5654C17.2607 12.2167 17.5436 11.9338 17.8923 11.9338C18.2411 11.9338 18.5239 12.2167 18.5239 12.5654C18.5239 12.9142 18.2411 13.197 17.8923 13.197Z"
                                fill="#603085"
                                stroke="#603085"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M0.839844 9.40759C0.839844 9.40759 0.839844 12.4392 3.36616 13.1971"
                                stroke="#603085"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M14.4831 6.56917C14.646 6.09972 14.7347 5.59452 14.7347 5.06823C14.7347 2.5814 12.7553 0.56543 10.3136 0.56543C7.87195 0.56543 5.89258 2.5814 5.89258 5.06823C5.89258 6.2577 6.34541 7.33943 7.08505 8.14438"
                                stroke="#603085"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <span
                            className="text-[#603085] text-[0.75rem] font-[600] mr-[0.25rem] cursor-pointer"
                            onClick={() => {
                              setPackageToggle(!packageToggle);
                            }}
                          >
                            Click here
                          </span>
                          <span className="text-[0.75rem]">
                            to view the special offer and buy gold
                          </span>
                        </span>
                        <div
                          className={`${
                            packageToggle === true ? "flex" : "hidden"
                          }`}
                        >
                          {packages.map((item, index) => {
                            return (
                              <span
                                key={index}
                                onClick={() => {
                                  setSelectedAttribute(item);
                                  if (item.thumbnail) {
                                    setActiveImage(item.thumbnail);
                                  }
                                }}
                                className={`${
                                  selectedAttribute &&
                                  selectedAttribute.id === item.id
                                    ? "bg-[#603085] text-[#fff]"
                                    : "bg-[#80808080] text-[#fff]"
                                } cursor-pointer flex items-center text-[12px] p-[3px] rounded-[20px] mr-[2.19rem] mt-[1.25rem] shadow`}
                              >
                                <span className="mx-[10px]">
                                  {item.attribute_name}
                                </span>
                              </span>
                            );
                          })}
                        </div>
                      </>
                    )}

                    <div className="flex items-center mt-[1.75rem]">
                      <svg
                        width="17"
                        height="24"
                        viewBox="0 0 17 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_2567_10027)">
                          <path
                            d="M8.51953 13C7.6862 13 6.97786 12.7083 6.39453 12.125C5.8112 11.5417 5.51953 10.8333 5.51953 10C5.51953 9.16667 5.8112 8.45833 6.39453 7.875C6.97786 7.29167 7.6862 7 8.51953 7C9.35286 7 10.0612 7.29167 10.6445 7.875C11.2279 8.45833 11.5195 9.16667 11.5195 10C11.5195 10.8333 11.2279 11.5417 10.6445 12.125C10.0612 12.7083 9.35286 13 8.51953 13ZM2.51953 23V15.275C1.8862 14.575 1.39453 13.775 1.04453 12.875C0.694531 11.975 0.519531 11.0167 0.519531 10C0.519531 7.76667 1.29453 5.875 2.84453 4.325C4.39453 2.775 6.2862 2 8.51953 2C10.7529 2 12.6445 2.775 14.1945 4.325C15.7445 5.875 16.5195 7.76667 16.5195 10C16.5195 11.0167 16.3445 11.975 15.9945 12.875C15.6445 13.775 15.1529 14.575 14.5195 15.275V23L8.51953 21L2.51953 23ZM8.51953 16C10.1862 16 11.6029 15.4167 12.7695 14.25C13.9362 13.0833 14.5195 11.6667 14.5195 10C14.5195 8.33333 13.9362 6.91667 12.7695 5.75C11.6029 4.58333 10.1862 4 8.51953 4C6.85286 4 5.4362 4.58333 4.26953 5.75C3.10286 6.91667 2.51953 8.33333 2.51953 10C2.51953 11.6667 3.10286 13.0833 4.26953 14.25C5.4362 15.4167 6.85286 16 8.51953 16ZM4.51953 20.025L8.51953 19L12.5195 20.025V16.925C11.9362 17.2583 11.307 17.5208 10.632 17.7125C9.95703 17.9042 9.25286 18 8.51953 18C7.7862 18 7.08203 17.9042 6.40703 17.7125C5.73203 17.5208 5.10286 17.2583 4.51953 16.925V20.025Z"
                            fill="#1E1E1E"
                            fillOpacity="0.5"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2567_10027">
                            <rect
                              width="16"
                              height="24"
                              fill="white"
                              transform="translate(0.839844)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <span className="text-[0.75rem] ml-[0.62rem]">
                        Product guarantee: 1 year
                      </span>
                    </div>

                    <div className="w-full flex items-center">
                      <div className="w-[65%]">
                        {selectedAttribute &&
                          cart &&
                          cart.find((item) => {
                            return (
                              parseInt(item.id) ===
                              parseInt(selectedAttribute.id)
                            );
                          }) &&
                          cart.find((item) => {
                            return (
                              parseInt(item.id) ===
                              parseInt(selectedAttribute.id)
                            );
                          }).count > 30 && (
                            <div className="text-[#FF547D] text-[12px]">
                              You choose more than 30. This item needs Shawn
                              approval.
                            </div>
                          )}
                      </div>
                      <div className="w-[35%] flex justify-end">
                        {selectedAttribute && getToken() && (
                          <AddToCartButton
                            attribute={selectedAttribute}
                            text={"Add To Cart"}
                            className="bg-[#000]"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full h-[1px] bg-[#30303088] my-[3.75rem]"></div>

                {product.introduction && (
                  <div className="mb-[1.75rem] font-[500] text-[0.875rem] leading-[200%] capitalize">
                    {product.introduction}
                  </div>
                )}

                {product.product_tabs && (
                  <div className="max-w-full">
                    <div className="">
                      <Box sx={{ width: "100%" }}>
                        <Box
                          sx={{
                            // background: "#fff",
                            padding: "5px",
                            borderRadius: "20px",
                            width: { xs: "calc(100vw - 30px)", md: "100%" },
                          }}
                        >
                          <StyledTabs
                            value={activeTab}
                            onChange={(event, newValue) => {
                              setActiveTab(newValue);
                            }}
                          >
                            {product.product_tabs.map((item, index) => {
                              return (
                                <StyledTab label={item.tab_name} key={index} />
                              );
                            })}
                          </StyledTabs>
                          <Box />
                        </Box>

                        {product.product_tabs.map((item, index) => {
                          return (
                            <TabPanel value={activeTab} index={tabCount++}>
                              <div
                                key={index}
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              />
                            </TabPanel>
                          );
                        })}
                      </Box>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          product && (
            <div className="mx-[1rem]">
              <div className="font-[700] text-[1.5rem] leading-normal my-[0.81rem]">
                {product.name}
              </div>
              {product.categories && (
                <div className="font-[700] text-[1rem] mb-[0.81rem]">
                  {JSON.parse(product.categories).map((category, index) => {
                    return (
                      <React.Fragment key={index}>
                        {index !== 0 && <span className="mx-[5px]">-</span>}
                        <span className="inline-block text-[#000]">
                          {category.name}
                        </span>
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
              <div className="flex items-center relative mb-[2.12rem]">
                <div className="w-[50%] z-[2]">
                  <span className="bg-[#fff] pr-[10px] text-[#0008] text-[0.625rem]">
                    SKU: {selectedAttribute && selectedAttribute.sku}
                  </span>
                </div>
                <div className="w-[50%] flex justify-end z-[2]">
                  <span className="bg-[#fff] pl-[10px] text-[#0008] text-[0.625rem] uppercase">
                    {product.brand}
                  </span>
                </div>
                <div className="absolute w-full h-[1px] top-[50%] left-0 bg-[#30303088]"></div>
              </div>
              <div className="relative">
                <Swiper
                  loop={true}
                  slidesPerView="auto"
                  centeredSlides="true"
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Autoplay, Pagination]}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  className="mobile-product-swiper mt-[0.75rem]"
                  style={{
                    "--swiper-pagination-color": "rgba(0, 0, 0, 0.80)",
                    "--swiper-pagination-bullet-inactive-color": "#6B6B6B",
                    "--swiper-pagination-bullet-inactive-opacity": "1",
                    "--swiper-pagination-bullet-size": "0.5rem",
                    "--swiper-pagination-bullet-horizontal-gap": "1.38rem",
                  }}
                >
                  {product.images &&
                    product.images.split(",")?.map((image, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <LazyLoadImage
                            src={image}
                            alt={product.name}
                            className="h-[80px] w-auto"
                          />
                          {/* <span
                            className="absolute top-[0.62rem] left-[0.62rem] z-[2] cursor-pointer"
                            onClick={() => {
                              setZoomToggle(true);
                              setActiveImage(image);
                            }}
                          >
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g filter="url(#filter0_b_806_3587)">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M1.67969 15.6294C1.67969 23.7089 8.22948 30.2587 16.3091 30.2587C24.3886 30.2587 30.9384 23.7089 30.9384 15.6294C30.9384 7.54979 24.3886 1 16.3091 1C8.22948 1 1.67969 7.54979 1.67969 15.6294ZM16.3091 0C7.6772 0 0.679688 6.99751 0.679688 15.6294C0.679688 24.2612 7.6772 31.2587 16.3091 31.2587C24.9409 31.2587 31.9384 24.2612 31.9384 15.6294C31.9384 6.99751 24.9409 0 16.3091 0ZM12.1119 7.339C9.41632 9.14285 8.26156 12.7012 10.0187 15.6424C11.8225 18.338 15.3809 19.4927 18.3221 17.7356C21.0177 15.9318 22.1724 12.3734 20.4153 9.43218C18.6114 6.73664 15.0531 5.58187 12.1119 7.339ZM18.3749 18.8469C15.0023 20.5433 11.1385 19.125 9.18002 16.1872C9.17562 16.1806 9.17138 16.1739 9.1673 16.1671C7.08101 12.69 8.49128 8.55087 11.5671 6.50034C11.5737 6.49594 11.5804 6.4917 11.5872 6.48761C15.0643 4.40132 19.2034 5.81159 21.254 8.88739C21.2584 8.89399 21.2626 8.90069 21.2667 8.90749C23.2694 12.2453 22.0501 16.1932 19.2284 18.3179L19.9586 19.7784C20.6248 19.7195 21.2065 20.0681 21.5127 20.3743C21.5464 20.408 21.5752 20.4465 21.5981 20.4884L23.5252 24.0215C23.5281 24.0268 23.5309 24.032 23.5335 24.0374C23.9905 24.9513 23.5184 25.8208 23.1187 26.2205C23.0807 26.2585 23.0368 26.2901 22.9887 26.3142C22.0747 26.7712 21.2053 26.2991 20.8056 25.8993C20.7718 25.8655 20.743 25.8271 20.7202 25.7852L18.793 22.252C18.7914 22.2491 18.7899 22.2462 18.7883 22.2433C18.7871 22.2409 18.7859 22.2386 18.7847 22.2362C18.3813 21.4294 18.7019 20.6573 19.0576 20.2124L18.3749 18.8469ZM19.8496 20.8221C19.6403 21.0678 19.5105 21.4429 19.6757 21.782L21.5601 25.2368C21.7899 25.4396 22.1409 25.5739 22.4687 25.4515C22.6779 25.2058 22.8078 24.8307 22.6425 24.4916L20.7581 21.0368C20.5283 20.8339 20.1774 20.6996 19.8496 20.8221ZM14.7365 13.5795V16.304H15.8501V13.5795H18.5746V12.4659H15.8501V9.74148H14.7365V12.4659H12.0121V13.5795H14.7365Z"
                                  fill="black"
                                  fillOpacity="0.5"
                                />
                              </g>
                              <defs>
                                <filter
                                  id="filter0_b_806_3587"
                                  x="-3.32031"
                                  y="-4"
                                  width="39.2588"
                                  height="39.2587"
                                  filterUnits="userSpaceOnUse"
                                  colorInterpolationFilters="sRGB"
                                >
                                  <feFlood
                                    floodOpacity="0"
                                    result="BackgroundImageFix"
                                  />
                                  <feGaussianBlur
                                    in="BackgroundImageFix"
                                    stdDeviation="2"
                                  />
                                  <feComposite
                                    in2="SourceAlpha"
                                    operator="in"
                                    result="effect1_backgroundBlur_806_3587"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_backgroundBlur_806_3587"
                                    result="shape"
                                  />
                                </filter>
                              </defs>
                            </svg>
                          </span> */}
                        </SwiperSlide>
                      );
                    })}
                  {attributes &&
                    attributes?.map((attribute, index) => {
                      if (attribute.thumbnail) {
                        return (
                          <SwiperSlide key={index}>
                            <LazyLoadImage
                              src={attribute.thumbnail}
                              alt={product.name}
                              className="h-[80px] w-auto"
                            />
                            <span
                              className="absolute top-[0.62rem] left-[0.62rem] z-[2] cursor-pointer"
                              onClick={() => {
                                setZoomToggle(true);
                                setActiveImage(attribute.thumbnail);
                              }}
                            >
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g filter="url(#filter0_b_806_3587)">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M1.67969 15.6294C1.67969 23.7089 8.22948 30.2587 16.3091 30.2587C24.3886 30.2587 30.9384 23.7089 30.9384 15.6294C30.9384 7.54979 24.3886 1 16.3091 1C8.22948 1 1.67969 7.54979 1.67969 15.6294ZM16.3091 0C7.6772 0 0.679688 6.99751 0.679688 15.6294C0.679688 24.2612 7.6772 31.2587 16.3091 31.2587C24.9409 31.2587 31.9384 24.2612 31.9384 15.6294C31.9384 6.99751 24.9409 0 16.3091 0ZM12.1119 7.339C9.41632 9.14285 8.26156 12.7012 10.0187 15.6424C11.8225 18.338 15.3809 19.4927 18.3221 17.7356C21.0177 15.9318 22.1724 12.3734 20.4153 9.43218C18.6114 6.73664 15.0531 5.58187 12.1119 7.339ZM18.3749 18.8469C15.0023 20.5433 11.1385 19.125 9.18002 16.1872C9.17562 16.1806 9.17138 16.1739 9.1673 16.1671C7.08101 12.69 8.49128 8.55087 11.5671 6.50034C11.5737 6.49594 11.5804 6.4917 11.5872 6.48761C15.0643 4.40132 19.2034 5.81159 21.254 8.88739C21.2584 8.89399 21.2626 8.90069 21.2667 8.90749C23.2694 12.2453 22.0501 16.1932 19.2284 18.3179L19.9586 19.7784C20.6248 19.7195 21.2065 20.0681 21.5127 20.3743C21.5464 20.408 21.5752 20.4465 21.5981 20.4884L23.5252 24.0215C23.5281 24.0268 23.5309 24.032 23.5335 24.0374C23.9905 24.9513 23.5184 25.8208 23.1187 26.2205C23.0807 26.2585 23.0368 26.2901 22.9887 26.3142C22.0747 26.7712 21.2053 26.2991 20.8056 25.8993C20.7718 25.8655 20.743 25.8271 20.7202 25.7852L18.793 22.252C18.7914 22.2491 18.7899 22.2462 18.7883 22.2433C18.7871 22.2409 18.7859 22.2386 18.7847 22.2362C18.3813 21.4294 18.7019 20.6573 19.0576 20.2124L18.3749 18.8469ZM19.8496 20.8221C19.6403 21.0678 19.5105 21.4429 19.6757 21.782L21.5601 25.2368C21.7899 25.4396 22.1409 25.5739 22.4687 25.4515C22.6779 25.2058 22.8078 24.8307 22.6425 24.4916L20.7581 21.0368C20.5283 20.8339 20.1774 20.6996 19.8496 20.8221ZM14.7365 13.5795V16.304H15.8501V13.5795H18.5746V12.4659H15.8501V9.74148H14.7365V12.4659H12.0121V13.5795H14.7365Z"
                                    fill="black"
                                    fillOpacity="0.5"
                                  />
                                </g>
                                <defs>
                                  <filter
                                    id="filter0_b_806_3587"
                                    x="-3.32031"
                                    y="-4"
                                    width="39.2588"
                                    height="39.2587"
                                    filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB"
                                  >
                                    <feFlood
                                      floodOpacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feGaussianBlur
                                      in="BackgroundImageFix"
                                      stdDeviation="2"
                                    />
                                    <feComposite
                                      in2="SourceAlpha"
                                      operator="in"
                                      result="effect1_backgroundBlur_806_3587"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in="SourceGraphic"
                                      in2="effect1_backgroundBlur_806_3587"
                                      result="shape"
                                    />
                                  </filter>
                                </defs>
                              </svg>
                            </span>
                          </SwiperSlide>
                        );
                      } else {
                        return null;
                      }
                    })}
                </Swiper>
                <div className="absolute top-[0.62rem] right-[0.62rem] z-[2]">
                  <WishlistIcon productId={product.id} />
                </div>
              </div>

              <div className="w-full text-[20px] text-[#303030bb] mt-[1.12rem] text-center">
                {selectedAttribute && selectedAttribute.stock > 0 ? (
                  <>
                    <div className="text-[#0BC154] text-[1.5625rem] font-[500]">
                      B2B Price: AED{" "}
                      {selectedAttribute && (
                        <Price
                          price={
                            selectedAttribute.b2b_sale_price
                              ? selectedAttribute.b2b_sale_price
                              : selectedAttribute.b2b_price
                          }
                        />
                      )}
                    </div>
                    <div className="text-[#0BC154] text-[1.5625rem] font-[500]">
                      AED{" "}
                      {selectedAttribute && (
                        <Price
                          price={
                            selectedAttribute.sale_price
                              ? selectedAttribute.sale_price
                              : selectedAttribute.price
                          }
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <span className="text-[#7F7F7F]">Unavailable</span>
                )}
              </div>

              {product.product_attributes ? (
                <>
                  {firstVariant.current.items.length > 0 && (
                    <>
                      <div className="text-[0.875rem] font-[700] text-[#000] mb-[0.25rem]">
                        {firstVariant.current.type_name}:
                      </div>
                      <div className="flex flex-wrap gap-[0.5rem]">
                        {firstVariant.current.items.map(
                          (product_attribute, index) => {
                            return (
                              <div
                                className={`w-auto p-[0.09rem_1.62rem] ${
                                  selectedVariant.first ===
                                  product_attribute.attribute_id
                                    ? "bg-[#603085] text-white border-[#603085]"
                                    : "bg-[#fff] text-[#626262] border-[#7F7F7F]"
                                }  hover:bg-[#603085] hover:border-[#603085] hover:text-white text-[0.875rem] rounded-[1.25rem] flex items-center justify-center cursor-pointer border-solid border-[0.5px]`}
                                key={index}
                                onClick={() => {
                                  setSelectedVariant({
                                    ...selectedVariant,
                                    first: product_attribute.attribute_id,
                                  });
                                  if (
                                    secondVariant.current.items.length === 0
                                  ) {
                                    const _selectedAttribute =
                                      attributes.filter((item) => {
                                        return (
                                          +item.attribute_id ===
                                          +product_attribute.attribute_id
                                        );
                                      })[0];
                                    setSelectedAttribute(_selectedAttribute);
                                    if (_selectedAttribute.thumbnail) {
                                      setActiveImage(
                                        _selectedAttribute.thumbnail
                                      );
                                    }
                                  } else if (selectedVariant.second) {
                                    const _selectedAttribute =
                                      attributes.filter((item) => {
                                        return (
                                          item.attribute_id ===
                                            (product_attribute.attribute_id +
                                              "," +
                                              selectedVariant.second ||
                                              selectedVariant.second +
                                                "," +
                                                product_attribute.attribute_id) ||
                                          item.attribute_id ===
                                            (selectedVariant.second +
                                              "," +
                                              product_attribute.attribute_id ||
                                              product_attribute.attribute_id +
                                                "," +
                                                selectedVariant.second)
                                        );
                                      })[0];
                                      
                                    setSelectedAttribute(_selectedAttribute);
                                    if (_selectedAttribute.thumbnail) {
                                      setActiveImage(
                                        _selectedAttribute.thumbnail
                                      );
                                    }
                                  }
                                }}
                              >
                                {product_attribute.attribute_type === 1 && (
                                  <span
                                    className={`w-[20px] h-[20px] ml-[0.12rem] mr-[5px] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                    style={{
                                      background:
                                        product_attribute.attribute_value,
                                    }}
                                  ></span>
                                )}
                                {product_attribute.attribute_name}
                              </div>
                            );
                          }
                        )}
                      </div>
                      {selectedVariant.first &&
                        firstVariant.current.items &&
                        firstVariant.current.items.length > 0 &&
                        firstVariant.current.items.filter((item) => {
                          return item.attribute_id === selectedVariant.first;
                        })[0] &&
                        firstVariant.current.items.filter((item) => {
                          return item.attribute_id === selectedVariant.first;
                        })[0].attribute_description && (
                          <>
                            <div className="text-[14px]">
                              {firstVariant.current.type_name + " Description"}
                            </div>

                            <div className="text-[12px]">
                              {
                                firstVariant.current.items.filter((item) => {
                                  return (
                                    item.attribute_id === selectedVariant.first
                                  );
                                })[0].attribute_description
                              }
                            </div>
                          </>
                        )}
                    </>
                  )}

                  {secondVariant.current.items &&
                    secondVariant.current.items.length > 0 && (
                      <>
                        <div className="text-[0.875rem] font-[700] text-[#000] mb-[0.25rem] mt-[1rem]">
                          {secondVariant.current.type_name}
                        </div>
                        <div className="flex flex-wrap gap-[0.5rem]">
                          {secondVariant.current.items.map(
                            (product_attribute, index) => {
                              return (
                                <div
                                  className={`w-[4.06rem] p-[0.12rem] ${
                                    selectedVariant.second ===
                                    product_attribute.attribute_id
                                      ? product_attribute.attribute_type === 1
                                        ? "bg-[#000] hover:bg-[#000] text-white justify-start border-[#000]  hover:border-[#000]"
                                        : "bg-[#603085] text-white hover:text-white justify-center border-[#603085] hover:bg-[#603085] hover:border-[#603085]"
                                      : product_attribute.attribute_type === 1
                                      ? "bg-[#fff] hover:bg-[#000] text-[#000] hover:text-white justify-start border-[#7F7F7F] hover:border-[#000]"
                                      : "bg-[#fff] hover:bg-[#603085] text-[#000] hover:text-white justify-center border-[#7F7F7F] hover:border-[#603085]"
                                  }    text-[0.75rem] rounded-[1.25rem] flex items-center cursor-pointer border-solid border-[0.5px]`}
                                  key={index}
                                  onClick={() => {
                                    setSelectedVariant({
                                      ...selectedVariant,
                                      second: product_attribute.attribute_id,
                                    });

                                    const _selectedAttribute =
                                      attributes.filter((item) => {
                                        return (
                                          item.attribute_id ===
                                            selectedVariant.first +
                                              "," +
                                              product_attribute.attribute_id ||
                                          item.attribute_id ===
                                            product_attribute.attribute_id +
                                              "," +
                                              selectedVariant.first
                                        );
                                      })[0];
                                    setSelectedAttribute(_selectedAttribute);
                                    if (_selectedAttribute.thumbnail) {
                                      setActiveImage(
                                        _selectedAttribute.thumbnail
                                      );
                                    }
                                  }}
                                >
                                  {product_attribute.attribute_type === 1 && (
                                    <span
                                      className={`w-[1rem] h-[1rem] mr-[0.03rem] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                      style={{
                                        background:
                                          product_attribute.attribute_value,
                                      }}
                                    ></span>
                                  )}
                                  {product_attribute.attribute_name}
                                </div>
                              );
                            }
                          )}
                        </div>
                        {selectedVariant.second &&
                          secondVariant.current.items &&
                          secondVariant.current.items.length > 0 &&
                          secondVariant.current.items.filter((item) => {
                            return item.attribute_id === selectedVariant.second;
                          })[0] &&
                          secondVariant.current.items.filter((item) => {
                            return item.attribute_id === selectedVariant.second;
                          })[0].attribute_description && (
                            <>
                              <div className="text-[14px]">
                                {secondVariant.current.type_name +
                                  " Description"}
                              </div>
                              <div className="text-[12px]">
                                {
                                  secondVariant.current.items.filter((item) => {
                                    return (
                                      item.attribute_id ===
                                      selectedVariant.second
                                    );
                                  })[0].attribute_description
                                }
                              </div>
                            </>
                          )}
                      </>
                    )}
                </>
              ) : (
                <div className="flex flex-wrap mt-[1.62rem]">
                  {attributes.length > 1
                    ? attributes.map((attribute, index) => {
                        return (
                          <span
                            key={index}
                            onClick={() => {
                              setSelectedAttribute(attribute);
                              if (attribute.thumbnail) {
                                setActiveImage(attribute.thumbnail);
                              }
                            }}
                            className={`text-[1.25rem] font-[700] leading-normal rounded-[1.25rem] ${
                              attribute.id === selectedAttribute.id
                                ? "bg-[#603085] text-white"
                                : "bg-transparent text-[#626262]"
                            } cursor-pointer flex items-center justify-center rounded-[20px] p-[0.19rem] min-w-[6.25rem] mr-[0.5rem] mb-[0.5rem] border-[#7F7F7F] border-solid border-[0.5px]`}
                          >
                            {attribute.attribute_type === 1 && (
                              <span
                                className={`w-[1rem] h-[1rem] mr-[0.03rem] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                                style={{
                                  background: attribute.attribute_value,
                                }}
                              ></span>
                            )}
                            {attribute.attribute_name}
                          </span>
                        );
                      })
                    : attributes[0] && (
                        <span
                          className={`text-[1.25rem] font-[700] leading-normal flex items-center justify-center`}
                        >
                          {attributes[0].attribute_type === 1 && (
                            <span
                              className={`w-[1rem] h-[1rem] mr-[0.03rem] inline-block rounded-full border-solid border-[1px] border-[#7F7F7F]`}
                              style={{
                                background: attributes[0].attribute_value,
                              }}
                            ></span>
                          )}
                          {attributes[0].attribute_name}
                        </span>
                      )}
                </div>
              )}

              {packages && packages.length > 0 && (
                <>
                  <span className="flex items-center mt-[1.63rem]">
                    <span className="mr-[0.62rem]">
                      <svg
                        width="26"
                        height="24"
                        viewBox="0 0 26 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.6294 7.51293C15.6432 7.25728 14.2728 6.88135 13.1743 6.88135C7.7574 6.88135 3.36621 10.2509 3.36621 14.4076C3.36621 16.7963 4.81624 18.9249 7.07767 20.3038L6.46496 22.3246C6.31739 22.8113 6.68163 23.3024 7.19024 23.3024H9.41859C9.58265 23.3024 9.74229 23.2492 9.87351 23.1507L11.6351 21.8287H14.7133L16.4749 23.1507C16.6061 23.2492 16.7658 23.3024 16.9299 23.3024H19.1582C19.6668 23.3024 20.0311 22.8113 19.8834 22.3246L19.2708 20.3038C20.618 19.4824 21.6773 18.3947 22.3136 17.1445"
                          stroke="#603085"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.6289 7.51266L22.3131 5.61792L22.207 10.201L24.8394 11.3021V15.7232L22.4067 16.9863"
                          stroke="#603085"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M17.8923 13.197C17.5436 13.197 17.2607 12.9142 17.2607 12.5654C17.2607 12.2167 17.5436 11.9338 17.8923 11.9338C18.2411 11.9338 18.5239 12.2167 18.5239 12.5654C18.5239 12.9142 18.2411 13.197 17.8923 13.197Z"
                          fill="#603085"
                          stroke="#603085"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M0.839844 9.40759C0.839844 9.40759 0.839844 12.4392 3.36616 13.1971"
                          stroke="#603085"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.4831 6.56917C14.646 6.09972 14.7347 5.59452 14.7347 5.06823C14.7347 2.5814 12.7553 0.56543 10.3136 0.56543C7.87195 0.56543 5.89258 2.5814 5.89258 5.06823C5.89258 6.2577 6.34541 7.33943 7.08505 8.14438"
                          stroke="#603085"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span
                      className="text-[#603085] text-[0.75rem] font-[600] mr-[0.25rem] cursor-pointer"
                      onClick={() => {
                        setPackageToggle(!packageToggle);
                      }}
                    >
                      Click here
                    </span>
                    <span className="text-[0.75rem]">
                      to view the special offer and buy gold
                    </span>
                  </span>
                  <div
                    className={`${packageToggle === true ? "flex" : "hidden"}`}
                  >
                    {packages.map((item, index) => {
                      return (
                        <span
                          key={index}
                          onClick={() => {
                            setSelectedAttribute(item);
                            if (item.thumbnail) {
                              setActiveImage(item.thumbnail);
                            }
                          }}
                          className={`${
                            selectedAttribute &&
                            selectedAttribute.id === item.id
                              ? "bg-[#603085] text-[#fff]"
                              : "bg-[#80808080] text-[#fff]"
                          } cursor-pointer flex items-center text-[12px] p-[3px] rounded-[20px] mr-[2.19rem] mt-[1.25rem] shadow`}
                        >
                          <span className="mx-[10px]">
                            {item.attribute_name}
                          </span>
                        </span>
                      );
                    })}
                  </div>
                </>
              )}

              <div className="flex items-center mt-[1.78rem] mb-[0.98rem]">
                <div className="flex items-center w-[60%] text-[10px]">
                  <Rating
                    name="read-only"
                    value={product.rate}
                    readOnly
                    sx={{ color: "#FFD954", marginRight: "10px" }}
                  />
                  {product.rate ? parseFloat(product.rate).toFixed(1) : "No"}{" "}
                  Product rating
                </div>
                <div className="w-[40%] flex items-center justify-end">
                  {product.direct_fast === 1 && (
                    <>
                      <span className="mr-[0.25rem] text-[0.75rem] text-black">
                        One day delivery
                      </span>
                      <img src={FastDeliverIcon} alt="fast delivery" />
                    </>
                  )}
                </div>
              </div>

              {reviews && (
                <div className="flex items-center text-[14px] mb-[1.19rem]">
                  <img src={LikeIcon} alt="" className="mr-[0.75rem]" />
                  {(product.rate * 100) / 5} % ( {reviews.length} people) of
                  buyers have suggested this product
                </div>
              )}

              {product.product_tabs &&
                product.product_tabs.map((item, index) => {
                  return (
                    <Accordion
                      title={item.tab_name}
                      key={index}
                      classes="mt-[20px]"
                    >
                      <div
                        className="py-[1rem]"
                        dangerouslySetInnerHTML={{
                          __html: item.content,
                        }}
                      />
                    </Accordion>
                  );
                })}

              {faqs && (
                <Accordion title="FAQ" classes="mt-[20px]">
                  <ProductFAQCard faqs={faqs} />
                </Accordion>
              )}

              {metas && metas.length > 0 && (
                <div className="w-full flex gap-[0.75rem] flex-wrap mt-[1.19rem]">
                  {metas.map((meta, index) => {
                    return (
                      <span className="flex items-center w-auto" key={index}>
                        <img
                          src={GaranteeIcon}
                          alt={meta.name}
                          className="mr-[0.75rem] w-[1.25rem] h-[1.25rem]"
                        />
                        {meta.name}
                      </span>
                    );
                  })}
                </div>
              )}

              <div className="mt-[0.91rem]">
                <div className="flex items-center">
                  <img
                    src={InfoIcon}
                    alt=""
                    className="mr-[0.75rem] h-[1.25rem] w-[1.25rem]"
                  />
                  Product test guarantee
                </div>
              </div>
              <div className="w-full flex items-center">
                <div className="w-[65%]">
                  {selectedAttribute &&
                    cart &&
                    cart.find((item) => {
                      return (
                        parseInt(item.id) === parseInt(selectedAttribute.id)
                      );
                    }) &&
                    cart.find((item) => {
                      return (
                        parseInt(item.id) === parseInt(selectedAttribute.id)
                      );
                    }).count > 30 && (
                      <div className="text-[#FF547D] text-[12px]">
                        You choose more than 30. This item needs Shawn approval.
                      </div>
                    )}
                </div>
                <div className="w-[35%] flex justify-end">
                  {selectedAttribute && getToken() && (
                    <AddToCartButton
                      attribute={selectedAttribute}
                      text={"Add To Cart"}
                      className="bg-[#000]"
                    />
                  )}
                </div>
              </div>
            </div>
          )
        )}

        {zoomToggle && (
          <Dialog
            open={zoomToggle}
            onClose={() => {
              setZoomToggle(false);
            }}
            scroll="body"
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="sh-blur"
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  // width: window.innerWidth > 991 ? "70rem" : "90%",
                  // maxWidth: "100%",
                  borderRadius: "1.25rem",
                },
              },
            }}
          >
            <div className="">
              <LazyLoadImage src={activeImage} />
              <img
                src={CloseIcon}
                alt=""
                className="absolute top-[0.94rem] left-[0.94rem] p-[5px] bg-[#00000033] rounded-full cursor-pointer"
                onClick={() => {
                  setZoomToggle(false);
                }}
              />
            </div>
          </Dialog>
        )}
      </main>
      <Footer />
    </>
  );
}
