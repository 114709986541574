import React from "react";
import { useContext } from "react";
import { useState } from "react";
import Input from "../components/input/input";
import FormButton from "../components/form-button/form-button";
import { ClipLoader } from "react-spinners";
import { useSnackbar } from "notistack";
import AppContext from "../context/context";
import Header from "./header";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";
// import {useDropzone} from 'react-dropzone'

export default function Register() {
  const { PUBLIC_URL, COUNTRY_NAMES } = useContext(AppContext);
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [countryId, setCountryId] = useState(225);
  const [lisence, setLisence] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [ORTNNumber, setOTRNNumber] = useState("");
  const [registered, setRegistered] = useState(false);
  const [loader, setLoader] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [error, setError] = useState({
    default: "",
    name: "",
    companyName: "",
    password: "",
    confirmPassword: "",
    mobile: "",
    email: "",
  });

  async function registerRequest() {
    try {
      if (!name) {
        setError({
          ...error,
          name: "Please, enter your name.",
        });
        return;
      }
      if (!companyName) {
        setError({
          ...error,
          companyName: "Please, enter your company name.",
        });
        return;
      }
      if (!email) {
        setError({ ...error, email: "Please, enter your email." });
        return;
      }
      if (!password) {
        setError({ ...error, password: "Please, enter your password." });
        return;
      }
      if (password !== confirmPassword) {
        setError({
          ...error,
          confirmPassword: "Your password and confirm password are not equal.",
        });
        return;
      }

      setError({
        ...error,
        default: "",
      });

      setLoader(1);

      await fetch(`${PUBLIC_URL}/api/customer/register`, {
        method: "POST",
        body: JSON.stringify({
          name: name,
          companyName: companyName,
          country: countryId,
          lisence: lisence,
          password: password,
          mobile: mobile,
          email: email,
          otrn: ORTNNumber,
        }),
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          console.log(data);
          setLoader(0);
          if (data.status === 1) {
            enqueueSnackbar("Your account created.", {
              variant: "success",
              vertical: "bottom",
              horizontal: "left",
            });
            // navigate(`/confirm/${email}`);
            setRegistered(true);
          } else if (data.status === 2) {
            setError({
              ...error,
              default: "Please, enter required fields.",
            });
          } else if (data.status === 3) {
            setError({
              ...error,
              default: "This email is registered before.",
            });
          } else if (data.status === 4) {
            setError({
              ...error,
              email: "Please, enter your email correctly.",
            });
          } else {
            setError({ ...error, default: "Error" });
          }
        });
    } catch (err) {
      enqueueSnackbar("Error", {
        variant: "error",
        vertical: "bottom",
        horizontal: "left",
      });
      setLoader(0);
    }
  }

  const registerHandler = () => {
    registerRequest();
  };

  return (
    <>
      <Header />
      <main className="bg-[rgba(0,0,0,0.05)] py-[60px]">
        {registered ? (
          <div className="w-[95%] md:w-[40%] bg-white mx-auto p-[60px_20px] border-[1px] border-solid border-[rgba(0,0,0,0.05)] shadow rounded-[15px]">
            <h1 className="text-center font-[500] text-[24px] mb-[15px]">
              Thank you for your registration
            </h1>
            <div className="mb-[6px]">
              <div className="text-center">
                Your information has been successfully registered. The
                confirmation of your user account will be notified via email,
                and if needed, we will contact you via your contact information.
              </div>
              <FormButton
                onclick={() => {
                  navigate("/");
                }}
                classes="mt-[15px] cursor-pointer inline-block cursor-pointer inline-block p-[15px] text-center upppercase bg-[#000000] text-[#fff] w-full rounded-[15px]"
              >
                Back Home Page
              </FormButton>
            </div>
          </div>
        ) : (
          <form className="w-[95%] md:w-[40%] bg-white mx-auto p-[60px_20px] border-[1px] border-solid border-[rgba(0,0,0,0.05)] shadow rounded-[15px]">
            <h1 className="text-center font-[500] text-[24px] mb-[15px]">
              Register
            </h1>
            <div className="mb-[6px]">
              <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
                {/* <div
                className={`w-[250px] bg-[rgba(0,0,0,0.05)] ${
                  error.name ? "text-[#f00]" : "text-[#000]"
                } p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right`}
              >
                Name *
              </div> */}
                <Input
                  type="text"
                  placeholder="Name *"
                  value={name}
                  onchange={(value) => {
                    setName(value);
                    setError({ ...error, name: "" });
                  }}
                  classes="text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]"
                />
              </div>
              {error.name && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.name}
                </div>
              )}
            </div>
            <div className="mb-[6px]">
              <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
                {/* <div
                className={`w-[250px] bg-[rgba(0,0,0,0.05)] ${
                  error.companyName ? "text-[#f00]" : "text-[#000]"
                } p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right`}
              >
                Company Name *
              </div> */}
                <Input
                  type="text"
                  placeholder="Company Name *"
                  value={companyName}
                  onchange={(value) => {
                    setCompanyName(value);
                    setError({ ...error, companyName: "" });
                  }}
                  classes="text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]"
                />
              </div>
              {error.companyName && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.companyName}
                </div>
              )}
            </div>
            <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)] mb-[6px]">
              {/* <div className="w-[250px] bg-[rgba(0,0,0,0.05)] text-[#000] p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right">
              Country
            </div> */}
              <select
                value={countryId}
                onChange={(event) => {
                  setCountryId(event.target.value);
                }}
                className="w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)] px-[10px] text-[11px] md:text-[14px]"
              >
                {COUNTRY_NAMES.map((country, index) => {
                  return (
                    <option key={index} value={country.id}>
                      {country.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="mb-[6px]">
              <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
                <div className="w-[250px] bg-[rgba(0,0,0,0.05)] text-[#000] p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right">
                  Lisence
                </div>
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png,.webp,.pdf"
                  onChange={(event) => {
                    var file = event.target.files[0];

                    var reader = new FileReader();
                    reader.onload = async (event) => {
                      setLisence(event.target.result);
                    };

                    reader.readAsDataURL(file);
                  }}
                  className={`text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]`}
                />
              </div>
            </div>

            <div className="mb-[6px]">
              <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
                {/* <div className="w-[250px] bg-[rgba(0,0,0,0.05)] text-[#000] p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right">
                Contact Number
              </div> */}
                <Input
                  type="text"
                  placeholder="Contact Number"
                  value={mobile}
                  onchange={(value) => {
                    setMobile(value);
                  }}
                  classes="text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]"
                />
              </div>
            </div>
            <div className="mb-[6px]">
              <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
                {/* <div
                className={`w-[250px] bg-[rgba(0,0,0,0.05)] ${
                  error.email ? "text-[#f00]" : "text-[#000]"
                } p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right`}
              >
                Email *
              </div> */}
                <Input
                  type="text"
                  placeholder="Email *"
                  value={email}
                  onchange={(value) => {
                    setEmail(value);
                    setError({ ...error, email: "" });
                  }}
                  classes="text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]"
                />
              </div>
              {error.email && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.email}
                </div>
              )}
            </div>

            <div className="mb-[6px]">
              <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
                {/* <div
                className={`w-[250px] bg-[rgba(0,0,0,0.05)] ${
                  error.password ? "text-[#f00]" : "text-[#000]"
                } p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right`}
              >
                Password *
              </div> */}
                <Input
                  type="password"
                  placeholder="Password *"
                  value={password}
                  onchange={(value) => {
                    setPassword(value);
                    setError({ ...error, password: "" });
                  }}
                  classes="text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]"
                />
              </div>
              {error.password && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.password}
                </div>
              )}
            </div>
            <div className="mb-[6px]">
              <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
                {/* <div
                className={`w-[250px] bg-[rgba(0,0,0,0.05)] ${
                  error.confirmPassword ? "text-[#f00]" : "text-[#000]"
                } p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right`}
              >
                Confirm Password *
              </div> */}
                <Input
                  type="password"
                  placeholder="Confirm Password *"
                  value={confirmPassword}
                  onchange={(value) => {
                    setConfirmPassword(value);
                    if (value) {
                      if (password !== value) {
                        setError({
                          ...error,
                          confirmPassword:
                            "Your password and confirm password are not equal.",
                        });
                      } else {
                        setError({ ...error, confirmPassword: "" });
                      }
                    } else {
                      setError({ ...error, confirmPassword: "" });
                    }
                  }}
                  classes="text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]"
                />
              </div>
              {error.confirmPassword && (
                <div className="text-[#f00] ltr:text-left rtl:text-right px-[15px]">
                  {error.confirmPassword}
                </div>
              )}
            </div>
            <div className="mb-[6px]">
              <div className="flex rounded-[15px] overflow-hidden bg-[rgba(0,0,0,0.05)]">
                <div className="w-[250px] bg-[rgba(0,0,0,0.05)] text-[#000] p-[11px_18px] uppercase text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] ltr:text-left rtl:text-right">
                  TRN Number
                </div>
                <input
                  type="file"
                  accept=".jpg,.jpeg,.png,.webp,.pdf"
                  onChange={(event) => {
                    var file = event.target.files[0];

                    var reader = new FileReader();
                    reader.onload = async (event) => {
                      setOTRNNumber(event.target.result);
                    };

                    reader.readAsDataURL(file);
                  }}
                  className={`text-[11px] md:text-[14px] 2xl:text-[16px] font-[300] w-full p-[11px_15px] bg-transparent text-[rgba(30,30,30,0.70)]`}
                />
              </div>
            </div>
            <FormButton
              onclick={() => {
                registerHandler();
              }}
              classes="cursor-pointer inline-block p-[15px] text-center upppercase bg-[#0BC154] text-[#fff] w-full rounded-[15px]"
            >
              {loader === 1 && (
                <ClipLoader
                  color="#ffffff"
                  size="15px"
                  className="rtl:ml-[10px] ltr:mr-[10px]"
                />
              )}
              Register
            </FormButton>
            <FormButton
              onclick={() => {
                navigate("/login");
              }}
              classes="mt-[15px] cursor-pointer inline-block cursor-pointer inline-block p-[15px] text-center upppercase bg-[#000000] text-[#fff] w-full rounded-[15px]"
            >
              Login
            </FormButton>
            {error.default && (
              <div className="text-[#f00] mt-[6px]">{error.default}</div>
            )}
          </form>
        )}
      </main>
      <Footer />
    </>
  );
}
