import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";
import { Autoplay } from "swiper/modules";
// import Slide1 from "../../assets/img/home-slider-1.jpg";
// import Slide2 from "../../assets/img/home-slider-2.jpg";
// import Slide3 from "../../assets/img/home-slider-3.jpg";
// import Slide4 from "../../assets/img/home-slider-4.jpg";
import Slide1 from "../../assets/img/tashvighi-1.jpg";
import Slide2 from "../../assets/img/tashvighi-2.jpg";
import Slide3 from "../../assets/img/tashvighi-3.jpg";
import Slide4 from "../../assets/img/tashvighi-4.jpg";
import Slide5 from "../../assets/img/tashvighi-5.jpg";
import Slide6 from "../../assets/img/tashvighi-6.jpg";
import Slide7 from "../../assets/img/tashvighi-7.jpg";
import MobileSlide1 from "../../assets/img/tashvighi-m-1.jpg";
import MobileSlide2 from "../../assets/img/tashvighi-m-2.jpg";
import MobileSlide3 from "../../assets/img/tashvighi-m-3.jpg";
import MobileSlide4 from "../../assets/img/tashvighi-m-4.jpg";
import MobileSlide5 from "../../assets/img/tashvighi-m-5.jpg";
import MobileSlide6 from "../../assets/img/tashvighi-m-6.jpg";

// import MobileSlide1 from "../../assets/img/home-slider-m-1.jpg";
// import MobileSlide2 from "../../assets/img/home-slider-m-2.jpg";
// import MobileSlide3 from "../../assets/img/home-slider-m-3.jpg";
// import MobileSlide4 from "../../assets/img/home-slider-m-4.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function HomeSlider() {
  return (
    <Swiper
      loop={true}
      modules={[Autoplay]}
      speed={2000}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      className="mt-[0.75rem]"
    >
      {window.innerWidth > 767 ? (
        <>
          <SwiperSlide>
            <LazyLoadImage
              src={Slide1}
              alt="slider 1"
              style={{ width: "100%" }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage
              src={Slide2}
              alt="slider 2"
              style={{ width: "100%" }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage
              src={Slide3}
              alt="slider 3"
              style={{ width: "100%" }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage
              src={Slide4}
              alt="slider 4"
              style={{ width: "100%" }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage
              src={Slide5}
              alt="slider 5"
              style={{ width: "100%" }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage
              src={Slide6}
              alt="slider 6"
              style={{ width: "100%" }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage
              src={Slide7}
              alt="slider 7"
              style={{ width: "100%" }}
            />
          </SwiperSlide>
        </>
      ) : (
        <>
          <SwiperSlide>
            <LazyLoadImage
              src={MobileSlide1}
              alt="slider 1"
              style={{ width: "100%" }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage
              src={MobileSlide2}
              alt="slider 2"
              style={{ width: "100%" }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage
              src={MobileSlide3}
              alt="slider 3"
              style={{ width: "100%" }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage
              src={MobileSlide4}
              alt="slider 4"
              style={{ width: "100%" }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage
              src={MobileSlide5}
              alt="slider 5"
              style={{ width: "100%" }}
            />
          </SwiperSlide>
          <SwiperSlide>
            <LazyLoadImage
              src={MobileSlide6}
              alt="slider 6"
              style={{ width: "100%" }}
            />
          </SwiperSlide>
        </>
      )}
    </Swiper>
  );
}
