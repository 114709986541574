import Header from "./header";
import { Helmet } from "react-helmet";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Footer from "./footer";
import ProductSkeleton from "../components/skeleton/product/product-skeleton";
import ProductCard from "../components/productcard/productcard";
import ShopPagination from "../components/pagination/pagination";
import { Link, useParams } from "react-router-dom";
import AppContext from "../context/context";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BackButton from "../components/back-button/back-button";

export default function Brands() {
  const { PUBLIC_URL, filters, setFilters, setPageID, brands, getToken } =
    useContext(AppContext);
  setPageID(4);
  const page = useRef(1);
  const [products, setProducts] = useState([]);
  const [productsCount, setProductsCount] = useState(0);
  const [brand, setBrand] = useState();
  const [loader, setLoader] = useState(0);
  let { id } = useParams();
  if (!id) {
    id = brands && brands.length > 0 ? brands[0].id : 0;
  }

  const getProducts = useCallback(async () => {
    if (brands && brands.length > 0 && id === 0) {
      id = brands[0].id;
    }
    try {
      setLoader(1);

      let filter = "";

      if (page.current > 0) {
        filter = filter + "p=" + page.current;
      }

      if (filters.searchTerm) {
        if (!filter) {
          filter = filter + "term=" + filters.searchTerm;
        } else {
          filter = filter + "&term=" + filters.searchTerm;
        }
      }

      filter = filter ? filter + "&brid=" + id : filter + "brid=" + id;

      await fetch(`${PUBLIC_URL}/api/product/productlist?${filter}`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then(async (data) => {
          setProductsCount(data.count);
          setProducts(data.products);
          setLoader(0);
        });
    } catch (error) {
      setLoader(0);
    }
    // }, [PUBLIC_URL, page, id, filters]);
  }, [PUBLIC_URL, filters, id, brands]);

  // useEffect(() => {
  //   setProducts([]);
  //   getProducts();
  // }, [page, id]);

  // useEffect(() => {
  //   setProducts([]);
  //   getProducts();
  // }, [filters, setFilters]);

  const getBrands = useCallback(async () => {
    try {
      await fetch(`${PUBLIC_URL}/api/product/brands`)
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          const _brand = data.filter((brand) => {
            return brand.id === parseInt(id);
          });
          if (_brand[0]) {
            setBrand(_brand[0]);
          }
          setProducts([]);
          getProducts();
        });
    } catch (error) {}
  }, [PUBLIC_URL, getProducts]);

  useEffect(() => {
    getBrands();
  }, [getBrands, id, page, filters, setFilters]);

  return (
    <>
      <Helmet>
        <title>Shawn</title>
        <meta name="description" content="Pet supplies store" />
      </Helmet>
      <Header />
      <main className="h-brands bg-white md:bg-[#f2f2f4]">
        <BackButton />
        <div className="flex flex-wrap lg:flex-nowrap gap-0 lg:gap-[3.5rem] px-[1rem] md:px-[1.5rem] lg:px-[3.5rem]">
          {window.innerWidth < 992 && (
            <div className="w-full block text-[1.5rem] font-[600] text-center mb-[0.94rem] text-[#000e] uppercase leading-normal">
              {brands &&
              id > 0 &&
              brands.filter((brand) => {
                return brand.id === parseInt(id);
              }).length > 0
                ? brands.filter((brand) => {
                    return brand.id === parseInt(id);
                  })[0].name
                : brands.length > 0
                ? brands[0].name
                : ""}
            </div>
          )}

          <div className="w-full lg:w-[13.88vw] flex lg:inline-block flex-wrap lg:flex-nowrap justify-center gap-[0.75rem]  mb-[1.27rem] md:mb-0">
            {window.innerWidth > 1023 ? (
              <>
                {brands.map((brand, index) => {
                  return (
                    <Link
                      key={index}
                      to={`/brand/${brand.id}`}
                      className={`flex flex-nowrap lg:flex-wrap items-center justify-center 
          w-auto lg:w-[13.88vw] h-auto lg:h-[11.95vw] cursor-pointer 
          rounded-[1.25rem] md:rounded-[0.63rem] bg-white border-[3px] border-solid ${
            parseInt(id) === brand.id
              ? "border-[rgba(0,0,0,0.60)]"
              : "border-[#fff]"
          } hover:border-[rgba(0,0,0,0.60)] mb-[0.75rem] lg:mb-[3.5rem] shadow overflow-hidden`}
                    >
                      <div className="w-auto lg:w-[13.88vw] h-auto lg:h-[11.95vw] flex items-center justify-center">
                        {brand.thumbnail ? (
                          <LazyLoadImage
                            src={brand.thumbnail}
                            className="w-auto h-auto"
                            alt={brand.name}
                          />
                        ) : (
                          <span className="text-[0.5rem] md:text-[0.7rem] lg:text-[1.2rem] font-[700] p-[10px_15px] lg:p-0 uppercase">
                            {brand.name}
                          </span>
                        )}
                      </div>
                    </Link>
                  );
                })}
              </>
            ) : (
              <>
                {brand && (
                  <span
                    className={`flex flex-nowrap md:flex-wrap items-center justify-center 
                        w-[5rem] lg:w-[12.5rem] h-[4.3rem] lg:h-[10.75rem] cursor-pointer 
                        rounded-[1.25rem] md:rounded-[0.63rem] bg-white border-[1px] border-solid border-[#000] hover:border-[#000] mb-[0.75rem] lg:mb-[3.5rem] shadow overflow-hidden`}
                  >
                    <div className="w-full lg:w-[50px] flex items-center justify-center">
                      {brand.thumbnail ? (
                        <LazyLoadImage
                          src={brand.thumbnail}
                          className="w-full h-auto"
                          alt={brand.name}
                        />
                      ) : (
                        <span className="text-[0.5rem] md:text-[0.7rem] lg:text-[1.2rem] font-[700] p-[10px_15px] lg:p-0 uppercase">
                          {brand.name}
                        </span>
                      )}
                    </div>
                  </span>
                )}
              </>
            )}
          </div>

          <div className="block lg:hidden text-[0.875rem] mb-[0.94rem] text-[#000e]">
            {products && (
              <div>
                {(page.current - 1) * 24 + 1} - {(page.current - 1) * 24 + 24}{" "}
                of {productsCount} Products
              </div>
            )}
          </div>

          <div className="w-full lg:w-[86.12vw] text-[#4D4D4D]">
            <div className="h-products  flex flex-wrap w-full">
              {loader === 0 ? (
                products ? (
                  products.map((item, index) => {
                    return <ProductCard product={item} key={index} />;
                  })
                ) : (
                  <div className="w-full p-[20px] bg-[#fff] rounded-[15px]">
                    There is no product.
                  </div>
                )
              ) : (
                <div className="flex flex-wrap gap-[10px]">
                  <ProductSkeleton count={24} />
                </div>
              )}
            </div>
            {productsCount > 24 && (
              <div className="w-full flex items-center justify-center">
                <ShopPagination
                  pagesCount={
                    productsCount % 24 === 0
                      ? productsCount / 24
                      : Math.floor(productsCount / 24) + 1
                  }
                  page={page.current}
                  handleChange={(event, value) => {
                    page.current = value;
                    getProducts();
                  }}
                />
              </div>
            )}
            <div className="mb-[0.94rem] md:mb-[6.5rem]"></div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
