import React, { useCallback, useEffect, useRef } from "react";
import AppContext from "../../context/context";
import { useContext } from "react";
import { useState } from "react";
import ShopPagination from "../pagination/pagination";
import PurchaseItemCard from "../purchase-item-card/purchase-item-card";

export default function PurchaseCard() {
  const { getToken, PUBLIC_URL } = useContext(AppContext);
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);

  const getOrders = useCallback(async () => {
    try {
      // setProductsLoader(1)
      await fetch(`${PUBLIC_URL}/api/order/orderslist`, {
        headers: {
          Authorization: "Bearer " + getToken(),
        },
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json();
          }
          throw new Error("error");
        })
        .then((data) => {
          setOrders(data.orders)
        });
    } catch (error) {
      // setProductsLoader(2)
    }
  }, []);

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  return (
    <div className="">
      {orders && orders.length>0 ? ( 
        <>
          {orders.map((item, index) => {
            if ((page - 1) * 3 <= index && index < page * 3) {
              return <PurchaseItemCard item={item} key={index} />;
            } else {
              return <React.Fragment key={index}></React.Fragment>;
            }
          })}
          {orders.length > 3 && (
            <div className="w-full mt-[13px]">
              <ShopPagination
                pagesCount={
                  orders.length % 3 === 0
                    ? orders.length / 3
                    : Math.floor(orders.length / 3) + 1
                }
                page={page}
                handleChange={(event, value) => {
                  setPage(value);
                }}
              />
            </div>
          )}
        </>
      ):(
        <div>No Orders Yet!</div>
      )}
    </div>
  );
}
