import { Skeleton, Stack } from "@mui/material";

export default function ProductSkeleton(props) {
  return Array.apply(0, Array(props.count ? props.count : 4)).map((index) => {
    return (
      <Stack
        spacing={1}
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: {xs:"100%",md:"calc(25% - 0.75rem)"},
        }}
        key={index}
      >
        <Skeleton
          variant="square"
          height={200}
          sx={{
            borderRadius: "20px 20px 0 0",
          }}
        />
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
        <div className="flex items-center">
          <div className="w-full">
            <Skeleton variant="text" width={60} sx={{ fontSize: "1rem" }} />
          </div>
          <div className="w-full flex justify-end">
            <Skeleton
              variant="square"
              width={60}
              height={25}
              sx={{ borderRadius: "20px" }}
            />
          </div>
        </div>
      </Stack>
    );
  });
}
