import HeartIcon from "../../assets/img/heart_white_icon.svg";
import HeartLikedIcon from "../../assets/img/heart_liked_icon.svg";
import { useSnackbar } from "notistack";
import { useContext } from "react";
import AppContext from "../../context/context";

export default function WishlistIcon(props) {
  const { wishlist, setWishlist, PUBLIC_URL } =
    useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const addToWishlist = async (id) => {
    try {
      // if (getToken()) {
      //   await fetch(`${PUBLIC_URL}/api/product/addtowishlist`, {
      //     method: "POST",
      //     body: JSON.stringify({
      //       pid: id,
      //     }),
      //     headers: {
      //       Authorization: "Bearer " + getToken(),
      //     },
      //   })
      //     .then((respose) => {
      //       if (respose.ok) {
      //         return respose.json();
      //       }
      //       throw new Error("error");
      //     })
      //     .then((data) => {
      //       if (data.status === 0) {
      //         //set error
      //       } else {
      //         setWishlist(data.wishlist);
      //         if (data.status === 1) {
      //           enqueueSnackbar(t('message_added_wishlist'), {
      //             variant: "success",
      //             vertical: "bottom",
      //             horizontal: "left",
      //           });
      //         } else if (data.status === 2) {
      //           enqueueSnackbar(t('message_removed_wishlist'), {
      //             variant: "error",
      //             vertical: "bottom",
      //             horizontal: "left",
      //           });
      //         }
      //       }
      //     });
      // } else {
        let localWishlist = localStorage.getItem("wish");

        if (localWishlist) {
          localWishlist = JSON.parse(localWishlist);

          const foundItem = localWishlist.find((item) => {
            return item.id === id;
          });
          if (foundItem) {
            const newlocalWishlist = localWishlist.filter((item) => {
              if (id !== item.id) {
                return item;
              }else{
                return null
              }
            });
            localStorage.setItem("wish", JSON.stringify(newlocalWishlist));
            enqueueSnackbar("Removed from wishlist", {
              variant: "error",
              vertical: "bottom",
              horizontal: "left",
            });
          } else {
            localStorage.setItem(
              "wish",
              JSON.stringify([...localWishlist, { id: id }])
            );
            enqueueSnackbar("Added to wishlist.", {
              variant: "success",
              vertical: "bottom",
              horizontal: "left",
            });
          }
        } else {
          localStorage.setItem("wish", JSON.stringify([{ id: id }]));
        }
        await fetch(`${PUBLIC_URL}/api/product/wishlist`, {
          method: "POST",
          body: JSON.stringify({
            localwish: localStorage.getItem("wish"),
          }),
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json();
            }
            throw new Error("error");
          })
          .then((data) => {
            setWishlist(data.products);
          });
      // }
    } catch (error) {}
  };

  return Array.isArray(wishlist) &&
    wishlist.find((item) => {
      return item.id === props.productId;
    }) ? (
    <span
      className="cursor-pointer"
      onClick={(event) => {
        addToWishlist(props.productId);
      }}
    >
      <img src={HeartLikedIcon} alt="wishlist" style={{ width: "1.38rem" }} />
    </span>
  ) : (
    <span
      className="cursor-pointer"
      onClick={(event) => {
        addToWishlist(props.productId);
      }}
    >
      <img src={HeartIcon} alt="wishlist" style={{ width: "1.38rem" }} />
    </span>
  );
}
