import { Pagination, Stack } from "@mui/material";

export default function ShopPagination(props) {
  return (
    <div className="w-auto inline-block my-[0.94rem] p-[0.62rem] mx-auto rounded-[1.25rem] border-solid border-[1px] border-[#6E6E73]">
      <Stack spacing={2}>
        <Pagination
          size="medium"
          count={props.pagesCount}
          shape="rounded"
          color="dark"
          page={props.page}
          onChange={props.handleChange}
        />
      </Stack>
    </div>
  );
}
